import { create } from 'zustand';

import type { Order } from '../util/table-utils';
import type { QuoteDetail } from '@backend/types';

export type QuoteDetailSorting = Exclude<keyof QuoteDetail, 'id' | 'tenantId'>;

interface SortingState {
  header: QuoteDetailSorting;
  order: Order;
}

interface prevQuotesTableStore {
  error: boolean;
  setError: (error: boolean) => void;

  openDeleteDialog: boolean;
  setOpenDeleteDialog: (open: boolean) => void;
  deleteRow: QuoteDetail | null;
  setDeleteRow: (quote: QuoteDetail | null) => void;

  quotes: QuoteDetail[];
  setQuotes: (quotes: QuoteDetail[]) => void;

  pages: number;
  setPages: (pages: number) => void;

  sorting: SortingState;
  setSorting: (sorting: SortingState) => void;

  pageSize: number;
  setPageSize: (pageSize: number) => void;

  currentPage: number;
  setCurrentPage: (page: number) => void;
  goToNextPage: () => void;
  goToPrevPage: () => void;

  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

export const usePrevQuotesTableStore = create<prevQuotesTableStore>(
  (set, get) => ({
    error: false,
    setError: (error) => set({ error }),

    openDeleteDialog: false,
    setOpenDeleteDialog: (open) => set({ openDeleteDialog: open }),
    deleteRow: null,
    setDeleteRow: (quote) => set({ deleteRow: quote }),

    quotes: [],
    setQuotes: (quotes) => set({ quotes }),

    pages: 0,
    setPages: (pages) => set({ pages }),

    sorting: { header: 'updatedAt', order: 'desc' },
    setSorting: (sorting) => set({ sorting }),

    pageSize: 10,
    setPageSize: (pageSize) => {
      set({ pageSize: pageSize > 0 ? pageSize : 1, currentPage: 1 });
    },

    currentPage: 1,
    setCurrentPage: (page) => {
      if (page > 0 && page <= get().pages) {
        set({ currentPage: page });
      }
    },
    goToNextPage: () =>
      set((state) => {
        if (state.currentPage < state.pages) {
          return { currentPage: state.currentPage + 1 };
        }
        return {};
      }),
    goToPrevPage: () =>
      set((state) => {
        if (state.currentPage > 1) {
          return { currentPage: state.currentPage - 1 };
        }
        return {};
      }),

    searchTerm: '',
    setSearchTerm: (searchTerm) => set({ searchTerm }),
  })
);
