// Please do not modify this file it is generated by `generate-backend-types.ts`
/* eslint-disable */
// prettier-ignore

export const ebayCategoryNameToId: Record<string, number> = {
  "Cameras & Photo": 625,
  "Vintage Movie & Photography": 3326,
  "Other Vintage Photography": 711,
  "Vintage Parts & Accessories": 175741,
  "Vintage Projectors & Screens": 11722,
  "Vintage Lenses": 101642,
  "Vintage Cameras": 101643,
  "Stereo Cameras": 4692,
  "Other Vintage Cameras": 4701,
  "Box Cameras": 11716,
  "Folding Cameras": 11717,
  "Instant Cameras": 11718,
  "Movie Cameras": 4689,
  "SLR Cameras": 11720,
  "TLR Cameras": 11721,
  "35mm Cameras": 98923,
  "Rangefinder Cameras": 98924,
  "Subminiature Cameras": 101644,
  "Disc Cameras": 109092,
  "Vintage Film": 167943,
  "Vintage Manuals & Guides": 170067,
  "Camera Manuals & Guides": 4684,
  "Camcorders": 11724,
  "Camera, Drone & Photo Accessories": 15200,
  "Memory Cards": 96991,
  "Battery Grips": 29967,
  "Accessory Bundles": 73834,
  "Camera & Camcorder Lights": 43440,
  "Memory Card Readers & Adapters": 51082,
  "Memory Card Cases": 50503,
  "Underwater Cases & Housings": 50506,
  "LCD Hoods": 50508,
  "Straps & Hand Grips": 64343,
  "Remotes & Shutter Releases": 64345,
  "Camcorder Tapes & Discs": 67339,
  "Microphones": 48707,
  "Cases, Bags & Covers": 107894,
  "Cables & Adapters": 56172,
  "Batteries": 20357,
  "Chargers & Cradles": 124270,
  "Cleaning Equipment & Kits": 175678,
  "Other Camera & Photo Accs": 162480,
  "Screen Protectors": 168096,
  "Viewfinders & Eyecups": 167930,
  "Photo Albums & Storage": 182075,
  "Other Photo Albums & Storage": 29953,
  "Photo Albums & Boxes": 73459,
  "Photo Pages, Sheets & Sleeves": 182076,
  "Camera Drone Accessories": 182969,
  "Video Production & Editing": 21162,
  "Other Production & Editing": 3319,
  "Video Recorders & Players": 21168,
  "Distribution Switchers & Routers": 21169,
  "Video Monitors": 21517,
  "Lighting for Video Production": 33513,
  "Video Production Accessories": 51541,
  "Audio for Video": 100501,
  "Video Servers & Streaming Dev.": 184620,
  "Signal Generators": 184622,
  "Production Switchers": 184623,
  "Converters, Scalers & Processors": 184648,
  "Extenders": 185014,
  "Production Controllers & Panels": 185015,
  "Waveform Monitors": 185016,
  "Other Cameras & Photo": 27432,
  "Binoculars & Telescopes": 28179,
  "Telescope Parts & Accessories": 74922,
  "Telescope Eyepieces & Lenses": 29954,
  "Telescope Cases & Bags": 32943,
  "Telescope Filters": 74923,
  "Telescope Mirrors & Prisms": 74925,
  "Other Telescope Parts": 74926,
  "Telescope Adapters": 151236,
  "Telescope Mounts": 167938,
  "Telescopes": 74927,
  "Binoculars & Monoculars": 83880,
  "Other Binoculars & Telescopes": 162044,
  "Binocular Cases & Accessories": 162485,
  "Lighting & Studio": 30078,
  "Continuous Lighting": 19590,
  "Other Continuous Lighting": 3860,
  "Lighting Kits": 19591,
  "Lighting Units": 69341,
  "Light Bulbs & Tubes": 172516,
  "Stock Photo Collections": 21198,
  "Props & Stage Equipment": 30085,
  "Other Lighting & Studio": 30088,
  "Background Material": 79003,
  "Light Controls & Modifiers": 79007,
  "Studio Reflectors": 30082,
  "Softboxes & Diffusers": 79008,
  "Studio Umbrellas": 79009,
  "Other Light Controls & Mods": 79010,
  "Light Stands & Booms": 88665,
  "Light Stand & Boom Accessories": 88667,
  "Light Meters": 88668,
  "Flash Lighting": 124848,
  "Slaves & Trigger Systems": 30086,
  "Other Flash Lighting": 30087,
  "Flash Lighting Kits": 87635,
  "Monolight Flashes": 87637,
  "Flash Heads & Power Supplies": 124850,
  "Flash Bulbs & Tubes": 155817,
  "Shooting Tables & Light Tents": 151226,
  "Tripods & Supports": 30090,
  "Tripods & Monopods": 30093,
  "Tripod Heads": 30095,
  "Other Tripods & Supports": 30097,
  "Stabilizers": 172511,
  "Digital Cameras": 31388,
  "Mixed Lots": 259701,
  "Flashes & Flash Accessories": 64353,
  "Flashes": 48515,
  "Other Flashes & Flash Accs": 64354,
  "Flash Adapters": 69573,
  "Flash Brackets": 89161,
  "Flash Diffusers": 121464,
  "Sync Cords": 155823,
  "Film Photography": 69323,
  "Film": 4201,
  "Movie Camera Accessories": 4690,
  "Movie Editing Equipment": 4790,
  "Film Cameras": 15230,
  "Slide & Movie Projection": 15250,
  "Projector Bulbs & Lamps": 15251,
  "Movie Projectors": 15252,
  "Slide Projectors": 15256,
  "Other Slide & Movie Projection": 15257,
  "Projection Screens & Material": 48655,
  "Projection Lenses": 83874,
  "Slide Trays & Carousels": 83876,
  "Slide Viewers": 83877,
  "Rangefinder Units & Accs": 29965,
  "Motor Drives & Winders": 29970,
  "Film Backs & Holders": 29973,
  "Lens Boards": 29980,
  "Other Film Photography": 43479,
  "Digital Camera Backs": 170066,
  "Darkroom & Developing": 182077,
  "Other Darkroom & Developing": 629,
  "Photographic Paper": 15226,
  "Film Development Chemicals": 29983,
  "Enlargement Equipment": 29985,
  "Enlarging Lenses": 29986,
  "Safelights & Bulbs": 29990,
  "Darkroom Timers": 29991,
  "Processing & Finishing Equip": 29993,
  "Easels & Negative Carriers": 162048,
  "Lenses & Filters": 78997,
  "Lenses": 3323,
  "Filters": 175736,
  "Filter Rings & Holders": 30040,
  "Lens Adapters, Mounts & Tubes": 30059,
  "Lens Hoods": 78999,
  "Other Lenses & Filters": 79000,
  "Lens Caps": 79001,
  "Auxiliary/Conversion Lenses": 80390,
  "Digital Photo Frames": 150044,
  "Camera Drones": 179697,
  "Replacement Parts & Tools": 163769,
  "Film Camera Parts": 30038,
  "Digital Camera Parts": 64352,
  "Camcorder Parts": 172513,
  "Camera Tools & Repair Kits": 172514,
  "Camera Drone Parts": 260144,
  "Vehicle Electronics & GPS": 3270,
  "Other Vehicle Electronics": 1498,
  "Radar & Laser Detectors": 14935,
  "Car Audio & Video Installation": 32806,
  "Power & Speaker Wire": 32810,
  "Connectors & Terminals": 50549,
  "Distribution Blocks": 50550,
  "Fuses & Fuse Holders": 50551,
  "Other Car A/V Installation": 50552,
  "Speaker/Sub. Enclosures": 50564,
  "Wire Harnesses": 64578,
  "Speaker/Sub. Grills & Accs": 67773,
  "Amplifier Kits": 71527,
  "Capacitors": 75386,
  "Dashboard Installation Kits": 85806,
  "Interconnect Cables": 94844,
  "Car Video": 48604,
  "Other Car Video": 48605,
  "Car Monitors w/o Player": 48606,
  "Video In-Dash Units w/GPS": 79834,
  "Video In-Dash Units w/o GPS": 79835,
  "Rear View Monitors/Cams & Kits": 149976,
  "Car Monitors w/Built-in Player": 175712,
  "Car Alarms & Security": 48610,
  "Other Car Alarms & Security": 60203,
  "Replacement Remotes": 71530,
  "Relays & Sensors": 75389,
  "Car Alarms & Security Systems": 75390,
  "Car Keys & Transponders": 94830,
  "Car Electronics Accessories": 60207,
  "Audio/Video Remotes": 12040,
  "Radio Antennas": 50553,
  "Power Inverters": 175687,
  "Adapters": 60208,
  "Replacement Faceplates": 71535,
  "Car Lighting": 71536,
  "Remote Start & Entry Systems": 175713,
  "Bluetooth, Handsfree Car Kits": 175714,
  "Other Car Electronics Accs": 175715,
  "12-Volt Portable Appliances": 73335,
  "GPS Accessories & Tracking": 139836,
  "Other GPS Accs & Tracking": 58049,
  "GPS Antennas": 73348,
  "GPS Holders & Mounts": 73353,
  "Tracking Devices": 73362,
  "GPS Software & Maps": 139837,
  "GPS Cases & Skins": 168103,
  "GPS Screen Protectors": 168104,
  "GPS Chargers & Batteries": 175726,
  "GPS Units": 156955,
  "Marine Audio": 168105,
  "Car Audio": 175716,
  "Car Speakers & Speaker Systems": 14936,
  "Car Amplifiers": 18795,
  "Car Subwoofers": 18805,
  "Car CD Changers": 39746,
  "Car Audio In-Dash Units": 39754,
  "Signal Processors": 79839,
  "Other Car Audio": 175717,
  "Camping Tableware": 181383,
  "Surveillance & Smart Home Electronics": 185067,
  "Home Surveillance": 48633,
  "Security Cameras": 48638,
  "Dummy Cameras": 75395,
  "Sensors & Motion Detectors": 115940,
  "CCTV Systems": 159909,
  "Home Surveillance Parts & Accessories": 185048,
  "Surveillance Monitors & Displays": 48635,
  "Security Camera Cables & Adapters": 48637,
  "Surveillance Video Processors & Switchers": 75397,
  "Surveillance DVRs & NVRs": 88754,
  "Other Surveillance Parts & Accessories": 175725,
  "Security Camera Brackets & Mounts": 185049,
  "Security Camera Cases & Housings": 185050,
  "Security Camera Microphones": 185051,
  "Surveillance Illuminators": 185052,
  "Security Camera Controllers & Joysticks": 185116,
  "Security Camera Lenses": 185117,
  "Security Camera Power Supplies": 185118,
  "IP & Smart Security Camera Systems": 185053,
  "Smart Speakers, Hubs & Accessories": 185034,
  "Smart Speakers": 184435,
  "Smart Speaker Accessories": 185043,
  "Smart Home Hubs": 185068,
  "Smart Home Hub Accessories": 185119,
  "Smart Speaker Parts": 260301,
  "Smart Home Hub Parts": 260302,
  "Smart Plugs": 185061,
  "Other Smart Home Electronics": 185065,
  "Major Appliances": 20710,
  "Other Major Appliances": 20715,
  "Washers & Dryers": 42231,
  "Dryers": 71254,
  "Washing Machines": 71256,
  "Washer Dryer Combinations & Sets": 71257,
  "Ranges & Cooking Appliances": 43563,
  "Cooktops": 71246,
  "Ranges & Stoves": 71250,
  "Range Hoods": 71253,
  "Wall Ovens": 71318,
  "Refrigerators & Freezers": 71258,
  "Refrigerators": 20713,
  "Upright & Chest Freezers": 71260,
  "Mini Fridges": 71262,
  "Kegerators": 155678,
  "Wine Fridges & Cellars": 177750,
  "Dishwashers": 116023,
  "Major Appliances Parts & Accessories": 260308,
  "Cooking Appliance Parts": 43566,
  "Washer & Dryer Parts": 99697,
  "Dishwasher Parts": 116026,
  "Refrigerator & Freezer Parts": 184666,
  "Cooking Appliance Accessories": 260136,
  "Video Games & Consoles": 1249,
  "Video Game Merchandise": 38583,
  "Video Game Accessories": 54968,
  "Other Video Game Accessories": 49230,
  "Controllers & Attachments": 117042,
  "Motion Sensors & Cameras": 117044,
  "Memory Cards & Expansion Packs": 117045,
  "Faceplates, Decals & Stickers": 20373,
  "Hard Drives": 171820,
  "Headsets": 80183,
  "Bags, Skins & Travel Cases": 171831,
  "Styluses": 176981,
  "Chargers & Charging Docks": 171858,
  "Cooling Devices": 171898,
  "Keyboards & Keypads": 33963,
  "Mounts & Stands": 182179,
  "Toys to Life": 182180,
  "Video Game Consoles": 139971,
  "Video Games": 139973,
  "Strategy Guides & Cheats": 156595,
  "Prepaid Gaming Cards": 156597,
  "Manuals, Inserts & Box Art": 182174,
  "Original Game Cases & Boxes": 182175,
  "PC & Console VR Headsets": 183068,
  "Cell Phones & Accessories": 15032,
  "Cell Phones & Smartphones": 9355,
  "Cell Phone Accessories": 9394,
  "Cases, Covers & Skins": 183070,
  "Mounts & Holders": 131093,
  "Other Cell Phone Accessories": 42425,
  "Manuals & Guides": 43307,
  "Signal Boosters": 68030,
  "Car Speakerphones": 88468,
  "Straps & Charms": 122962,
  "Audio Docks & Speakers": 132297,
  "Transmitters": 73836,
  "Armbands": 168093,
  "Port Dust Covers": 179406,
  "PDAs": 38331,
  "Cell Phone & Smartphone Parts": 43304,
  "Display Phones": 136699,
  "Phone Cards & SIM Cards": 146492,
  "SIM Cards": 29778,
  "Phone & Data Cards": 43308,
  "SIM Card Accessories": 146493,
  "PDA Accessories": 175744,
  "Smart Watches": 178893,
  "Smart Watch Accessories": 182064,
  "Chargers & Docking Stations": 182065,
  "Smart Watch Cases": 182067,
  "Watch Bands": 182068,
  "Other Smart Watch Accessories": 182069,
  "Vintage Cell Phones": 182073,
  "Computers/Tablets & Networking": 58058,
  "Other Computers & Networking": 162,
  "Drives, Storage & Blank Media": 165,
  "Other Drives, Storage & Media": 167,
  "Floppy, Zip & Jaz Drives": 169,
  "CD, DVD & Blu-ray Duplicators": 31509,
  "Tape & Data Cartridge Drives": 39976,
  "USB Flash Drives": 51071,
  "Blank Media & Accessories": 80133,
  "Other Blank Media & Accs": 11161,
  "Tapes & Data Cartridges": 73329,
  "CD, DVD & Blu-ray Discs": 175718,
  "Floppy, Zip & Jaz Disks": 80136,
  "Disc Repair & Disc Cleaning": 175664,
  "Drive Head & Lens Cleaning": 175665,
  "Media Cases, Sleeves & Wallets": 175666,
  "CD, DVD & Blu-ray Drives": 131542,
  "Drive Bay Caddies": 158816,
  "Drive Enclosures & Docks": 158817,
  "Hard Drive Duplicators": 175671,
  "Hard Drives (HDD, SSD & NAS)": 182085,
  "Internal Hard Disk Drives": 56083,
  "Network Attached Storage": 106273,
  "External Hard Disk Drives": 131553,
  "Solid State Drives": 175669,
  "Other Hard Drives": 175670,
  "Manuals & Resources": 48644,
  "Keyboards, Mice & Pointers": 3676,
  "Other Keyboards & Mice": 170,
  "Graphics Tablets/Boards & Pens": 3680,
  "Mice, Trackballs & Touchpads": 23160,
  "Keyboard & Mouse Bundles": 47779,
  "Remote Controls & Pointers": 51086,
  "Home Networking & Connectivity": 11176,
  "Wired Routers": 11183,
  "Wireless Routers": 44995,
  "Other Home Networking": 44996,
  "USB Bluetooth Adapters/Dongles": 44999,
  "USB Wi-Fi Adapters/Dongles": 45002,
  "Modems": 58297,
  "Boosters, Extenders & Antennas": 61816,
  "VoIP Home Phones": 61840,
  "Powerline Networking": 67262,
  "VoIP Phone Adapters": 99269,
  "Modem-Router Combos": 101270,
  "Wireless Access Points": 175709,
  "Mobile Broadband Devices": 175710,
  "Vintage Computing": 11189,
  "Other Vintage Computing": 4193,
  "Vintage Manuals & Merchandise": 14906,
  "Vintage Computers & Mainframes": 162075,
  "Software": 18793,
  "Other Computer Software": 182,
  "Web & Desktop Publishing": 185,
  "Office & Business": 3768,
  "Education, Language, Reference": 3783,
  "Operating Systems": 11226,
  "Image, Video & Audio": 41859,
  "Hobbies & Leisure": 80015,
  "Servers, Development & DBMS": 80356,
  "Personal Finance, Tax & Legal": 158906,
  "Drivers & Utilities": 158911,
  "Antivirus & Security": 175689,
  "Laptop & Desktop Accessories": 31530,
  "Computer Speakers": 3702,
  "Laptop Docking Stations": 3709,
  "Webcams": 4616,
  "Laptop Batteries": 14295,
  "Mouse Pads & Wrist Rests": 23895,
  "Laptop Power Adapters/Chargers": 31510,
  "Laptop Cases & Bags": 31519,
  "Anti-Theft Locks & Kits": 31529,
  "Other Laptop & Desktop Accs": 31534,
  "Laptop Add-On Cards": 42190,
  "Laptop Network Cards": 42196,
  "Laptop Port Expansion Cards": 42323,
  "Laptop Modem Cards": 74953,
  "Other Laptop Add-On Cards": 96913,
  "USB Lights & Gadgets": 67870,
  "Sound Cards (External)": 75518,
  "Laptop Cooling Pads": 96915,
  "Stands, Holders & Car Mounts": 116346,
  "Keyboard Protectors": 158837,
  "Privacy Filters": 170597,
  "Case Mods, Stickers & Decals": 175677,
  "TV Tuner/Video Capture Devices": 175679,
  "Hard Drive Pouches": 175680,
  "Power Protection, Distribution": 86722,
  "Other Power Protection": 20315,
  "UPS Batteries & Components": 48479,
  "Power Distribution Units": 86723,
  "Surge Protectors, Power Strips": 96907,
  "Uninterruptible Power Supplies": 99265,
  "Monitors, Projectors & Accs": 162497,
  "Projectors": 25321,
  "Monitor Mounts & Stands": 51052,
  "Monitors": 80053,
  "Projector Replacement Parts": 99231,
  "Monitor Power Supplies": 158845,
  "Monitor Replacement Parts": 168068,
  "Other Computer Monitors & Accs": 175688,
  "Projector Accessories": 260137,
  "Tablets & eBook Readers": 171485,
  "Desktops & All-In-Ones": 171957,
  "PC Desktops & All-In-Ones": 179,
  "Apple Desktops & All-In-Ones": 111418,
  "Printers, Scanners & Supplies": 171961,
  "Printers": 1245,
  "Printer Ink, Toner & Paper": 11195,
  "Ink Ribbons": 11202,
  "Ink Cartridges": 16191,
  "Toner Cartridges": 16204,
  "Printer Photo Paper": 73452,
  "Printer Paper": 86728,
  "Ink Refills & Kits": 99319,
  "Empty Ink Cartridges": 156545,
  "Toner Refills & Kits": 175682,
  "Other Ink, Toner & Paper": 175705,
  "Scanners": 40979,
  "Printer & Scanner Parts & Accs": 182086,
  "Feeders & Trays": 11197,
  "Printer Memory": 16211,
  "Laser Drums": 51288,
  "Print Heads": 51328,
  "Power Supplies": 48708,
  "Other Printer & Scanner Accs": 170599,
  "Scanner Parts": 170601,
  "Cleaning & Repair Kits": 175685,
  "Fusers": 175686,
  "Duplexers": 182087,
  "Laptops & Netbooks": 175672,
  "PC Laptops & Netbooks": 177,
  "Apple Laptops": 111422,
  "Computer Components & Parts": 175673,
  "CPUs/Processors": 164,
  "Motherboards": 1244,
  "Video Capture & TV Tuner Cards": 3761,
  "Other Components & Parts": 16145,
  "Graphics/Video Cards": 27386,
  "Fans, Heat Sinks & Cooling": 42000,
  "Other Computer Fans & Cooling": 3673,
  "Thermal Compounds & Supplies": 46322,
  "Video Card/GPU Cooling": 80151,
  "CPU Fans & Heat Sinks": 131486,
  "Computer Case Fans": 131487,
  "Hard Drive Cooling": 131494,
  "Fan Grills & Filters": 131495,
  "Memory & Chipset Cooling": 131502,
  "Water Cooling": 131503,
  "Fan, Heat Sink Brackets & Accs": 170078,
  "Sound Cards (Internal)": 44980,
  "Motherboard & CPU Combos": 131511,
  "Power Supply Testers": 168059,
  "Memory (RAM)": 170083,
  "Computer Cases & Accessories": 175674,
  "Computer Cases": 42014,
  "Case Parts & Accessories": 51064,
  "Interface/Add-On Cards": 182088,
  "Network Cards": 20318,
  "Modem Cards": 44940,
  "Disk Controllers/RAID Cards": 56091,
  "Port Expansion Cards": 90718,
  "Other Interface/Add-On Cards": 90719,
  "Laptop Replacement Parts": 182089,
  "Laptop Replacement Keyboards": 31568,
  "Laptop Screens & LCD Panels": 31569,
  "Other Laptop Replacement Parts": 168061,
  "Laptop Housings & Touchpads": 175676,
  "Motherboard Components & Accs": 182090,
  "Motherboard Diagnostic Cards": 168060,
  "Motherboard Backplates": 170080,
  "Other Motherboard Accessories": 170082,
  "Motherboard Components": 175668,
  "Enterprise Networking, Servers": 175698,
  "Other Enterprise Networking": 11175,
  "Load Balancers": 51167,
  "Firewall & VPN Devices": 51168,
  "Racks, Chassis & Patch Panels": 51197,
  "Patch Panels": 44992,
  "Rackmount Cabinets & Frames": 51199,
  "Rackmount Cases & Chassis": 64061,
  "Other Ent. Networking Racks": 80216,
  "Server Components": 51240,
  "Server Memory (RAM)": 11210,
  "Server CPUs/Processors": 56088,
  "Server Power Supplies": 56090,
  "Other Ent. Server Components": 56092,
  "Server Boards": 71509,
  "Server Fans & Cooling Systems": 168074,
  "VoIP Business Phones/IP PBX": 61839,
  "CSUs/DSUs": 80225,
  "Directional Antennas": 86720,
  "Enterprise Routers": 175699,
  "Servers, Clients & Terminals": 175700,
  "Computer Servers": 11211,
  "Print Servers": 64052,
  "Terminal Servers": 175701,
  "Thin Clients": 175702,
  "Other Ent. Clients & Terminals": 175703,
  "Server Cables & Adapters": 184657,
  "Server Tray Sleds/Caddies": 184658,
  "Server Mount Hardware": 184659,
  "Switches & Hubs": 182091,
  "Network Hubs": 11180,
  "Network Switches": 51268,
  "Switch Modules": 58319,
  "Switch Power Supplies": 58320,
  "Other Ent. Switches & Hubs": 58321,
  "Enterprise Router Components": 182092,
  "Router Modules/Cards/Adapters": 67286,
  "Router Power Supplies": 67287,
  "Router Memory": 67292,
  "Other Ent. Router Components": 175704,
  "Network Storage Disk Arrays": 182093,
  "Other Network Disk Arrays": 11209,
  "SAN Disk Arrays": 58317,
  "Disk Array Components": 58322,
  "NAS Disk Arrays": 64069,
  "Tablet & eBook Reader Accs": 176970,
  "A/V Cables & Adapters": 176972,
  "Cases, Covers, Keyboard Folios": 176973,
  "Chargers & Sync Cables": 176974,
  "Keyboards": 176975,
  "Memory Card & USB Adapters": 176976,
  "Mounts, Stands & Holders": 176977,
  "Reading Lights": 176978,
  "Stickers & Decals": 176980,
  "Other Tablet & eBook Accs": 176982,
  "Tablet & eBook Reader Parts": 180235,
  "Computer Cables & Connectors": 182094,
  "Other Computer Cables": 3668,
  "Networking Cables & Adapters": 20311,
  "Ethernet Cables (RJ-45/8P8C)": 64035,
  "Optical Fiber Cables": 64043,
  "Splitters, Couplers & Adapters": 67276,
  "Plugs, Jacks & Wall Plates": 67279,
  "DSL/Phone Cables (RJ-11)": 67857,
  "Other Networking Cables": 101282,
  "Coaxial Network Cables": 175681,
  "Audio Cables & Adapters": 31492,
  "FireWire Cables & Adapters": 31493,
  "USB Cables, Hubs & Adapters": 44932,
  "Cabling Tools": 51169,
  "Computer Cable Testers": 58300,
  "Cable Ties & Organizers": 67858,
  "Drive Cables & Adapters": 74941,
  "Parallel, Serial & PS/2": 116848,
  "Monitor/AV Cables & Adapters": 158840,
  "KVM Cables": 182095,
  "KVM Switches": 182096,
  "Power Cables & Connectors": 116852,
  "3D Printers & Supplies": 183062,
  "3D Printers": 183063,
  "3D Scanners": 183064,
  "3D Printer Consumables": 183065,
  "3D Printer Parts": 183066,
  "3D Printer Accessories": 260315,
  "3D Scanner Accessories": 260316,
  "3D Scanner Parts": 260317,
  "Consumer Electronics": 293,
  "Radio Communication": 1500,
  "Other Radio Communication": 296,
  "Commercial Radios": 4669,
  "Ham, Amateur Radio": 4670,
  "Ham Radio Receivers": 4673,
  "Ham Radio Transmitters": 4675,
  "Ham Radio Transceivers": 40065,
  "Other Ham Radio Equipment": 96966,
  "Ham Radio Amplifiers": 163853,
  "Shortwave Radios": 15051,
  "Walkie Talkies, Two-Way Radios": 20381,
  "CB Radios": 40054,
  "Marine & Aircraft Radios": 149987,
  "Antennas": 175835,
  "Ham, Amateur Radio Antennas": 4672,
  "CB Radio Antennas": 48692,
  "Marine/Air Band Radio Antennas": 175731,
  "Walkie Talkie/Two-Way Antennas": 175732,
  "Other Radio Antennas": 175733,
  "Parts & Accessories": 183503,
  "Manuals & Magazines": 15050,
  "Other Radio Communication Accs": 40059,
  "Mounts": 48694,
  "Meters": 48701,
  "Batteries & Chargers": 48703,
  "Coax. Cables & Connectors": 48704,
  "Headsets & Earpieces": 48705,
  "Code Keyers & Keys": 48706,
  "Speakers": 48709,
  "Tubes": 48710,
  "Tuners": 48711,
  "Baluns & Chokes": 175735,
  "Oscillators": 175737,
  "Radio & Scanner Crystals": 175738,
  "Home Telephones & Accessories": 3286,
  "Pagers": 3314,
  "Home Telephone Parts": 9425,
  "Caller ID Devices": 15048,
  "Telephone Batteries": 20034,
  "Cordless Telephones & Handsets": 33929,
  "Corded Telephones": 41373,
  "Cords, Cables & Adapters": 58353,
  "Telephone Headsets": 84079,
  "Answering Machines": 139968,
  "Jacks & Plugs": 184642,
  "Portable Audio & Headphones": 15052,
  "Personal Cassette Players": 15053,
  "Personal CD Players": 15054,
  "Personal MiniDisc Recorders": 15056,
  "Portable Stereos & Boomboxes": 48626,
  "Portable Audio Accessories": 56169,
  "Other Portable Audio Accs": 48680,
  "Cassette Adapters": 73835,
  "Remotes": 79877,
  "Device-Specific Batteries": 118261,
  "Mini Plug-in Microphones": 122654,
  "iPods & MP3 Players": 73839,
  "Portable AM/FM Radios": 96954,
  "Portable Satellite Radios": 109256,
  "Audio Docks & Mini Speakers": 111694,
  "Headphones": 112529,
  "Headphone Parts": 184618,
  "Headphone Accessories": 260318,
  "TV, Video & Home Audio": 32852,
  "TV, Video & Audio Accessories": 14961,
  "Remote Controls": 61312,
  "Other TV, Video & Audio Accs": 61395,
  "Media Cases & Storage": 64588,
  "Audio/Video Transmitters": 149958,
  "Splitters & Combiners": 149972,
  "Video Glasses": 163826,
  "Blank Audio/Video Media": 184637,
  "Audio Tapes": 14962,
  "MiniDiscs": 149959,
  "VHS Video Tapes": 149960,
  "TV Tools": 184638,
  "Crimpers": 184639,
  "Strippers": 184640,
  "Other TV Tools": 184641,
  "TV & Video Accessories": 184975,
  "Video Cables & Interconnects": 32834,
  "Signal Amplifiers & Filters": 39803,
  "TV Stands & Mounts": 48656,
  "Satellite Signal Multiswitches": 67880,
  "Signal Finders": 67882,
  "Projector Lamps & Components": 71583,
  "Projector Mounts & Stands": 71584,
  "Satellite LNB Downconverters": 149971,
  "Antenna & Dish Mounting Gear": 149973,
  "3D TV Glasses & Accessories": 175719,
  "Satellite Dishes": 175720,
  "Home Audio Accessories": 184976,
  "Audio Cables & Interconnects": 14964,
  "Speaker Mounts & Stands": 61336,
  "Audio Cable Plugs & Jacks": 175721,
  "Audio/Video Media Cleaning": 258042,
  "A/V Cleaning Kits": 184610,
  "A/V Cleaning Cloths & Brushes": 258044,
  "A/V Cleaning Solutions": 258045,
  "Audio/Video Media Repair": 258046,
  "A/V Buffing & Sanding Pads": 184612,
  "A/V Repair Kits": 184613,
  "A/V Repair Machines & Parts": 184614,
  "TV, Video & Audio Parts": 71582,
  "Cassette Player Parts & Comp.": 31197,
  "Record Player, Turntable Parts": 64619,
  "Other TV, Video & Audio Parts": 71585,
  "Amplifier Parts & Components": 122649,
  "Rear-Projection TV Lamps": 149975,
  "TV Boards, Parts & Components": 163768,
  "Speaker Parts & Components": 184647,
  "Tweeters": 12903,
  "Speaker Boxes": 21995,
  "Other Speaker Parts & Comp.": 21997,
  "Manuals": 96273,
  "Speaker Building Components": 141249,
  "Woofers": 141262,
  "Repair Kits & Consumables": 169024,
  "Electronic Parts & Components": 169025,
  "Speaker Frames": 185025,
  "Speaker Magnets": 185026,
  "Voice Coils": 185027,
  "Other TV, Video & Home Audio": 163829,
  "Media Streamers": 168058,
  "TV & Video": 184972,
  "TVs": 11071,
  "DVRs, Hard Drive Recorders": 11725,
  "Home Theater Projectors": 22610,
  "Cable TV Boxes": 39804,
  "Satellite TV Receivers": 96969,
  "DVD & Blu-ray Players": 175711,
  "Home Audio": 184973,
  "Compact & Shelf Stereos": 4787,
  "Home Audio Components": 14969,
  "MiniDisc Decks": 4786,
  "CD Players & Recorders": 14971,
  "Radio Tuners": 22635,
  "Cassette Tape Decks": 48645,
  "Record Players/Home Turntables": 48647,
  "Other Home Stereo Components": 81741,
  "Stereo Component Combos": 175708,
  "Home Speakers & Subwoofers": 14990,
  "Home Theater Systems": 72406,
  "Receivers & Amplifiers": 184974,
  "Equalizers": 3271,
  "Amplifiers & Preamps": 14970,
  "Receivers": 14981,
  "Multipurpose Batteries & Power": 48446,
  "Battery Chargers": 48618,
  "Rechargeable Batteries": 48619,
  "Single Use Batteries": 50602,
  "Battery Testers": 79845,
  "Other Multipurpose Batteries": 79846,
  "Multipurpose AC to DC Adapters": 88758,
  "Battery Converters": 172011,
  "Heavy-Duty Voltage Converters": 175729,
  "Other Consumer Electronics": 175837,
  "Smart Glasses": 178894,
  "Virtual Reality": 183067,
  "Smartphone VR Headsets": 183069,
  "Controllers & Motion Sensors": 183071,
  "Parts": 183072,
  "Other Virtual Reality Accs": 183073,
  "Standalone VR Headsets": 184645,
  "Vintage Electronics": 183077,
  "Vintage Calculators": 58042,
  "Vintage Audio & Video": 175740,
  "Laserdisc Players": 3317,
  "VCRs": 15088,
  "Vintage Record Players": 48649,
  "Vintage Radios": 50595,
  "Vintage Stereo Receivers": 50596,
  "Vintage Speakers": 50597,
  "Vintage Preamps & Tube Preamps": 67807,
  "Vintage Cassette Decks": 71574,
  "Vintage Amplifiers & Tube Amps": 73368,
  "Vintage Televisions": 73374,
  "Vintage Tuners & Tube Tuners": 73382,
  "Reel-to-Reel Tape Recorders": 116868,
  "Vintage Manuals": 39996,
  "Vintage Tubes & Tube Sockets": 64627,
  "Vintage Transformers": 67815,
  "Vintage Capacitors": 71573,
  "Vintage Speaker & Horn Drivers": 73372,
  "Vintage Blank Media": 163851,
  "Other Vintage A/V Parts & Accs": 168088,
  "Other Vintage Audio & Video": 175742,
  "Vintage Headphones": 183074,
  "8-Track Players": 183078,
  "Vintage Home Telephones": 182098,
  "Other Vintage Electronics": 183079
};

export const ebayCategoryIdToName: Record<number, string> = {
  "162": "Other Computers & Networking",
  "164": "CPUs/Processors",
  "165": "Drives, Storage & Blank Media",
  "167": "Other Drives, Storage & Media",
  "169": "Floppy, Zip & Jaz Drives",
  "170": "Other Keyboards & Mice",
  "177": "PC Laptops & Netbooks",
  "179": "PC Desktops & All-In-Ones",
  "182": "Other Computer Software",
  "185": "Web & Desktop Publishing",
  "293": "Consumer Electronics",
  "296": "Other Radio Communication",
  "625": "Cameras & Photo",
  "629": "Other Darkroom & Developing",
  "711": "Other Vintage Photography",
  "1244": "Motherboards",
  "1245": "Printers",
  "1249": "Video Games & Consoles",
  "1498": "Other Vehicle Electronics",
  "1500": "Radio Communication",
  "3270": "Vehicle Electronics & GPS",
  "3271": "Equalizers",
  "3286": "Home Telephones & Accessories",
  "3314": "Pagers",
  "3317": "Laserdisc Players",
  "3319": "Other Production & Editing",
  "3323": "Lenses",
  "3326": "Vintage Movie & Photography",
  "3516": "Manuals & Resources",
  "3668": "Other Computer Cables",
  "3673": "Other Computer Fans & Cooling",
  "3676": "Keyboards, Mice & Pointers",
  "3680": "Graphics Tablets/Boards & Pens",
  "3702": "Computer Speakers",
  "3709": "Laptop Docking Stations",
  "3761": "Video Capture & TV Tuner Cards",
  "3768": "Office & Business",
  "3783": "Education, Language, Reference",
  "3860": "Other Continuous Lighting",
  "4193": "Other Vintage Computing",
  "4201": "Film",
  "4616": "Webcams",
  "4669": "Commercial Radios",
  "4670": "Ham, Amateur Radio",
  "4672": "Ham, Amateur Radio Antennas",
  "4673": "Ham Radio Receivers",
  "4675": "Ham Radio Transmitters",
  "4684": "Camera Manuals & Guides",
  "4689": "Movie Cameras",
  "4690": "Movie Camera Accessories",
  "4692": "Stereo Cameras",
  "4701": "Other Vintage Cameras",
  "4702": "Vintage Parts & Accessories",
  "4786": "MiniDisc Decks",
  "4787": "Compact & Shelf Stereos",
  "4790": "Movie Editing Equipment",
  "9355": "Cell Phones & Smartphones",
  "9394": "Cell Phone Accessories",
  "9425": "Home Telephone Parts",
  "11071": "TVs",
  "11161": "Other Blank Media & Accs",
  "11175": "Other Enterprise Networking",
  "11176": "Home Networking & Connectivity",
  "11180": "Network Hubs",
  "11183": "Wired Routers",
  "11189": "Vintage Computing",
  "11195": "Printer Ink, Toner & Paper",
  "11197": "Feeders & Trays",
  "11202": "Ink Ribbons",
  "11205": "Scanners",
  "11209": "Other Network Disk Arrays",
  "11210": "Server Memory (RAM)",
  "11211": "Computer Servers",
  "11226": "Operating Systems",
  "11716": "Box Cameras",
  "11717": "Folding Cameras",
  "11718": "Instant Cameras",
  "11719": "Movie Cameras",
  "11720": "SLR Cameras",
  "11721": "TLR Cameras",
  "11722": "Vintage Projectors & Screens",
  "11724": "Camcorders",
  "11725": "DVRs, Hard Drive Recorders",
  "12040": "Audio/Video Remotes",
  "12903": "Tweeters",
  "14295": "Laptop Batteries",
  "14906": "Vintage Manuals & Merchandise",
  "14935": "Radar & Laser Detectors",
  "14936": "Car Speakers & Speaker Systems",
  "14961": "TV, Video & Audio Accessories",
  "14962": "Audio Tapes",
  "14964": "Audio Cables & Interconnects",
  "14969": "Home Audio Components",
  "14970": "Amplifiers & Preamps",
  "14971": "CD Players & Recorders",
  "14981": "Receivers",
  "14990": "Home Speakers & Subwoofers",
  "15032": "Cell Phones & Accessories",
  "15048": "Caller ID Devices",
  "15050": "Manuals & Magazines",
  "15051": "Shortwave Radios",
  "15052": "Portable Audio & Headphones",
  "15053": "Personal Cassette Players",
  "15054": "Personal CD Players",
  "15056": "Personal MiniDisc Recorders",
  "15088": "VCRs",
  "15200": "Camera, Drone & Photo Accessories",
  "15216": "Filters",
  "15226": "Photographic Paper",
  "15230": "Film Cameras",
  "15250": "Slide & Movie Projection",
  "15251": "Projector Bulbs & Lamps",
  "15252": "Movie Projectors",
  "15256": "Slide Projectors",
  "15257": "Other Slide & Movie Projection",
  "16145": "Other Components & Parts",
  "16191": "Ink Cartridges",
  "16204": "Toner Cartridges",
  "16211": "Printer Memory",
  "18793": "Software",
  "18795": "Car Amplifiers",
  "18805": "Car Subwoofers",
  "18871": "Memory Cards",
  "19590": "Continuous Lighting",
  "19591": "Lighting Kits",
  "20034": "Telephone Batteries",
  "20311": "Networking Cables & Adapters",
  "20315": "Other Power Protection",
  "20318": "Network Cards",
  "20349": "Cases, Covers & Skins",
  "20357": "Batteries",
  "20373": "Faceplates, Decals & Stickers",
  "20381": "Walkie Talkies, Two-Way Radios",
  "20710": "Major Appliances",
  "20713": "Refrigerators",
  "20715": "Other Major Appliances",
  "21162": "Video Production & Editing",
  "21168": "Video Recorders & Players",
  "21169": "Distribution Switchers & Routers",
  "21198": "Stock Photo Collections",
  "21517": "Video Monitors",
  "21995": "Speaker Boxes",
  "21997": "Other Speaker Parts & Comp.",
  "22610": "Home Theater Projectors",
  "22635": "Radio Tuners",
  "23160": "Mice, Trackballs & Touchpads",
  "23895": "Mouse Pads & Wrist Rests",
  "25321": "Projectors",
  "27386": "Graphics/Video Cards",
  "27432": "Other Cameras & Photo",
  "28179": "Binoculars & Telescopes",
  "29778": "SIM Cards",
  "29953": "Other Photo Albums & Storage",
  "29954": "Telescope Eyepieces & Lenses",
  "29965": "Rangefinder Units & Accs",
  "29967": "Battery Grips",
  "29970": "Motor Drives & Winders",
  "29973": "Film Backs & Holders",
  "29980": "Lens Boards",
  "29983": "Film Development Chemicals",
  "29985": "Enlargement Equipment",
  "29986": "Enlarging Lenses",
  "29990": "Safelights & Bulbs",
  "29991": "Darkroom Timers",
  "29993": "Processing & Finishing Equip",
  "29994": "Accessory Bundles",
  "30038": "Film Camera Parts",
  "30040": "Filter Rings & Holders",
  "30059": "Lens Adapters, Mounts & Tubes",
  "30078": "Lighting & Studio",
  "30082": "Studio Reflectors",
  "30085": "Props & Stage Equipment",
  "30086": "Slaves & Trigger Systems",
  "30087": "Other Flash Lighting",
  "30088": "Other Lighting & Studio",
  "30089": "Projection Screens & Material",
  "30090": "Tripods & Supports",
  "30093": "Tripods & Monopods",
  "30095": "Tripod Heads",
  "30097": "Other Tripods & Supports",
  "31197": "Cassette Player Parts & Comp.",
  "31388": "Digital Cameras",
  "31492": "Audio Cables & Adapters",
  "31493": "FireWire Cables & Adapters",
  "31509": "CD, DVD & Blu-ray Duplicators",
  "31510": "Laptop Power Adapters/Chargers",
  "31519": "Laptop Cases & Bags",
  "31529": "Anti-Theft Locks & Kits",
  "31530": "Laptop & Desktop Accessories",
  "31534": "Other Laptop & Desktop Accs",
  "31568": "Laptop Replacement Keyboards",
  "31569": "Laptop Screens & LCD Panels",
  "32806": "Car Audio & Video Installation",
  "32810": "Power & Speaker Wire",
  "32834": "Video Cables & Interconnects",
  "32852": "TV, Video & Home Audio",
  "32943": "Telescope Cases & Bags",
  "33513": "Lighting for Video Production",
  "33929": "Cordless Telephones & Handsets",
  "33963": "Keyboards & Keypads",
  "35190": "Mounts & Holders",
  "38331": "PDAs",
  "38583": "Video Game Merchandise",
  "39746": "Car CD Changers",
  "39754": "Car Audio In-Dash Units",
  "39803": "Signal Amplifiers & Filters",
  "39804": "Cable TV Boxes",
  "39976": "Tape & Data Cartridge Drives",
  "39996": "Vintage Manuals",
  "40054": "CB Radios",
  "40059": "Other Radio Communication Accs",
  "40065": "Ham Radio Transceivers",
  "40979": "Scanners",
  "41373": "Corded Telephones",
  "41859": "Image, Video & Audio",
  "42000": "Fans, Heat Sinks & Cooling",
  "42014": "Computer Cases",
  "42017": "Power Supplies",
  "42190": "Laptop Add-On Cards",
  "42196": "Laptop Network Cards",
  "42231": "Washers & Dryers",
  "42323": "Laptop Port Expansion Cards",
  "42425": "Other Cell Phone Accessories",
  "43304": "Cell Phone & Smartphone Parts",
  "43307": "Manuals & Guides",
  "43308": "Phone & Data Cards",
  "43440": "Camera & Camcorder Lights",
  "43448": "Memory Card Readers & Adapters",
  "43479": "Other Film Photography",
  "43563": "Ranges & Cooking Appliances",
  "43566": "Cooking Appliance Parts",
  "44932": "USB Cables, Hubs & Adapters",
  "44940": "Modem Cards",
  "44980": "Sound Cards (Internal)",
  "44992": "Patch Panels",
  "44995": "Wireless Routers",
  "44996": "Other Home Networking",
  "44999": "USB Bluetooth Adapters/Dongles",
  "45002": "USB Wi-Fi Adapters/Dongles",
  "45065": "Mixed Lots",
  "45089": "Mixed Lots",
  "46322": "Thermal Compounds & Supplies",
  "47779": "Keyboard & Mouse Bundles",
  "48446": "Multipurpose Batteries & Power",
  "48479": "UPS Batteries & Components",
  "48515": "Flashes",
  "48571": "Memory Card Readers & Adapters",
  "48604": "Car Video",
  "48605": "Other Car Video",
  "48606": "Car Monitors w/o Player",
  "48610": "Car Alarms & Security",
  "48618": "Battery Chargers",
  "48619": "Rechargeable Batteries",
  "48626": "Portable Stereos & Boomboxes",
  "48633": "Home Surveillance",
  "48635": "Surveillance Monitors & Displays",
  "48637": "Security Camera Cables & Adapters",
  "48638": "Security Cameras",
  "48644": "Manuals & Resources",
  "48645": "Cassette Tape Decks",
  "48647": "Record Players/Home Turntables",
  "48649": "Vintage Record Players",
  "48655": "Projection Screens & Material",
  "48656": "TV Stands & Mounts",
  "48677": "Styluses",
  "48680": "Other Portable Audio Accs",
  "48692": "CB Radio Antennas",
  "48694": "Mounts",
  "48701": "Meters",
  "48703": "Batteries & Chargers",
  "48704": "Coax. Cables & Connectors",
  "48705": "Headsets & Earpieces",
  "48706": "Code Keyers & Keys",
  "48707": "Microphones",
  "48708": "Power Supplies",
  "48709": "Speakers",
  "48710": "Tubes",
  "48711": "Tuners",
  "49230": "Other Video Game Accessories",
  "50503": "Memory Card Cases",
  "50506": "Underwater Cases & Housings",
  "50508": "LCD Hoods",
  "50549": "Connectors & Terminals",
  "50550": "Distribution Blocks",
  "50551": "Fuses & Fuse Holders",
  "50552": "Other Car A/V Installation",
  "50553": "Radio Antennas",
  "50564": "Speaker/Sub. Enclosures",
  "50595": "Vintage Radios",
  "50596": "Vintage Stereo Receivers",
  "50597": "Vintage Speakers",
  "50602": "Single Use Batteries",
  "51052": "Monitor Mounts & Stands",
  "51064": "Case Parts & Accessories",
  "51071": "USB Flash Drives",
  "51082": "Memory Card Readers & Adapters",
  "51086": "Remote Controls & Pointers",
  "51167": "Load Balancers",
  "51168": "Firewall & VPN Devices",
  "51169": "Cabling Tools",
  "51197": "Racks, Chassis & Patch Panels",
  "51199": "Rackmount Cabinets & Frames",
  "51240": "Server Components",
  "51268": "Network Switches",
  "51288": "Laser Drums",
  "51328": "Print Heads",
  "51541": "Video Production Accessories",
  "54968": "Video Game Accessories",
  "56083": "Internal Hard Disk Drives",
  "56088": "Server CPUs/Processors",
  "56090": "Server Power Supplies",
  "56091": "Disk Controllers/RAID Cards",
  "56092": "Other Ent. Server Components",
  "56169": "Portable Audio Accessories",
  "56170": "Cases, Covers & Skins",
  "56172": "Cables & Adapters",
  "58020": "Power Inverters",
  "58042": "Vintage Calculators",
  "58049": "Other GPS Accs & Tracking",
  "58058": "Computers/Tablets & Networking",
  "58297": "Modems",
  "58300": "Computer Cable Testers",
  "58317": "SAN Disk Arrays",
  "58319": "Switch Modules",
  "58320": "Switch Power Supplies",
  "58321": "Other Ent. Switches & Hubs",
  "58322": "Disk Array Components",
  "58353": "Cords, Cables & Adapters",
  "58540": "Screen Protectors",
  "58543": "Accessory Bundles",
  "60203": "Other Car Alarms & Security",
  "60207": "Car Electronics Accessories",
  "60208": "Adapters",
  "61312": "Remote Controls",
  "61336": "Speaker Mounts & Stands",
  "61395": "Other TV, Video & Audio Accs",
  "61816": "Boosters, Extenders & Antennas",
  "61839": "VoIP Business Phones/IP PBX",
  "61840": "VoIP Home Phones",
  "64035": "Ethernet Cables (RJ-45/8P8C)",
  "64043": "Optical Fiber Cables",
  "64052": "Print Servers",
  "64061": "Rackmount Cases & Chassis",
  "64069": "NAS Disk Arrays",
  "64343": "Straps & Hand Grips",
  "64345": "Remotes & Shutter Releases",
  "64352": "Digital Camera Parts",
  "64353": "Flashes & Flash Accessories",
  "64354": "Other Flashes & Flash Accs",
  "64578": "Wire Harnesses",
  "64588": "Media Cases & Storage",
  "64619": "Record Player, Turntable Parts",
  "64627": "Vintage Tubes & Tube Sockets",
  "67262": "Powerline Networking",
  "67276": "Splitters, Couplers & Adapters",
  "67279": "Plugs, Jacks & Wall Plates",
  "67286": "Router Modules/Cards/Adapters",
  "67287": "Router Power Supplies",
  "67292": "Router Memory",
  "67339": "Camcorder Tapes & Discs",
  "67773": "Speaker/Sub. Grills & Accs",
  "67807": "Vintage Preamps & Tube Preamps",
  "67815": "Vintage Transformers",
  "67857": "DSL/Phone Cables (RJ-11)",
  "67858": "Cable Ties & Organizers",
  "67870": "USB Lights & Gadgets",
  "67880": "Satellite Signal Multiswitches",
  "67882": "Signal Finders",
  "68030": "Signal Boosters",
  "69323": "Film Photography",
  "69341": "Lighting Units",
  "69573": "Flash Adapters",
  "71246": "Cooktops",
  "71250": "Ranges & Stoves",
  "71253": "Range Hoods",
  "71254": "Dryers",
  "71256": "Washing Machines",
  "71257": "Washer Dryer Combinations & Sets",
  "71258": "Refrigerators & Freezers",
  "71260": "Upright & Chest Freezers",
  "71262": "Mini Fridges",
  "71318": "Wall Ovens",
  "71509": "Server Boards",
  "71527": "Amplifier Kits",
  "71530": "Replacement Remotes",
  "71535": "Replacement Faceplates",
  "71536": "Car Lighting",
  "71573": "Vintage Capacitors",
  "71574": "Vintage Cassette Decks",
  "71582": "TV, Video & Audio Parts",
  "71583": "Projector Lamps & Components",
  "71584": "Projector Mounts & Stands",
  "71585": "Other TV, Video & Audio Parts",
  "72406": "Home Theater Systems",
  "73329": "Tapes & Data Cartridges",
  "73335": "12-Volt Portable Appliances",
  "73348": "GPS Antennas",
  "73353": "GPS Holders & Mounts",
  "73362": "Tracking Devices",
  "73368": "Vintage Amplifiers & Tube Amps",
  "73372": "Vintage Speaker & Horn Drivers",
  "73374": "Vintage Televisions",
  "73382": "Vintage Tuners & Tube Tuners",
  "73452": "Printer Photo Paper",
  "73459": "Photo Albums & Boxes",
  "73834": "Accessory Bundles",
  "73835": "Cassette Adapters",
  "73836": "Transmitters",
  "73839": "iPods & MP3 Players",
  "74922": "Telescope Parts & Accessories",
  "74923": "Telescope Filters",
  "74925": "Telescope Mirrors & Prisms",
  "74926": "Other Telescope Parts",
  "74927": "Telescopes",
  "74941": "Drive Cables & Adapters",
  "74953": "Laptop Modem Cards",
  "75386": "Capacitors",
  "75389": "Relays & Sensors",
  "75390": "Car Alarms & Security Systems",
  "75395": "Dummy Cameras",
  "75397": "Surveillance Video Processors & Switchers",
  "75518": "Sound Cards (External)",
  "78997": "Lenses & Filters",
  "78999": "Lens Hoods",
  "79000": "Other Lenses & Filters",
  "79001": "Lens Caps",
  "79003": "Background Material",
  "79007": "Light Controls & Modifiers",
  "79008": "Softboxes & Diffusers",
  "79009": "Studio Umbrellas",
  "79010": "Other Light Controls & Mods",
  "79834": "Video In-Dash Units w/GPS",
  "79835": "Video In-Dash Units w/o GPS",
  "79839": "Signal Processors",
  "79845": "Battery Testers",
  "79846": "Other Multipurpose Batteries",
  "79877": "Remotes",
  "80015": "Hobbies & Leisure",
  "80053": "Monitors",
  "80077": "Headsets",
  "80133": "Blank Media & Accessories",
  "80135": "CD, DVD & Blu-ray Discs",
  "80136": "Floppy, Zip & Jaz Disks",
  "80151": "Video Card/GPU Cooling",
  "80183": "Headsets",
  "80216": "Other Ent. Networking Racks",
  "80225": "CSUs/DSUs",
  "80356": "Servers, Development & DBMS",
  "80390": "Auxiliary/Conversion Lenses",
  "81741": "Other Home Stereo Components",
  "83857": "Microphones",
  "83874": "Projection Lenses",
  "83876": "Slide Trays & Carousels",
  "83877": "Slide Viewers",
  "83880": "Binoculars & Monoculars",
  "84079": "Telephone Headsets",
  "85806": "Dashboard Installation Kits",
  "86720": "Directional Antennas",
  "86722": "Power Protection, Distribution",
  "86723": "Power Distribution Units",
  "86728": "Printer Paper",
  "87635": "Flash Lighting Kits",
  "87637": "Monolight Flashes",
  "88468": "Car Speakerphones",
  "88665": "Light Stands & Booms",
  "88667": "Light Stand & Boom Accessories",
  "88668": "Light Meters",
  "88754": "Surveillance DVRs & NVRs",
  "88758": "Multipurpose AC to DC Adapters",
  "89161": "Flash Brackets",
  "90715": "Disk Controllers/RAID Cards",
  "90718": "Port Expansion Cards",
  "90719": "Other Interface/Add-On Cards",
  "94830": "Car Keys & Transponders",
  "94844": "Interconnect Cables",
  "96273": "Manuals",
  "96907": "Surge Protectors, Power Strips",
  "96913": "Other Laptop Add-On Cards",
  "96915": "Laptop Cooling Pads",
  "96954": "Portable AM/FM Radios",
  "96966": "Other Ham Radio Equipment",
  "96969": "Satellite TV Receivers",
  "96991": "Memory Cards",
  "98923": "35mm Cameras",
  "98924": "Rangefinder Cameras",
  "99231": "Projector Replacement Parts",
  "99265": "Uninterruptible Power Supplies",
  "99269": "VoIP Phone Adapters",
  "99319": "Ink Refills & Kits",
  "99697": "Washer & Dryer Parts",
  "100501": "Audio for Video",
  "101270": "Modem-Router Combos",
  "101282": "Other Networking Cables",
  "101356": "Power Supplies",
  "101642": "Vintage Lenses",
  "101643": "Vintage Cameras",
  "101644": "Subminiature Cameras",
  "106273": "Network Attached Storage",
  "107894": "Cases, Bags & Covers",
  "109092": "Disc Cameras",
  "109256": "Portable Satellite Radios",
  "111418": "Apple Desktops & All-In-Ones",
  "111422": "Apple Laptops",
  "111694": "Audio Docks & Mini Speakers",
  "112529": "Headphones",
  "115940": "Sensors & Motion Detectors",
  "116023": "Dishwashers",
  "116026": "Dishwasher Parts",
  "116309": "Microphones",
  "116346": "Stands, Holders & Car Mounts",
  "116848": "Parallel, Serial & PS/2",
  "116852": "Power Cables & Connectors",
  "116868": "Reel-to-Reel Tape Recorders",
  "117042": "Controllers & Attachments",
  "117044": "Motion Sensors & Cameras",
  "117045": "Memory Cards & Expansion Packs",
  "118261": "Device-Specific Batteries",
  "121464": "Flash Diffusers",
  "122649": "Amplifier Parts & Components",
  "122654": "Mini Plug-in Microphones",
  "122962": "Straps & Charms",
  "123417": "Chargers & Cradles",
  "123422": "Cables & Adapters",
  "124270": "Chargers & Cradles",
  "124848": "Flash Lighting",
  "124850": "Flash Heads & Power Supplies",
  "131093": "Mounts & Holders",
  "131486": "CPU Fans & Heat Sinks",
  "131487": "Computer Case Fans",
  "131494": "Hard Drive Cooling",
  "131495": "Fan Grills & Filters",
  "131502": "Memory & Chipset Cooling",
  "131503": "Water Cooling",
  "131511": "Motherboard & CPU Combos",
  "131542": "CD, DVD & Blu-ray Drives",
  "131553": "External Hard Disk Drives",
  "132297": "Audio Docks & Speakers",
  "136699": "Display Phones",
  "139836": "GPS Accessories & Tracking",
  "139837": "GPS Software & Maps",
  "139968": "Answering Machines",
  "139971": "Video Game Consoles",
  "139973": "Video Games",
  "141249": "Speaker Building Components",
  "141262": "Woofers",
  "146492": "Phone Cards & SIM Cards",
  "146493": "SIM Card Accessories",
  "146504": "Transmitters",
  "149958": "Audio/Video Transmitters",
  "149959": "MiniDiscs",
  "149960": "VHS Video Tapes",
  "149971": "Satellite LNB Downconverters",
  "149972": "Splitters & Combiners",
  "149973": "Antenna & Dish Mounting Gear",
  "149975": "Rear-Projection TV Lamps",
  "149976": "Rear View Monitors/Cams & Kits",
  "149987": "Marine & Aircraft Radios",
  "150044": "Digital Photo Frames",
  "151226": "Shooting Tables & Light Tents",
  "151236": "Telescope Adapters",
  "155678": "Kegerators",
  "155707": "Cables & Adapters",
  "155817": "Flash Bulbs & Tubes",
  "155823": "Sync Cords",
  "156545": "Empty Ink Cartridges",
  "156595": "Strategy Guides & Cheats",
  "156597": "Prepaid Gaming Cards",
  "156955": "GPS Units",
  "158816": "Drive Bay Caddies",
  "158817": "Drive Enclosures & Docks",
  "158837": "Keyboard Protectors",
  "158840": "Monitor/AV Cables & Adapters",
  "158845": "Monitor Power Supplies",
  "158846": "Screen Protectors",
  "158906": "Personal Finance, Tax & Legal",
  "158911": "Drivers & Utilities",
  "159909": "CCTV Systems",
  "162044": "Other Binoculars & Telescopes",
  "162045": "Batteries",
  "162046": "Chargers & Cradles",
  "162048": "Easels & Negative Carriers",
  "162053": "Cleaning Equipment & Kits",
  "162075": "Vintage Computers & Mainframes",
  "162480": "Other Camera & Photo Accs",
  "162485": "Binocular Cases & Accessories",
  "162497": "Monitors, Projectors & Accs",
  "163768": "TV Boards, Parts & Components",
  "163769": "Replacement Parts & Tools",
  "163826": "Video Glasses",
  "163829": "Other TV, Video & Home Audio",
  "163851": "Vintage Blank Media",
  "163853": "Ham Radio Amplifiers",
  "166030": "Armbands",
  "167928": "Screen Protectors",
  "167930": "Viewfinders & Eyecups",
  "167938": "Telescope Mounts",
  "167943": "Vintage Film",
  "168058": "Media Streamers",
  "168059": "Power Supply Testers",
  "168060": "Motherboard Diagnostic Cards",
  "168061": "Other Laptop Replacement Parts",
  "168068": "Monitor Replacement Parts",
  "168074": "Server Fans & Cooling Systems",
  "168088": "Other Vintage A/V Parts & Accs",
  "168093": "Armbands",
  "168096": "Screen Protectors",
  "168103": "GPS Cases & Skins",
  "168104": "GPS Screen Protectors",
  "168105": "Marine Audio",
  "169024": "Repair Kits & Consumables",
  "169025": "Electronic Parts & Components",
  "170066": "Digital Camera Backs",
  "170067": "Vintage Manuals & Guides",
  "170078": "Fan, Heat Sink Brackets & Accs",
  "170080": "Motherboard Backplates",
  "170082": "Other Motherboard Accessories",
  "170083": "Memory (RAM)",
  "170597": "Privacy Filters",
  "170599": "Other Printer & Scanner Accs",
  "170601": "Scanner Parts",
  "171485": "Tablets & eBook Readers",
  "171668": "Faceplates, Decals & Stickers",
  "171813": "Batteries",
  "171814": "Cables & Adapters",
  "171820": "Hard Drives",
  "171821": "Headsets",
  "171826": "Accessory Bundles",
  "171831": "Bags, Skins & Travel Cases",
  "171833": "Replacement Parts & Tools",
  "171837": "Screen Protectors",
  "171839": "Styluses",
  "171858": "Chargers & Charging Docks",
  "171898": "Cooling Devices",
  "171957": "Desktops & All-In-Ones",
  "171961": "Printers, Scanners & Supplies",
  "172011": "Battery Converters",
  "172510": "Keyboards & Keypads",
  "172511": "Stabilizers",
  "172513": "Camcorder Parts",
  "172514": "Camera Tools & Repair Kits",
  "172516": "Light Bulbs & Tubes",
  "175664": "Disc Repair & Disc Cleaning",
  "175665": "Drive Head & Lens Cleaning",
  "175666": "Media Cases, Sleeves & Wallets",
  "175668": "Motherboard Components",
  "175669": "Solid State Drives",
  "175670": "Other Hard Drives",
  "175671": "Hard Drive Duplicators",
  "175672": "Laptops & Netbooks",
  "175673": "Computer Components & Parts",
  "175674": "Computer Cases & Accessories",
  "175676": "Laptop Housings & Touchpads",
  "175677": "Case Mods, Stickers & Decals",
  "175678": "Cleaning Equipment & Kits",
  "175679": "TV Tuner/Video Capture Devices",
  "175680": "Hard Drive Pouches",
  "175681": "Coaxial Network Cables",
  "175682": "Toner Refills & Kits",
  "175685": "Cleaning & Repair Kits",
  "175686": "Fusers",
  "175687": "Power Inverters",
  "175688": "Other Computer Monitors & Accs",
  "175689": "Antivirus & Security",
  "175690": "Vintage Parts & Accessories",
  "175698": "Enterprise Networking, Servers",
  "175699": "Enterprise Routers",
  "175700": "Servers, Clients & Terminals",
  "175701": "Terminal Servers",
  "175702": "Thin Clients",
  "175703": "Other Ent. Clients & Terminals",
  "175704": "Other Ent. Router Components",
  "175705": "Other Ink, Toner & Paper",
  "175708": "Stereo Component Combos",
  "175709": "Wireless Access Points",
  "175710": "Mobile Broadband Devices",
  "175711": "DVD & Blu-ray Players",
  "175712": "Car Monitors w/Built-in Player",
  "175713": "Remote Start & Entry Systems",
  "175714": "Bluetooth, Handsfree Car Kits",
  "175715": "Other Car Electronics Accs",
  "175716": "Car Audio",
  "175717": "Other Car Audio",
  "175718": "CD, DVD & Blu-ray Discs",
  "175719": "3D TV Glasses & Accessories",
  "175720": "Satellite Dishes",
  "175721": "Audio Cable Plugs & Jacks",
  "175725": "Other Surveillance Parts & Accessories",
  "175726": "GPS Chargers & Batteries",
  "175729": "Heavy-Duty Voltage Converters",
  "175731": "Marine/Air Band Radio Antennas",
  "175732": "Walkie Talkie/Two-Way Antennas",
  "175733": "Other Radio Antennas",
  "175735": "Baluns & Chokes",
  "175736": "Filters",
  "175737": "Oscillators",
  "175738": "Radio & Scanner Crystals",
  "175740": "Vintage Audio & Video",
  "175741": "Vintage Parts & Accessories",
  "175742": "Other Vintage Audio & Video",
  "175744": "PDA Accessories",
  "175835": "Antennas",
  "175837": "Other Consumer Electronics",
  "176970": "Tablet & eBook Reader Accs",
  "176971": "Accessory Bundles",
  "176972": "A/V Cables & Adapters",
  "176973": "Cases, Covers, Keyboard Folios",
  "176974": "Chargers & Sync Cables",
  "176975": "Keyboards",
  "176976": "Memory Card & USB Adapters",
  "176977": "Mounts, Stands & Holders",
  "176978": "Reading Lights",
  "176979": "Screen Protectors",
  "176980": "Stickers & Decals",
  "176981": "Styluses",
  "176982": "Other Tablet & eBook Accs",
  "177750": "Wine Fridges & Cellars",
  "178893": "Smart Watches",
  "178894": "Smart Glasses",
  "179406": "Port Dust Covers",
  "179697": "Camera Drones",
  "180235": "Tablet & eBook Reader Parts",
  "181383": "Camping Tableware",
  "182064": "Smart Watch Accessories",
  "182065": "Chargers & Docking Stations",
  "182066": "Screen Protectors",
  "182067": "Smart Watch Cases",
  "182068": "Watch Bands",
  "182069": "Other Smart Watch Accessories",
  "182073": "Vintage Cell Phones",
  "182074": "Replacement Parts & Tools",
  "182075": "Photo Albums & Storage",
  "182076": "Photo Pages, Sheets & Sleeves",
  "182077": "Darkroom & Developing",
  "182085": "Hard Drives (HDD, SSD & NAS)",
  "182086": "Printer & Scanner Parts & Accs",
  "182087": "Duplexers",
  "182088": "Interface/Add-On Cards",
  "182089": "Laptop Replacement Parts",
  "182090": "Motherboard Components & Accs",
  "182091": "Switches & Hubs",
  "182092": "Enterprise Router Components",
  "182093": "Network Storage Disk Arrays",
  "182094": "Computer Cables & Connectors",
  "182095": "KVM Cables",
  "182096": "KVM Switches",
  "182097": "Power Cables & Connectors",
  "182098": "Vintage Home Telephones",
  "182174": "Manuals, Inserts & Box Art",
  "182175": "Original Game Cases & Boxes",
  "182179": "Mounts & Stands",
  "182180": "Toys to Life",
  "182969": "Camera Drone Accessories",
  "183062": "3D Printers & Supplies",
  "183063": "3D Printers",
  "183064": "3D Scanners",
  "183065": "3D Printer Consumables",
  "183066": "3D Printer Parts",
  "183067": "Virtual Reality",
  "183068": "PC & Console VR Headsets",
  "183069": "Smartphone VR Headsets",
  "183070": "Cases, Covers & Skins",
  "183071": "Controllers & Motion Sensors",
  "183072": "Parts",
  "183073": "Other Virtual Reality Accs",
  "183074": "Vintage Headphones",
  "183077": "Vintage Electronics",
  "183078": "8-Track Players",
  "183079": "Other Vintage Electronics",
  "183502": "Antennas",
  "183503": "Parts & Accessories",
  "184435": "Smart Speakers",
  "184610": "A/V Cleaning Kits",
  "184612": "A/V Buffing & Sanding Pads",
  "184613": "A/V Repair Kits",
  "184614": "A/V Repair Machines & Parts",
  "184618": "Headphone Parts",
  "184620": "Video Servers & Streaming Dev.",
  "184622": "Signal Generators",
  "184623": "Production Switchers",
  "184637": "Blank Audio/Video Media",
  "184638": "TV Tools",
  "184639": "Crimpers",
  "184640": "Strippers",
  "184641": "Other TV Tools",
  "184642": "Jacks & Plugs",
  "184645": "Standalone VR Headsets",
  "184647": "Speaker Parts & Components",
  "184648": "Converters, Scalers & Processors",
  "184657": "Server Cables & Adapters",
  "184658": "Server Tray Sleds/Caddies",
  "184659": "Server Mount Hardware",
  "184666": "Refrigerator & Freezer Parts",
  "184972": "TV & Video",
  "184973": "Home Audio",
  "184974": "Receivers & Amplifiers",
  "184975": "TV & Video Accessories",
  "184976": "Home Audio Accessories",
  "185014": "Extenders",
  "185015": "Production Controllers & Panels",
  "185016": "Waveform Monitors",
  "185025": "Speaker Frames",
  "185026": "Speaker Magnets",
  "185027": "Voice Coils",
  "185034": "Smart Speakers, Hubs & Accessories",
  "185043": "Smart Speaker Accessories",
  "185048": "Home Surveillance Parts & Accessories",
  "185049": "Security Camera Brackets & Mounts",
  "185050": "Security Camera Cases & Housings",
  "185051": "Security Camera Microphones",
  "185052": "Surveillance Illuminators",
  "185053": "IP & Smart Security Camera Systems",
  "185061": "Smart Plugs",
  "185065": "Other Smart Home Electronics",
  "185067": "Surveillance & Smart Home Electronics",
  "185068": "Smart Home Hubs",
  "185116": "Security Camera Controllers & Joysticks",
  "185117": "Security Camera Lenses",
  "185118": "Security Camera Power Supplies",
  "185119": "Smart Home Hub Accessories",
  "258042": "Audio/Video Media Cleaning",
  "258044": "A/V Cleaning Cloths & Brushes",
  "258045": "A/V Cleaning Solutions",
  "258046": "Audio/Video Media Repair",
  "259696": "Mixed Lots",
  "259701": "Mixed Lots",
  "260000": "Mixed Lots",
  "260136": "Cooking Appliance Accessories",
  "260137": "Projector Accessories",
  "260144": "Camera Drone Parts",
  "260301": "Smart Speaker Parts",
  "260302": "Smart Home Hub Parts",
  "260308": "Major Appliances Parts & Accessories",
  "260315": "3D Printer Accessories",
  "260316": "3D Scanner Accessories",
  "260317": "3D Scanner Parts",
  "260318": "Headphone Accessories"
};

export const ebayCategoryIds: readonly number[] = [
  625,
  3326,
  711,
  4702,
  11722,
  101642,
  101643,
  4692,
  4701,
  11716,
  11717,
  11718,
  11719,
  11720,
  11721,
  98923,
  98924,
  101644,
  109092,
  167943,
  170067,
  4684,
  11724,
  15200,
  18871,
  29967,
  29994,
  43440,
  43448,
  50503,
  50506,
  50508,
  64343,
  64345,
  67339,
  83857,
  107894,
  155707,
  162045,
  162046,
  162053,
  162480,
  167928,
  167930,
  182075,
  29953,
  73459,
  182076,
  182969,
  21162,
  3319,
  21168,
  21169,
  21517,
  33513,
  51541,
  100501,
  184620,
  184622,
  184623,
  184648,
  185014,
  185015,
  185016,
  27432,
  28179,
  74922,
  29954,
  32943,
  74923,
  74925,
  74926,
  151236,
  167938,
  74927,
  83880,
  162044,
  162485,
  30078,
  19590,
  3860,
  19591,
  69341,
  172516,
  21198,
  30085,
  30088,
  79003,
  79007,
  30082,
  79008,
  79009,
  79010,
  88665,
  88667,
  88668,
  124848,
  30086,
  30087,
  87635,
  87637,
  124850,
  155817,
  151226,
  30090,
  30093,
  30095,
  30097,
  172511,
  31388,
  45089,
  64353,
  48515,
  64354,
  69573,
  89161,
  121464,
  155823,
  69323,
  4201,
  4689,
  4690,
  4790,
  15230,
  15250,
  15251,
  15252,
  15256,
  15257,
  30089,
  83874,
  83876,
  83877,
  29965,
  29970,
  29973,
  29980,
  43479,
  170066,
  182077,
  629,
  15226,
  29983,
  29985,
  29986,
  29990,
  29991,
  29993,
  162048,
  78997,
  3323,
  15216,
  30040,
  30059,
  78999,
  79000,
  79001,
  80390,
  150044,
  179697,
  182074,
  30038,
  64352,
  172513,
  172514,
  260144,
  3270,
  1498,
  14935,
  32806,
  32810,
  50549,
  50550,
  50551,
  50552,
  50564,
  64578,
  67773,
  71527,
  75386,
  85806,
  94844,
  48604,
  48605,
  48606,
  79834,
  79835,
  149976,
  175712,
  48610,
  60203,
  71530,
  75389,
  75390,
  94830,
  60207,
  12040,
  50553,
  58020,
  60208,
  71535,
  71536,
  175713,
  175714,
  175715,
  73335,
  139836,
  58049,
  73348,
  73353,
  73362,
  139837,
  168103,
  168104,
  175726,
  156955,
  168105,
  175716,
  14936,
  18795,
  18805,
  39746,
  39754,
  79839,
  175717,
  181383,
  185067,
  48633,
  48638,
  75395,
  115940,
  159909,
  185048,
  48635,
  48637,
  75397,
  88754,
  175725,
  185049,
  185050,
  185051,
  185052,
  185116,
  185117,
  185118,
  185053,
  185034,
  184435,
  185043,
  185068,
  185119,
  260301,
  260302,
  185061,
  185065,
  20710,
  20715,
  42231,
  71254,
  71256,
  71257,
  43563,
  71246,
  71250,
  71253,
  71318,
  71258,
  20713,
  71260,
  71262,
  155678,
  177750,
  116023,
  260308,
  43566,
  99697,
  116026,
  184666,
  260136,
  1249,
  38583,
  54968,
  49230,
  117042,
  117044,
  117045,
  171668,
  171813,
  171814,
  171820,
  171821,
  171826,
  171831,
  171837,
  171839,
  171858,
  171898,
  172510,
  182179,
  182180,
  139971,
  139973,
  156595,
  156597,
  171833,
  182174,
  182175,
  260000,
  183068,
  15032,
  9355,
  9394,
  20349,
  20357,
  20373,
  35190,
  42425,
  43307,
  48571,
  48677,
  58540,
  58543,
  68030,
  80077,
  88468,
  96991,
  122962,
  123417,
  123422,
  132297,
  146504,
  166030,
  179406,
  38331,
  43304,
  45065,
  136699,
  146492,
  29778,
  43308,
  146493,
  175744,
  178893,
  182064,
  182065,
  182066,
  182067,
  182068,
  182069,
  182073,
  58058,
  162,
  165,
  167,
  169,
  31509,
  39976,
  51071,
  80133,
  11161,
  73329,
  80135,
  80136,
  175664,
  175665,
  175666,
  131542,
  158816,
  158817,
  175671,
  182085,
  56083,
  106273,
  131553,
  175669,
  175670,
  3516,
  3676,
  170,
  3680,
  23160,
  33963,
  47779,
  51086,
  11176,
  11183,
  44995,
  44996,
  44999,
  45002,
  58297,
  61816,
  61840,
  67262,
  99269,
  101270,
  175709,
  175710,
  11189,
  4193,
  14906,
  162075,
  175690,
  18793,
  182,
  185,
  3768,
  3783,
  11226,
  41859,
  80015,
  80356,
  158906,
  158911,
  175689,
  31530,
  3702,
  3709,
  4616,
  14295,
  23895,
  31510,
  31519,
  31529,
  31534,
  42190,
  42196,
  42323,
  74953,
  96913,
  51082,
  67870,
  75518,
  80183,
  96915,
  116309,
  116346,
  158837,
  158846,
  170597,
  175677,
  175678,
  175679,
  175680,
  86722,
  20315,
  48479,
  86723,
  96907,
  99265,
  175687,
  162497,
  25321,
  51052,
  80053,
  99231,
  158845,
  168068,
  175688,
  260137,
  171485,
  171957,
  179,
  111418,
  171961,
  1245,
  11195,
  11202,
  16191,
  16204,
  73452,
  86728,
  99319,
  156545,
  175682,
  175705,
  11205,
  182086,
  11197,
  16211,
  51288,
  51328,
  101356,
  170599,
  170601,
  175685,
  175686,
  182087,
  175672,
  177,
  111422,
  175673,
  164,
  1244,
  3761,
  16145,
  27386,
  42000,
  3673,
  46322,
  80151,
  131486,
  131487,
  131494,
  131495,
  131502,
  131503,
  170078,
  42017,
  44980,
  131511,
  168059,
  170083,
  175674,
  42014,
  51064,
  182088,
  20318,
  44940,
  90715,
  90718,
  90719,
  182089,
  31568,
  31569,
  168061,
  175676,
  182090,
  168060,
  170080,
  170082,
  175668,
  175698,
  11175,
  51167,
  51168,
  51197,
  44992,
  51199,
  64061,
  80216,
  51240,
  11210,
  56088,
  56090,
  56091,
  56092,
  71509,
  168074,
  61839,
  80225,
  86720,
  175699,
  175700,
  11211,
  64052,
  175701,
  175702,
  175703,
  184657,
  184658,
  184659,
  182091,
  11180,
  51268,
  58319,
  58320,
  58321,
  182092,
  67286,
  67287,
  67292,
  175704,
  182093,
  11209,
  58317,
  58322,
  64069,
  176970,
  176971,
  176972,
  176973,
  176974,
  176975,
  176976,
  176977,
  176978,
  176979,
  176980,
  176981,
  176982,
  180235,
  182094,
  3668,
  20311,
  64035,
  64043,
  67276,
  67279,
  67857,
  101282,
  175681,
  31492,
  31493,
  44932,
  51169,
  58300,
  67858,
  74941,
  116848,
  158840,
  182095,
  182096,
  182097,
  183062,
  183063,
  183064,
  183065,
  183066,
  260315,
  260316,
  260317,
  259696,
  293,
  1500,
  296,
  4669,
  4670,
  4673,
  4675,
  40065,
  96966,
  163853,
  15051,
  20381,
  40054,
  40979,
  149987,
  183502,
  4672,
  48692,
  175731,
  175732,
  175733,
  183503,
  15050,
  40059,
  48694,
  48701,
  48703,
  48704,
  48705,
  48706,
  48707,
  48708,
  48709,
  48710,
  48711,
  175735,
  175736,
  175737,
  175738,
  3286,
  3314,
  9425,
  15048,
  20034,
  33929,
  41373,
  58353,
  84079,
  139968,
  184642,
  15052,
  15053,
  15054,
  15056,
  48626,
  56169,
  48680,
  56170,
  56172,
  73834,
  73835,
  73836,
  79877,
  118261,
  122654,
  124270,
  131093,
  168093,
  168096,
  73839,
  96954,
  109256,
  111694,
  112529,
  163769,
  184618,
  260318,
  32852,
  14961,
  48644,
  61312,
  61395,
  64588,
  149958,
  149972,
  163826,
  184637,
  14962,
  149959,
  149960,
  175718,
  184638,
  184639,
  184640,
  184641,
  184975,
  32834,
  39803,
  48655,
  48656,
  67880,
  67882,
  71583,
  71584,
  149971,
  149973,
  175719,
  175720,
  175835,
  184976,
  14964,
  61336,
  175721,
  258042,
  184610,
  258044,
  258045,
  258046,
  184612,
  184613,
  184614,
  71582,
  31197,
  64619,
  71585,
  122649,
  149975,
  163768,
  184647,
  12903,
  21995,
  21997,
  96273,
  141249,
  141262,
  169024,
  169025,
  185025,
  185026,
  185027,
  163829,
  168058,
  184972,
  11071,
  11725,
  22610,
  39804,
  96969,
  175711,
  184973,
  4787,
  14969,
  4786,
  14971,
  22635,
  48645,
  48647,
  81741,
  175708,
  14990,
  72406,
  184974,
  3271,
  14970,
  14981,
  48446,
  48618,
  48619,
  50602,
  79845,
  79846,
  88758,
  116852,
  172011,
  175729,
  175837,
  178894,
  183067,
  183069,
  183070,
  183071,
  183072,
  183073,
  184645,
  183077,
  58042,
  175740,
  3317,
  15088,
  48649,
  50595,
  50596,
  50597,
  67807,
  71574,
  73368,
  73374,
  73382,
  116868,
  175741,
  39996,
  64627,
  67815,
  71573,
  73372,
  163851,
  168088,
  175742,
  183074,
  183078,
  182098,
  183079,
  259701
] as const;
