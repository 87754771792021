import { isSameFile } from '@shared';
import { create } from 'zustand';

import type { QuoteItem } from '@backend/types';
export type PendingFile = {
  uploadId: string;
  file: File;
  uploaded: boolean;
};

export type QuoteItemDialogOperation = 'create' | 'update';

interface QuoteStore {
  filenameToPendingFile: Map<string, PendingFile>;

  selected: string[];
  success: string;
  error: string | null;

  itemDialog: {
    open: boolean;
    operation: QuoteItemDialogOperation;
    item?: QuoteItem;
  };

  addFiles: (files: File[]) => void;
  deleteFile: (file: PendingFile) => void;
  updateFileUploadedStatus: (file: PendingFile, uploaded: boolean) => void;

  setSelected: (item_ids: string[]) => void;
  setSuccess: (success: string) => void;
  setError: (error: string | null) => void;

  setItemDialogState: (
    open: boolean,
    operation?: QuoteItemDialogOperation,
    item?: QuoteItem
  ) => void;
}

export const useQuoteStore = create<QuoteStore>((set, get) => ({
  filenameToPendingFile: new Map<string, PendingFile>(),
  selected: [],
  success: '',
  error: null,
  itemDialog: {
    open: false,
    operation: 'create',
    item: undefined,
  },

  addFiles: async (newFiles) => {
    const filenameToPendingFile = new Map(get().filenameToPendingFile);

    let changed = false;
    for (const file of newFiles) {
      // Create a new pending file object
      const pendingFile: PendingFile = {
        uploadId: '',
        file,
        uploaded: false,
      };

      const existingFile = filenameToPendingFile.get(file.name);

      // If the file is already in the store and is the same file, don't add it
      if (existingFile && isSameFile(existingFile.file, file)) {
        continue;
      }

      // Add the file to the store, or update it if it already exists
      filenameToPendingFile.set(file.name, pendingFile);
      changed = true;
    }

    // Only update the store if a file was added or updated
    // Prevents unnecessary re-renders
    if (changed) {
      set({
        filenameToPendingFile,
      });
    }
  },
  deleteFile: ({ file }) => {
    set((state) => {
      const files = new Map(state.filenameToPendingFile);
      files.delete(file.name);
      return { filenameToPendingFile: files };
    });
  },

  updateFileUploadedStatus: (pendingFile, uploaded) => {
    set((state) => {
      const files = new Map(state.filenameToPendingFile);
      const newFile: PendingFile = { ...pendingFile, uploaded };
      files.set(pendingFile.file.name, newFile);
      return { filenameToPendingFile: files };
    });
  },

  setSelected: (selected) => set({ selected }),
  setSuccess: (success) => set({ success }),
  setError: (error) => set({ error }),

  setItemDialogState: (open, operation, item) =>
    set((state) => ({
      itemDialog: {
        open,
        operation: operation ?? state.itemDialog.operation,
        item,
      },
    })),
}));
