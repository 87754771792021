import { Typography, styled } from '@mui/joy';
import MuiDivider from '@mui/joy/Divider';
import MuiList from '@mui/joy/List';
import MuiListItem from '@mui/joy/ListItem';
import * as React from 'react';

const List = styled(MuiList)(({ theme }) => ({
  width: theme.spacing(40),
  maxHeight: 540,
  overflow: 'auto',
  padding: theme.spacing(1, 0),
}));

const ListItem = styled(MuiListItem)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 0.5,
});

const Divider = styled(MuiDivider)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const notifications = [
  {
    title: 'Welcome to GreenSight',
    message:
      "We're excited to have you on board! Let us know if you have any questions.",
    date: '2024-09-02',
  },
];

const NotificationsList = () => {
  return (
    <List data-testid="notifications-list">
      {notifications.map((notif, idx) => (
        <React.Fragment key={notif.title + notif.date}>
          <ListItem data-testid="notification-inst">
            <Typography level="title-md" data-testid="notification-title">
              {notif.title}
            </Typography>
            <Typography level="body-sm" data-testid="notification-message">
              {notif.message}
            </Typography>
            <Typography level="body-xs" data-testid="notification-date">
              {new Date(notif.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </Typography>
          </ListItem>
          {idx < notifications.length - 1 ? <Divider /> : null}
        </React.Fragment>
      ))}
    </List>
  );
};

export default NotificationsList;
