import { createId } from '@paralleldrive/cuid2';
import { sql } from 'drizzle-orm';
import { relations } from 'drizzle-orm/relations';
import { sqliteTable, text, numeric, real, int } from 'drizzle-orm/sqlite-core';

// Quote Details

export const quoteDetails = sqliteTable('QuoteDetail', {
  id: text('id')
    .primaryKey()
    .$default(() => createId()),
  createdAt: numeric('createdAt')
    .notNull()
    .default(sql`(CURRENT_TIMESTAMP)`),
  updatedAt: numeric('updatedAt')
    .notNull()
    .default(sql`(current_timestamp)`)
    .$onUpdate(() => sql`(current_timestamp)`),
  tenantId: text('tenantId').notNull(),
  quoteName: text('quoteName').notNull(),
  quoteDescription: text('quoteDescription'),
  status: text('status', { enum: ['draft', 'completed'] })
    .default('draft')
    .notNull(),
  estValue: text('estValue'),
});

export type QuoteDetailDB = typeof quoteDetails.$inferSelect;
export type NewQuoteDetailDB = typeof quoteDetails.$inferInsert;

export const QuoteDetailRelations = relations(quoteDetails, ({ many }) => ({
  items: many(quoteItem),
}));

// Item

export const quoteItem = sqliteTable('QuoteItem', {
  id: text('id')
    .primaryKey()
    .$default(() => createId()),
  quoteId: text('quoteId')
    .notNull()
    .references(() => quoteDetails.id, {
      onDelete: 'restrict',
      onUpdate: 'cascade',
    }),
  status: text('status', { enum: ['pending', 'estimated'] })
    .notNull()
    .default('pending'),
  category: text('category').default('Other').notNull(),
  manufacturer: text('manufacturer').default('Other').notNull(),
  model: text('model').notNull(),
  identifier: text('identifier'),
  quantity: real('quantity').notNull(),
  minUnitPrice: real('minUnitPrice'),
  maxUnitPrice: real('maxUnitPrice'),
  fixedUnitPrice: real('fixedUnitPrice'),
  inFinalQuote: int('inFinalQuote', { mode: 'boolean' }).default(false),
});

export type QuoteItemDB = typeof quoteDetails.$inferSelect;
export type NewQuoteItemDB = typeof quoteItem.$inferInsert;

export const QuoteItemRelations = relations(quoteItem, ({ one }) => ({
  details: one(quoteDetails, {
    fields: [quoteItem.quoteId],
    references: [quoteDetails.id],
  }),
}));
