import { getConfig, type DeploymentStage } from './config';

export function getTenantDomain(hostname: string, stage: DeploymentStage) {
  // Extract the domain from the configuration (ignore the port if present)
  const frontendDomain = getConfig(stage).FRONTEND_DOMAIN.replace(/:\d+$/, '');

  if (stage === 'production') {
    return hostname === frontendDomain ? '' : hostname.split('.')[0];
  } else {
    // Create a dynamic regex to match subdomains not equal to the FRONTEND_DOMAIN
    const domainParts = frontendDomain.split('.');
    const regex = new RegExp(
      `^([a-zA-Z0-9-]+)\\.(?=.*\\.${domainParts[domainParts.length - 2]}\\.${domainParts[domainParts.length - 1]}$)`
    );

    const match = hostname.match(regex);
    return match ? match[1] : '';
  }
}
