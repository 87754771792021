import { Card } from '@mui/joy';
import Box from '@mui/joy/Box';
import { useLoaderData, type LoaderFunctionArgs } from 'react-router-dom';

import PricingStep from '../../../components/Quote/PricingStep/PricingStep';
import NewQuoteStepper from '../../../components/Quote/Stepper/NewQuoteStepper';
import { clientUtils } from '../../../util/trpc-client';

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const quoteDetail = await clientUtils.quotes.getQuoteDetailsById.ensureData({
    id: params.quoteId!,
  });

  const finalQuoteItems =
    await clientUtils.quotes.getAllItemsInFinalQuote.ensureData({
      id: quoteDetail.id,
    });

  return {
    quoteDetail,
    finalQuoteItems,
  };
};

const QuotePricing = () => {
  const { quoteDetail } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        width: '100%',
        maxWidth: 1500,
        margin: '0 auto',
      }}
    >
      <NewQuoteStepper finalized={quoteDetail.status === 'completed'} />
      <Card sx={{ width: '100%' }}>
        <PricingStep />
      </Card>
    </Box>
  );
};

export default QuotePricing;
