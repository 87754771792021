import { createId } from '@paralleldrive/cuid2';
import { sql } from 'drizzle-orm';
import { relations } from 'drizzle-orm/relations';
import {
  sqliteTable,
  integer,
  text,
  numeric,
  uniqueIndex,
  real,
} from 'drizzle-orm/sqlite-core';

export const d1Migrations = sqliteTable('d1_migrations', {
  id: integer('id').primaryKey({ autoIncrement: true }),
  name: text('name'),
  applied_at: numeric('applied_at')
    .default(sql`(CURRENT_TIMESTAMP)`)
    .notNull(),
});

export const photos = sqliteTable('Photo', {
  id: text('id')
    .primaryKey()
    .$default(() => createId()),
  createdAt: numeric('createdAt')
    .notNull()
    .default(sql`(CURRENT_TIMESTAMP)`),
  deviceId: text('deviceId')
    .notNull()
    .references(() => devices.id, {
      onDelete: 'restrict',
      onUpdate: 'cascade',
    }),
});

export type PhotoDB = typeof photos.$inferSelect;
export type NewPhotoDB = typeof photos.$inferInsert;

export const devices = sqliteTable(
  'Device',
  {
    id: text('id')
      .primaryKey()
      .$default(() => createId()),
    createdAt: numeric('createdAt')
      .notNull()
      .default(sql`(CURRENT_TIMESTAMP)`),
    updatedAt: numeric('updatedAt')
      .notNull()
      .default(sql`(current_timestamp)`)
      .$onUpdate(() => sql`(current_timestamp)`),
    tenantId: text('tenantId').notNull(),
    assetTag: text('assetTag'),
    manufacturer: text('manufacturer').notNull(),
    model: text('model'),
    serialNumber: text('serialNumber'),
    releaseYear: integer('releaseYear'),
    category: text('category').default('Other').notNull(),
    description: text('description'),
    status: text('status').default('incomplete').notNull(),
    price: real('price'),
  },
  (table) => {
    return {
      tenantId_assetTag_key: uniqueIndex('Device_tenantId_assetTag_key').on(
        table.tenantId,
        table.assetTag
      ),
    };
  }
);

export type DeviceDB = typeof devices.$inferSelect;
export type NewDeviceDB = typeof devices.$inferInsert;

export const PhotoRelations = relations(photos, ({ one }) => ({
  device: one(devices, {
    fields: [photos.deviceId],
    references: [devices.id],
  }),
}));

export const DeviceRelations = relations(devices, ({ many }) => ({
  photos: many(photos),
}));
