import { httpBatchLink, httpLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';

import type { AppConfiguration } from './config';
import type { AppRouter } from '@backend';
import type { CreateTRPCClientOptions } from '@trpc/client';
import type { HTTPLinkBaseOptions } from '@trpc/client/dist/links/internals/httpUtils';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';

export const trpc = createTRPCReact<AppRouter>({
  abortOnUnmount: true,
});

export const getTrpcClientOptions = (
  config: AppConfiguration
): CreateTRPCClientOptions<AppRouter> => {
  const linkConfig: HTTPLinkBaseOptions<any> = {
    url: `https://${config.clientEnv.domain}/trpc`,
    fetch(url, options) {
      return fetch(url, {
        ...options,
        credentials: 'include',
      });
    },
  };

  const link = config.unitTest
    ? httpLink(linkConfig)
    : httpBatchLink(linkConfig);

  return {
    links: [link],
  };
};

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;
