import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Option,
  Select,
} from '@mui/joy';
import { useEffect, useState } from 'react';

import { usePrevQuotesTableStore } from '../../../stores/prevQuotesTable.store';

export default function PrevQuotesPagination() {
  const [
    currentPage,
    setPage,
    pages,
    pageSize,
    setPageSize,
    goToPrevPage,
    goToNextPage,
  ] = usePrevQuotesTableStore((state) => [
    state.currentPage,
    state.setCurrentPage,
    state.pages,
    state.pageSize,
    state.setPageSize,
    state.goToPrevPage,
    state.goToNextPage,
  ]);

  const [pageArray, setPageArray] = useState<string[]>([]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        goToNextPage();
      } else if (event.key === 'ArrowLeft') {
        goToPrevPage();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [goToNextPage, goToPrevPage]);

  useEffect(
    () =>
      setPageArray(Array.from({ length: pages }, (_, i) => (i + 1).toString())),
    [pages]
  );

  const formatPageArray = () => {
    // If there are no pages or pages is less than 1, return just ['1']
    if (pages < 1) return ['1'];

    // If there are fewer than 8 pages, return the entire array of pages
    if (pages < 8) return pageArray;

    // If the current page is within the first 4 pages, show first 5 pages, followed by '...' and the last page
    if (currentPage < 5) {
      return [...pageArray.slice(0, 5), '...', pages.toString()];
    }

    // If the current page is within the last 4 pages, show the first page, '...', and the last 5 pages
    if (currentPage > pages - 4) {
      return ['1', '...', ...pageArray.slice(-5)];
    }

    // For any other case, show the first page, '...', the current page and its neighbors, followed by '...' and the last page
    return [
      '1',
      '...',
      `${currentPage - 1}`,
      `${currentPage}`,
      `${currentPage + 1}`,
      '...',
      `${pages}`,
    ];
  };
  const formatedPageArray = formatPageArray();

  return (
    <Box sx={{ display: 'flex', pt: 2, gap: 1, alignItems: 'center' }}>
      <Button
        variant="outlined"
        color="primary"
        disabled={currentPage === 1}
        onClick={goToPrevPage}
        startDecorator={<KeyboardArrowLeftIcon />}
      >
        Previous
      </Button>

      <Box sx={{ flex: 1 }} />

      {formatedPageArray.map((pageString, index) => (
        <IconButton
          key={
            pageString !== '...' ? `page-${pageString}` : `ellipsis-${index}`
          }
          variant="outlined"
          color={pageString === currentPage.toString() ? 'primary' : 'neutral'}
          sx={{
            borderRadius: '50%',
            ...(pageString === currentPage.toString() && {
              bgcolor: 'primary',
            }),
          }}
          onClick={() => setPage(Number(pageString))}
        >
          {pageString}
        </IconButton>
      ))}

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <FormControl orientation="horizontal">
          <FormLabel id="rows-select-label" htmlFor="rows-select-button">
            Rows per page
          </FormLabel>
          <Select
            value={pageSize}
            onChange={(_, size) => size && setPageSize(size)}
            slotProps={{
              button: {
                id: 'rows-select-button',
                'aria-labelledby': 'rows-select-label rows-select-button',
              },
            }}
          >
            {[5, 10, 15, 20, 30].map((size) => (
              <Option key={`size-${size}`} value={size}>
                {size}
              </Option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Button
        variant="outlined"
        color="primary"
        disabled={currentPage === pages}
        onClick={goToNextPage}
        endDecorator={<KeyboardArrowRightIcon />}
      >
        Next
      </Button>
    </Box>
  );
}
