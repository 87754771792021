import { Breadcrumbs, Typography, styled } from '@mui/joy';
import MuiLink from '@mui/joy/Link';
import { useEffect } from 'react';
import { Link, useMatches } from 'react-router-dom';

import { usePageStore } from '../../../stores/page.store';

import type { Breadcrumb } from '../../../util/router-utils';
import type { UIMatch } from 'react-router-dom';

interface Match extends UIMatch<unknown, unknown> {
  handle: {
    crumb: Breadcrumb;
  };
}

const StyledBreadcrumbs = styled(Breadcrumbs)({
  maxWidth: '100%',
  '.MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
  },
  '.MuiBreadcrumbs-separator': {
    flexShrink: 0,
  },
  '.MuiBreadcrumbs-li:not(:last-of-type)': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100px',
  },
});

export default function TopBarBreadcrumbs() {
  const matches = useMatches().filter((match): match is Match =>
    Boolean((match as Match).handle?.crumb)
  );

  const setPage = usePageStore((state) => state.setPage);

  useEffect(() => {
    setPage(matches[matches.length - 1]?.handle.crumb.pageName);
  });

  if (matches.length > 1) {
    return (
      <StyledBreadcrumbs data-testid="breadcrumbs">
        {matches.map((match, index) => {
          const crumb = match.handle.crumb;
          if (index === matches.length - 1) {
            return (
              <Typography key={match.pathname}>
                {crumb.crumbName ?? crumb.pageName}
              </Typography>
            );
          } else {
            return (
              <MuiLink
                component={Link}
                key={match.pathname}
                to={crumb.crumbPath}
                color="neutral"
              >
                {crumb.crumbName ?? crumb.pageName}
              </MuiLink>
            );
          }
        })}
      </StyledBreadcrumbs>
    );
  } else {
    return null;
  }
}
