import { Box, Button, Typography } from '@mui/joy';

import type * as React from 'react';

interface MakeEditsButtonProps {
  logo: React.ElementType;
  header: string;
  subtext: string;
  disabled?: boolean;
  bold?: boolean;
  onClick: () => void;
  loading?: boolean;
}

const QuoteActionButton: React.FC<MakeEditsButtonProps> = ({
  logo: Logo,
  header,
  subtext,
  disabled,
  bold,
  onClick,
  loading,
}) => {
  return (
    <Button
      data-testid="button"
      onClick={onClick}
      disabled={disabled}
      variant="outlined"
      sx={(theme) => ({
        border: '2px solid',
        margin: 2,
        paddingTop: 1,
        borderRadius: 15,
        '&:hover': {
          backgroundColor: 'neutral.200',
        },
        '&:active': {
          backgroundColor: 'neutral.300',
        },
        [theme.getColorSchemeSelector('dark')]: {
          '&:hover': {
            backgroundColor: 'neutral.700',
          },
          '&:active': {
            backgroundColor: 'neutral.600',
          },
        },
      })}
      loading={loading}
    >
      <Box
        width={100}
        height={110}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        marginLeft={-1}
        padding={0.5}
      >
        <Logo
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            backgroundColor: 'primary.500',
            color: 'white',
            mb: 0.5,
            padding: 0.75,
          }}
        />
        <Typography
          level="title-sm"
          sx={{ mb: 0.5, mt: 0.5, fontWeight: bold ? 'bold' : 'normal' }}
        >
          {header}
        </Typography>
        <Typography
          level="body-xs"
          sx={(theme) => ({
            textAlign: 'left',
            color: 'neutral.700',
            [theme.getColorSchemeSelector('dark')]: {
              color: 'neutral.300',
            },
          })}
        >
          {subtext}
        </Typography>
      </Box>
    </Button>
  );
};

export default QuoteActionButton;
