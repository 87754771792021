import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Input from '@mui/joy/Input';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Table from '@mui/joy/Table';
import { trpc } from '@shared';
import { keepPreviousData } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { usePrevQuotesTableStore } from '../../stores/prevQuotesTable.store';
import DeleteDialog from '../DeleteDialog/DeleteDialog';

import PrevQuotesHeader from './PrevQuotesHeader/PrevQuotesHeader';
import PrevQuotesPagination from './PrevQuotesPagination/PrevQuotesPagination';
import PrevQuotesRows from './PrevQuotesRows/PrevQuotesRows';

export const DEBOUNCE_TIME = 300;

export default function PrevQuotes() {
  const [
    openDeleteDialog,
    setOpenDeleteDialog,
    deleteRow,
    setPages,
    setQuotes,
    setError,
    sorting,
    currentPage,
    pageSize,
    searchTerm,
    setSearchTerm,
  ] = usePrevQuotesTableStore((state) => [
    state.openDeleteDialog,
    state.setOpenDeleteDialog,
    state.deleteRow,
    state.setPages,
    state.setQuotes,
    state.setError,
    state.sorting,
    state.currentPage,
    state.pageSize,
    state.searchTerm,
    state.setSearchTerm,
  ]);

  const filter = {
    sortBy: sorting.header,
    sortOrder: sorting.order,
    page: currentPage,
    pageSize,
    searchTerm,
  };

  const getQuoteDetails = trpc.quotes.getQuoteDetails.useQuery(filter, {
    placeholderData: keepPreviousData,
  });

  const utils = trpc.useUtils();

  const deleteQuoteDetail = trpc.quotes.deleteQuoteDetail.useMutation();

  const handleDelete = async () => {
    if (deleteRow == null) return;
    await deleteQuoteDetail.mutateAsync(deleteRow.id);
    void utils.quotes.getQuoteDetailsById.invalidate({ id: deleteRow.id });
    void utils.quotes.getQuoteDetails.invalidate();
  };

  // Debounces the search term to prevent unnecessary API calls
  const [inputSearchTerm, setInputSearchTerm] = useState('');
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(inputSearchTerm);
    }, DEBOUNCE_TIME);

    return () => {
      clearTimeout(handler);
    };
  }, [inputSearchTerm, setSearchTerm]);

  useEffect(() => {
    if (getQuoteDetails.data == null) return;

    const { pages, quotes } = getQuoteDetails.data;

    setPages(pages);
    setQuotes(quotes.slice(0, pageSize));
  }, [getQuoteDetails.data, setPages, setQuotes, pageSize]);

  useEffect(() => {
    setError(getQuoteDetails.error != null);
  }, [getQuoteDetails.error, setError]);

  return (
    <Stack spacing={2}>
      <Input
        id="search"
        placeholder="Search for quotes by name or description..."
        startDecorator={<SearchRoundedIcon />}
        onChange={(e) => setInputSearchTerm(e.target.value)}
        data-testid="prev-quotes-search-bar"
      />
      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 'sm',
          overflow: 'auto',
        }}
        data-testid="prev-quotes-table"
      >
        <Table
          stickyHeader
          hoverRow
          noWrap
          sx={{
            '--TableCell-headBackground':
              'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground':
              'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '20px',
          }}
        >
          <PrevQuotesHeader />
          <PrevQuotesRows />
        </Table>
      </Sheet>
      <PrevQuotesPagination />
      <DeleteDialog
        itemToBeDeleted="Quote"
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={handleDelete}
      />
    </Stack>
  );
}
