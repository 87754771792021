import { Box } from '@mui/joy';

import PrevQuotes from '../../../components/PrevQuotes/PrevQuotes';
import { usePrevQuotesTableStore } from '../../../stores/prevQuotesTable.store';
import { clientUtils } from '../../../util/trpc-client';

export async function loader() {
  const { sorting, currentPage, pageSize, searchTerm } =
    usePrevQuotesTableStore.getState();
  const quoteDetails = await clientUtils.quotes.getQuoteDetails.ensureData({
    sortBy: sorting.header,
    sortOrder: sorting.order,
    page: currentPage,
    pageSize,
    searchTerm,
  });
  return {
    quoteDetails,
  };
}

const MyQuotesPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1500,
        margin: '0 auto',
      }}
    >
      <PrevQuotes />
    </Box>
  );
};

export default MyQuotesPage;
