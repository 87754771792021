import { authStateSchema } from '@backend/types';
import { CircularProgress } from '@mui/joy';
import { getTenantDomain } from '@shared';
import {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';

import type { DeploymentStage } from '@shared';

const AuthContext = createContext({
  isLoading: true,
  isAuthenticated: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
  apiUrl: string;
}

const stage = import.meta.env.MODE as DeploymentStage;

const AuthProvider = ({ children, apiUrl }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = useCallback(() => {
    const query = new URLSearchParams({
      return_url: encodeURI(window.location.href),
      tenant_domain: getTenantDomain(window.location.hostname, stage),
    }).toString();
    window.location.href = `${apiUrl}/auth/login?${query}`;
  }, [apiUrl]);

  const logout = useCallback(() => {
    window.location.href = `${apiUrl}/auth/logout`;
  }, [apiUrl]);

  // Bootstrap the application with the authenticated user's session data.
  useEffect(() => {
    const fetchSession = async () => {
      try {
        const res = await fetch(`${apiUrl}/auth/auth-state`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          method: 'GET',
          credentials: 'include',
        });
        const parsed = authStateSchema.safeParse(await res.json());
        if (!parsed.success) {
          setIsAuthenticated(false);
          throw new Error('Failed to parse auth state');
        }
        const { isAuthenticated } = parsed.data;
        if (!isAuthenticated) {
          login();
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.log(error);
        logout();
      }
    };

    fetchSession();
  }, [apiUrl, login, logout]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isLoading: false, login, logout }}
    >
      {isAuthenticated ? (
        children
      ) : (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

/* WRISTBAND_TOUCHPOINT - AUTHENTICATION */
// React context responsbile for establishing that the user is authenticated and getting session data.
// "AuthProvider" should wrap your App component to enable access to the "useAuth" hook everywhere.
// That hook can then be used to protect App routes.
export { AuthProvider, useAuth };
