import { trpc } from '@shared';
import { useLoaderData } from 'react-router-dom';

import AccountInfo from '../../../components/Settings/Account/AccountInfo/AccountInfo';
import PersonalInfo from '../../../components/Settings/Account/PersonalInfo/PersonalInfo';
import { clientUtils } from '../../../util/trpc-client';

export async function loader() {
  const userInfoData = await clientUtils.session.userInfo.ensureData();
  return {
    userInfoData,
  };
}

const AccountSettings = () => {
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const { data } = trpc.session.userInfo.useQuery(undefined, {
    initialData: loaderData.userInfoData,
  });

  const { email, emailVerified, identityProviderType } = data;

  const managed = identityProviderType !== 'WRISTBAND';

  return (
    <>
      <PersonalInfo userInfo={data} managed={managed} />
      <AccountInfo
        email={email}
        emailVerified={emailVerified}
        managed={managed}
      />
    </>
  );
};

export default AccountSettings;
