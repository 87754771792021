import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import { trpc } from '@shared';
import { useLoaderData } from 'react-router-dom';

import QuoteDetailsStep from '../../../components/Quote/DetailsStep/QuoteDetailsStep';
import NewQuoteStepper from '../../../components/Quote/Stepper/NewQuoteStepper';
import { clientUtils } from '../../../util/trpc-client';

import type { LoaderFunctionArgs } from 'react-router-dom';

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { quoteId } = params;

  const quoteDetail = await clientUtils.quotes.getQuoteDetailsById.ensureData({
    id: quoteId!,
  });
  return {
    quoteDetail,
  };
};

const QuoteDetails = () => {
  const { quoteDetail } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const quoteDetailData = trpc.quotes.getQuoteDetailsById.useQuery(
    {
      id: quoteDetail.id,
    },
    { initialData: quoteDetail }
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        width: '100%',
        maxWidth: 1500,
        margin: '0 auto',
      }}
    >
      <NewQuoteStepper finalized={quoteDetail.status === 'completed'} />
      <Card sx={{ width: '100%' }}>
        <QuoteDetailsStep detail={quoteDetailData.data} />
      </Card>
    </Box>
  );
};

export default QuoteDetails;
