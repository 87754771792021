/* eslint-disable jsx-a11y/anchor-is-valid */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Checkbox,
  Table,
  Input,
  FormLabel,
  FormControl,
  Dropdown,
  MenuItem,
  Link,
  Menu,
  MenuButton,
} from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import { trpc } from '@shared';
import { useState } from 'react';

import { useQuoteStore } from '../../../../stores/quote.store';

import type { QuoteItem } from '@backend/types';
import type * as React from 'react';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface QuoteTableProps {
  items: QuoteItem[];
}

export default function QuoteTable({ items }: QuoteTableProps) {
  const [selected, setSelected, setSuccess, setError, setItemDialogState] =
    useQuoteStore((state) => [
      state.selected,
      state.setSelected,
      state.setSuccess,
      state.setError,
      state.setItemDialogState,
    ]);

  const [order, setOrder] = useState<Order>('desc');
  const [search, setSearch] = useState('');
  const [quantity, setQuantity] = useState(1);
  const utils = trpc.useUtils();

  const deleted_item = trpc.quotes.deleteItem.useMutation({
    onMutate: () => {
      setError(null);
    },
    onSuccess: async () => {
      setSuccess('Item successfully deleted');
      setError(null);
      void utils.quotes.getItems.invalidate();
    },
    onError: (err) => {
      setSuccess('');
      setError(err.message);
      void utils.quotes.getItems.invalidate();
    },
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (!isNaN(value)) {
      setQuantity(value);
    }
  };

  const searchFilters = (items: QuoteItem[]) => {
    const filtered_items: QuoteItem[] = [];
    items.forEach((i) => {
      if (
        (i.category.toLowerCase().includes(search.toLowerCase()) ||
          i.manufacturer.toLowerCase().includes(search.toLowerCase()) ||
          i.model.toLowerCase().includes(search.toLowerCase())) &&
        i.quantity >= quantity
      ) {
        filtered_items.push(i);
      }
    });
    return filtered_items;
  };

  return (
    <Box data-testid="table" sx={{ mr: 2, overflowX: 'auto' }}>
      <Box
        className="SearchAndFilters-tabletUp"
        data-testid="searchbar"
        sx={{
          py: 2,
          display: { xs: 'flex', sm: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }}>
          <FormLabel>Search for Item</FormLabel>
          <Input
            placeholder="Product name, manufacturer, model..."
            startDecorator={<SearchIcon />}
            onChange={handleInputChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Minimum Quantity</FormLabel>
          <Input placeholder="1" onChange={handleQuantityChange}></Input>
        </FormControl>
      </Box>

      <Table
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
          tableLayout: 'auto',
        }}
      >
        <thead>
          <tr>
            <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
              <Checkbox
                indeterminate={
                  selected.length > 0 &&
                  selected.length !== items.length &&
                  items.length > 0
                }
                checked={selected.length === items.length && items.length > 0}
                onChange={(event) => {
                  setSelected(
                    event.target.checked
                      ? items.map((item: { id: string }) => item.id)
                      : []
                  );
                }}
                color={
                  selected &&
                  items &&
                  (selected.length > 0 || selected.length === items.length)
                    ? 'primary'
                    : undefined
                }
                sx={{ verticalAlign: 'text-bottom' }}
                data-testid="select-all"
              />
            </th>
            <th style={{ width: 120, padding: '12px 6px' }}>
              <Link
                underline="none"
                bgcolor="primary"
                component="button"
                onClick={() => setOrder(order === 'asc' ? 'desc' : 'asc')}
                endDecorator={<ArrowDropDownIcon />}
                sx={{
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                  },
                }}
              >
                Product Category
              </Link>
            </th>
            <th style={{ width: 120, padding: '12px 6px' }}>Quantity</th>
            <th style={{ width: 120, padding: '12px 6px' }}>Manufacturer</th>
            <th style={{ width: 120, padding: '12px 6px' }}>Model</th>
            <th style={{ width: 120, padding: '12px 6px' }}>Addl. ID</th>
            <th style={{ width: 120, padding: '12px 6px' }} />
          </tr>
        </thead>
        <tbody>
          {stableSort(
            searchFilters(items),
            getComparator(order, 'category')
          ).map((item) => (
            <tr key={item.id}>
              <td style={{ textAlign: 'center', width: 120 }}>
                <Checkbox
                  checked={selected && selected.includes(item.id)}
                  color={
                    selected && selected.includes(item.id)
                      ? 'primary'
                      : undefined
                  }
                  onChange={(event) => {
                    const newIds = event.target.checked
                      ? [...selected, item.id]
                      : selected.filter((itemId) => itemId !== item.id);

                    setSelected(newIds);
                  }}
                  slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                  sx={{ verticalAlign: 'text-bottom' }}
                />
              </td>
              <td>{item.category}</td>
              <td>{item.quantity}</td>
              <td>{item.manufacturer}</td>
              <td>{item.model}</td>
              <td>{item.identifier}</td>
              <td>
                <Dropdown>
                  <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{
                      root: {
                        variant: 'plain',
                        color: 'neutral',
                      },
                    }}
                    data-testid="menu-button"
                  >
                    <MoreHorizRoundedIcon />
                  </MenuButton>
                  <Menu sx={{ minWidth: 140 }}>
                    <MenuItem
                      onClick={() => {
                        setItemDialogState(true, 'update', item);
                      }}
                      data-testid="menu-button-edit"
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setItemDialogState(true, 'create', item);
                      }}
                      data-testid="menu-button-copy"
                    >
                      Copy to New
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        deleted_item.mutate({ id: item.id });
                      }}
                      color="danger"
                      data-testid="menu-button-delete"
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  );
}
