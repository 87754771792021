import { emailChangeSchema } from '@backend/types';
import { zodResolver } from '@hookform/resolvers/zod';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/joy/Alert';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import { trpc } from '@shared';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import FormInput from '../../../../FormInput/FormInput';

import type { EmailChange } from '@backend/types';
import type { SubmitHandler } from 'react-hook-form';

interface EmailChangeProps {
  currentEmail: string;
  isVerified: boolean;
}

const EmailChange = ({ currentEmail, isVerified }: EmailChangeProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailChange>({
    resolver: zodResolver(emailChangeSchema),
    defaultValues: {
      email: currentEmail,
    },
  });

  const changeEmail = trpc.session.changeEmail.useMutation();
  const verifyEmail = trpc.session.verifyEmail.useMutation();

  const onSubmit: SubmitHandler<EmailChange> = async (data) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await changeEmail.mutateAsync(data);
      setSuccess('Email changed! Check your email for a verification link');
    } catch (err) {
      setError((err as any).message);
    }

    setLoading(false);
  };

  const handleVerify = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await verifyEmail.mutateAsync();
      setSuccess('Check your email for a verification link');
    } catch (err) {
      setError((err as any).message);
    }

    setLoading(false);
  };

  const formEmail = useWatch({ control, name: 'email' });

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          field="email"
          fieldLabel="Email"
          inputProps={{
            endDecorator:
              currentEmail === formEmail ? (
                isVerified ? (
                  <CheckCircleOutline color="success" />
                ) : (
                  <WarningIcon color="warning" />
                )
              ) : undefined,
            color:
              currentEmail === formEmail
                ? isVerified
                  ? 'success'
                  : 'warning'
                : undefined,
          }}
          fieldHelperText={
            currentEmail === formEmail ? (
              isVerified ? (
                'Your email is verified'
              ) : (
                <>
                  Please verify your email.{' '}
                  <Link component="button" type="button" onClick={handleVerify}>
                    Verify now
                  </Link>
                </>
              )
            ) : undefined
          }
          errors={errors}
          register={register}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Stack direction="row" spacing={2}>
            {error != null && (
              <Alert size="sm" color="danger">
                {error}
              </Alert>
            )}
            {success && (
              <Alert size="sm" color="success">
                {success}
              </Alert>
            )}
            <Button variant="solid" type="submit" loading={loading}>
              Change Email
            </Button>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export default EmailChange;
