import { requiredNumberSchema } from '@backend/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Box, Button } from '@mui/joy';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import FormInput from '../../FormInput/FormInput';

import type { QuoteItem } from '@backend/types';
import type { SubmitHandler } from 'react-hook-form';

const priceSchema = z
  .object({
    minUnitPrice: requiredNumberSchema.positive(),
    maxUnitPrice: requiredNumberSchema.positive(),
  })
  .refine((data) => data.minUnitPrice <= data.maxUnitPrice, {
    message: 'Min price must be less than or equal to max price',
    path: ['minUnitPrice'],
  });

interface RangedPriceFormProps {
  initialMin: number | null;
  initialMax: number | null;
  mutate: (data: Partial<QuoteItem>) => Promise<void>;
}

const RangedPriceForm = ({
  mutate,
  initialMin,
  initialMax,
}: RangedPriceFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<QuoteItem>>({
    resolver: zodResolver(priceSchema),
    defaultValues: {
      minUnitPrice: initialMin,
      maxUnitPrice: initialMax,
    },
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const onSubmit: SubmitHandler<Partial<QuoteItem>> = async (data) => {
    setSubmitLoading(true);
    setSubmitError(null);
    try {
      await mutate(data);
    } catch (err) {
      setSubmitError((err as any).message);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <FormInput
          field="minUnitPrice"
          errors={errors}
          fieldLabel="Min Unit Price"
          register={register}
          inputProps={{
            type: 'number',
          }}
          required
        />{' '}
        &mdash;{' '}
        <FormInput
          field="maxUnitPrice"
          errors={errors}
          fieldLabel="Max Unit Price"
          register={register}
          inputProps={{
            type: 'number',
          }}
          required
        />
        <Button type="submit" loading={submitLoading}>
          Save
        </Button>
      </Box>
      {submitError && <Alert size="sm">{submitError}</Alert>}
    </form>
  );
};

export default RangedPriceForm;
