import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import { z } from 'zod';

import { devices, quoteDetails, quoteItem } from '../db/schema';

import type { ZodObject } from 'zod';

// Common
export const idSchema = z.string().cuid().or(z.string().cuid2());
export const idInputSchema = z.object({ id: idSchema });

export const trimmedStringSchema = z.string().trim();
export const requiredStringSchema = trimmedStringSchema.min(
  1,
  'This field is required'
);
export const optionalStringSchema = trimmedStringSchema
  .transform((val) => (val.length == 0 ? undefined : val))
  .nullish();

export const requiredNumberSchema = z.number({
  message: 'This field is required',
});
export const optionalNumberSchema = requiredNumberSchema.optional();

export const getUpdateEndpointSchema = <T extends ZodObject<any>>(
  updateSchema: T
) => idInputSchema.extend({ data: updateSchema });

// Ebay API

export const getPricesSchema = z
  .object({
    searchTerm: z.string(),
    actuallySold: z.boolean(),
    sandbox: z.boolean().optional(),
    condition: z.number().optional(),
    category: z.number().optional(),
  })
  .readonly();

export type GetPrices = z.infer<typeof getPricesSchema>;

// Gemini API

export const putImageRequestSchema = z
  .object({
    data: z.string().base64(),
    mimeType: z.string(),
    sandbox: z.boolean().optional(),
  })
  .readonly();

export type PutImageRequest = z.infer<typeof putImageRequestSchema>;

// Devices API

export const statusSchema = z.enum(['complete', 'incomplete']);

export const createDeviceSchema = createInsertSchema(devices, {
  assetTag: optionalStringSchema,
  manufacturer: requiredStringSchema,
  model: optionalStringSchema,
  serialNumber: optionalStringSchema,
  releaseYear: optionalNumberSchema,
}).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  tenantId: true,
});

export type CreateDevice = z.infer<typeof createDeviceSchema>;

export const updateDeviceSchema = createDeviceSchema.partial();

export type UpdateDevice = z.infer<typeof updateDeviceSchema>;

export const selectDeviceSchema = createSelectSchema(devices).omit({
  tenantId: true,
});

export type Device = z.infer<typeof selectDeviceSchema>;

// Quote Detail API

export const createQuoteDetailSchema = createInsertSchema(quoteDetails, {
  quoteName: requiredStringSchema,
  quoteDescription: optionalStringSchema,
}).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  tenantId: true,
});

export type CreateQuoteDetail = z.infer<typeof createQuoteDetailSchema>;

export const updateQuoteDetailSchema = createQuoteDetailSchema.partial();

export type UpdateQuoteDetail = z.infer<typeof updateQuoteDetailSchema>;

export const quoteDetailSchema = createSelectSchema(quoteDetails);

export type QuoteDetail = z.infer<typeof quoteDetailSchema>;

export const getQuoteDetailByTenantSchema = z.object({
  sortBy: z
    .enum([
      'createdAt',
      'updatedAt',
      'quoteName',
      'quoteDescription',
      'status',
      'estValue',
    ])
    .optional()
    .default('createdAt'),
  sortOrder: z.enum(['asc', 'desc']).optional().default('asc'),
  page: z.number().optional().default(1),
  pageSize: z.number().optional().default(10),
  pageCount: z.number().optional().default(1),
  searchTerm: z.string().optional().default(''),
  lastAccessTime: z.number().optional().default(0),
});

export type GetQuoteDetailsByTenant = z.infer<
  typeof getQuoteDetailByTenantSchema
>;

// Quote Item API

export const createQuoteItemSchema = createInsertSchema(quoteItem, {
  quoteId: idSchema,
  category: requiredStringSchema,
  manufacturer: requiredStringSchema,
  model: requiredStringSchema,
  identifier: optionalStringSchema,
  quantity: requiredNumberSchema,
  minUnitPrice: z.number().positive().optional(),
  maxUnitPrice: z.number().positive().optional(),
  fixedUnitPrice: z.number().positive().optional(),
}).omit({
  id: true,
});

export type CreateQuoteItem = z.infer<typeof createQuoteItemSchema>;

export const updateQuoteItemSchema = createQuoteItemSchema.partial();

export type UpdateQuoteItem = z.infer<typeof updateQuoteItemSchema>;

export const quoteItemSchema = createSelectSchema(quoteItem);

export type QuoteItem = z.infer<typeof quoteItemSchema>;

// Profile API

export const authStateSchema = z.object({
  isAuthenticated: z.boolean(),
});

export type AuthState = z.infer<typeof authStateSchema>;

export const personalInfoSchema = z.object({
  givenName: requiredStringSchema,
  familyName: requiredStringSchema,
  displayName: optionalStringSchema.transform((val) => val ?? null),
  pictureUrl: z.string().url(),
});

export type PersonalInfo = z.infer<typeof personalInfoSchema>;

export const emailChangeSchema = z.object({
  email: z.string().email(),
});

export type EmailChange = z.infer<typeof emailChangeSchema>;

export const passwordChangeSchema = z.object({
  currentPassword: requiredStringSchema,
  newPassword: requiredStringSchema,
});

export type PasswordChange = z.infer<typeof passwordChangeSchema>;

//global feature map & tenant override schemas

//creating the zod enum schema, right now only QUOTE is a valid value
export const featureEnum = z.enum(['QUOTE']);

//a Feature should be within the featureEnum, so this validates the Feature type
export type Feature = z.infer<typeof featureEnum>;

//map for global features, maps the feature to a boolean
export const featureMapSchema = z.record(featureEnum, z.boolean());

//GlobalFeatureMap type is validated as a globalFeatureMapSchema
export type FeatureMap = z.infer<typeof featureMapSchema>;

//maps a tenant id to their global feature map
export const tenantOverrideSchema = z.record(z.string(), featureMapSchema);

//validating the type here
export type TenantOverrideMap = z.infer<typeof tenantOverrideSchema>;
