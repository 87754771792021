import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

export default function NotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        mb: 1,
        gap: 1,
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'start', sm: 'center' },
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Typography level="h2" component="h1">
        404 Not Found
      </Typography>
    </Box>
  );
}
