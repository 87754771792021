import { z } from 'zod';

export const dataTypeSchema = z.enum([
  'STRING',
  'NUMBER',
  'INTEGER',
  'BOOLEAN',
  'OBJECT',
  'ARRAY',
]);

export const stringDataTypeSchema = z.object({
  type: dataTypeSchema,
});

export const paginationBaseSchema = z.object({
  totalResults: z.number().int().nonnegative(),
  start_index: z.number().int().positive(),
  itemsPerPage: z.number().int().nonnegative(),
});

export const versionMetadataSchema = z.object({
  version: z.string(),
  lastModifiedTime: z.string().datetime(),
  creationTime: z.string().datetime(),
});

export const activationMetadataSchema = versionMetadataSchema.extend({
  activationTime: z.string().datetime().nullable(),
  deactivationTime: z.string().datetime().nullable(),
});

export const tenantDataSchema = z.object({
  domainName: z.string(),
  displayName: z.string(),
  description: z.string(),
  signupEnabled: z.boolean(),
  allowedSignupEmailDomains: z.array(z.string()),
  restrictedMetadata: z.record(z.unknown()),
  publicMetadata: z.record(z.unknown()),
  status: z.string(),
  metadata: activationMetadataSchema,
});

export type TenantData = z.infer<typeof tenantDataSchema>;

export const identityProviderTypeSchema = z.enum([
  'WRISTBAND',
  'APPLE',
  'BITBUCKET',
  'GITHUB',
  'GITLAB',
  'GOOGLE',
  'OKTA',
  'GOOGLE_WORKSPACE',
]);

export const userDataSchema = z.object({
  tenantId: z.string(),
  identityProviderName: z.string(),
  identityProviderType: identityProviderTypeSchema,
  username: z.string().nullable(),
  email: z.string().email(),
  emailVerified: z.boolean(),
  externalId: z.string().nullable(),
  fullName: z.string().nullable(),
  givenName: z.string().nullable(),
  familyName: z.string().nullable(),
  middleName: z.string().nullable(),
  honorificPrefix: z.string().nullable(),
  honorificSuffix: z.string().nullable(),
  nickname: z.string().nullable(),
  displayName: z.string().nullable(),
  pictureUrl: z.string().url().nullable(),
  gender: z.string().nullable(),
  birthdate: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  preferredLanguage: z.string().nullable(),
  locale: z.string().nullable(),
  timeZone: z.string().nullable(),
  restrictedMetadata: z.record(z.unknown()),
  publicMetadata: z.record(z.unknown()),
  status: z.enum([
    'PROVISIONED',
    'PENDING_INVITE_ACTIVATION',
    'PENDING_SIGNUP_ACTIVATION',
    'ACTIVE',
    'INACTIVE',
  ]),
  metadata: activationMetadataSchema,
});

export type UserData = z.infer<typeof userDataSchema>;

export const paginatedUserDataSchema = paginationBaseSchema.extend({
  items: z.array(userDataSchema),
});

export const ownerTypeSchema = z.enum(['APPLICATION', 'TENANT']);

export const roleSchema = z.object({
  id: z.string(),
  ownerType: ownerTypeSchema,
  ownerId: z.string(),
  type: z.enum(['PREDEFINED', 'CUSTOM']),
  name: z.string(),
  displayName: z.string(),
  description: z.string(),
  permissionBoundaryId: z.string(),
  tenantVisibility: z.enum(['ALL', 'TENANT_INCLUSION_LIST', 'NONE', 'OWNER']),
  tenantVisibilityInclusionList: z.array(z.string()),
  metadata: versionMetadataSchema,
});

export const queryRolesSchema = paginationBaseSchema.extend({
  items: z.array(roleSchema),
});

export const passwordPolicySchema = z.object({
  id: z.string(),
  ownerType: ownerTypeSchema,
  ownerId: z.string(),
  minimumLength: z.number(),
  lowercaseCharRequired: z.boolean(),
  uppercaseCharRequired: z.boolean(),
  digitRequired: z.boolean(),
  specialCharRequired: z.boolean(),
  breachedPwdDetectionEnabled: z.boolean(),
  breachedPwdLoginRemediationStrategy: z.enum([
    'NONE',
    'REQUIRE_PASSWORD_RESET',
  ]),
  metadata: versionMetadataSchema,
});

export const resolveTenantPasswordPolicyOverridesSchema = z.object({
  items: z.array(
    z.object({
      isDefault: z.boolean(),
      item: passwordPolicySchema,
    })
  ),
});

export const resolveTenantIdentityProviderOverridesSchema = z.object({
  items: z.array(
    z.object({
      isDefault: z.boolean(),
      item: z.object({
        id: z.string(),
        ownerType: ownerTypeSchema,
        ownerId: z.string(),
        type: identityProviderTypeSchema,
        name: z.string(),
        displayName: z.string(),
        isExternal: z.boolean(),
        status: z.enum(['ENABLED', 'DISABLED', 'DELETE_IN_PROGRESS']),
        metadata: versionMetadataSchema,
        loginIdentifiers: z.array(z.enum(['EMAIL', 'USERNAME'])),
        loginFactors: z.array(z.enum(['PASSWORD', 'MAGIC_LINK'])),
      }),
    })
  ),
});

export const baseProfileSchema = z.object({
  properties: z.object({
    username: stringDataTypeSchema,
    password: stringDataTypeSchema,
    email: stringDataTypeSchema,
    externalId: stringDataTypeSchema,
    fullName: stringDataTypeSchema,
    givenName: stringDataTypeSchema,
    familyName: stringDataTypeSchema,
    middleName: stringDataTypeSchema,
    honorificPrefix: stringDataTypeSchema,
    honorificSuffix: stringDataTypeSchema,
    nickname: stringDataTypeSchema,
    displayName: stringDataTypeSchema,
    pictureUrl: stringDataTypeSchema,
    profileUrl: stringDataTypeSchema,
    websiteUrl: stringDataTypeSchema,
    gender: stringDataTypeSchema,
    birthdate: stringDataTypeSchema,
    phoneNumber: stringDataTypeSchema,
    preferredLanguage: stringDataTypeSchema,
    locale: stringDataTypeSchema,
    timeZone: stringDataTypeSchema,
  }),
  required: z.array(
    z.enum([
      'email',
      'fullName',
      'givenName',
      'familyName',
      'birthdate',
      'phoneNumber',
    ])
  ),
  type: dataTypeSchema,
});

export const resolveTenantUserSchemaOverridesSchema = z.object({
  items: z.array(
    z.object({
      isDefault: z.boolean(),
      item: z.object({
        id: z.string(),
        ownerType: ownerTypeSchema,
        ownerId: z.string(),
        baseProfileVersion: z.number(),
        baseProfile: baseProfileSchema,
        metadata: versionMetadataSchema,
      }),
    })
  ),
});

export const permissionInfoSchema = z.object({
  id: z.string(),
  type: z.enum(['PREDEFINED', 'CUSTOM']),
  value: z.string(),
  boundaryType: z.enum([
    'PREDEFINED',
    'CUSTOM',
    'TENANT_INCLUSION_LIST',
    'TENANT_EXCLUSION_LIST',
  ]),
  boundaryValue: z.string(),
  boundaryResourceList: z.array(z.string()),
  metadata: versionMetadataSchema,
});

export const paginatedPermissionInfoSchema = paginationBaseSchema.extend({
  items: z.array(permissionInfoSchema),
});
