import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  CircularProgress,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
  Select,
  Option,
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
  IconButton,
  Alert,
} from '@mui/joy';
import { trpc } from '@shared';
import { useRef, useState } from 'react';

import { ebayConditions } from '../../../../../backend/src/types/ebay-items-dictionary';
import Histogram from '../Histogram/Histogram';

import FixedPriceForm from './FixedPriceForm/FixedPriceForm';
import RangedPriceForm from './RangedPriceForm/RangedPriceForm';

import type { GetPrices, QuoteItem } from '@backend/types';
import type { SelectStaticProps } from '@mui/joy';

interface PriceDialogProps {
  item: QuoteItem;
  searchParams: GetPrices;
  open: boolean;
  onClose: () => void;
}

const PriceDialog = ({
  item,
  searchParams,
  open,
  onClose,
}: PriceDialogProps) => {
  const [filterCondition, setFilterCondition] = useState<number | null>(null);

  const {
    data: priceData,
    isLoading,
    isError,
    error,
  } = trpc.ebay.getPrices.useQuery(
    { condition: filterCondition ?? undefined, ...searchParams },
    {
      enabled: open,
    }
  );

  const [fixedMode, setFixedMode] = useState(false);
  const action: SelectStaticProps['action'] = useRef(null);

  const updateItem = trpc.quotes.updateItem.useMutation();
  const utils = trpc.useUtils();

  const mutate = async (data: Partial<QuoteItem>) => {
    await updateItem.mutateAsync({
      id: item.id,
      data: {
        ...data,
        status: 'estimated',
      },
    });
    void utils.quotes.getItems.invalidate({ id: item.quoteId });
    void utils.quotes.getAllItemsInFinalQuote.invalidate({
      id: item.quoteId,
    });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setFilterCondition(null);
      }}
    >
      <ModalDialog
        sx={{
          width: '40vw',
        }}
      >
        <ModalClose />
        <Typography level="h3">Price Distribution</Typography>
        <Typography level="h4">
          {item.manufacturer} {item.model} {item.identifier}
        </Typography>
        {isLoading && (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
            <Typography>Loading...</Typography>
          </Stack>
        )}
        {isError && <Alert color="danger">{error.message}</Alert>}
        {priceData != null && priceData.length > 0 && (
          <>
            <Histogram data={priceData} />
            <Divider />
            {/* median and average */}
            <Stack direction="row" spacing={2}>
              <Typography>
                Median: $
                {priceData.sort()[Math.floor(priceData.length / 2)]?.toFixed(2)}
              </Typography>
              <Typography>
                Average: $
                {(
                  priceData.reduce((acc, curr) => acc + curr, 0) /
                  priceData.length
                )?.toFixed(2)}
              </Typography>
            </Stack>
          </>
        )}
        <Divider />
        <FormControl>
          <FormLabel>Condition</FormLabel>
          <Select
            action={action}
            placeholder="All Conditions"
            value={filterCondition}
            onChange={(_, newValue) => setFilterCondition(newValue)}
            {...(filterCondition && {
              // display the button and remove select indicator
              // when user has selected a value
              endDecorator: (
                <IconButton
                  size="sm"
                  variant="plain"
                  color="neutral"
                  onMouseDown={(event) => {
                    // don't open the popup when clicking on this button
                    event.stopPropagation();
                  }}
                  onClick={() => {
                    setFilterCondition(null);
                    action.current?.focusVisible();
                  }}
                >
                  <CloseRoundedIcon />
                </IconButton>
              ),
              indicator: null,
            })}
          >
            {Object.entries(ebayConditions).map(([condition, value]) => (
              <Option key={value} value={value}>
                {condition}
              </Option>
            ))}
          </Select>
          <FormHelperText>Filter by condition</FormHelperText>
        </FormControl>
        <Divider />
        <Stack direction="row" spacing={2}>
          <FormControl>
            <FormLabel>{fixedMode ? 'Fixed' : 'Range'}</FormLabel>
            <Switch
              checked={fixedMode}
              onChange={(event) => setFixedMode(event.target.checked)}
            />
          </FormControl>
          {fixedMode ? (
            <FixedPriceForm
              mutate={mutate}
              initialPrice={item.fixedUnitPrice}
            />
          ) : (
            <RangedPriceForm
              mutate={mutate}
              initialMin={item.minUnitPrice}
              initialMax={item.maxUnitPrice}
            />
          )}
        </Stack>
      </ModalDialog>
    </Modal>
  );
};

export default PriceDialog;
