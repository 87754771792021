import { z } from 'zod';

import type { EbayCategory } from './ebay-items-dictionary';

/**
 * The category of the electronic item
 */
export const itemCategories = [
  'Laptop',
  'Desktop',
  'Tablet',
  'Phone',
  'Audio',
  'Monitor',
  'TV',
  'GamingConsole',
  'Peripheral',
  'Refrigerator',
  'WashingMachine',
  'Dryer',
  'Watch',
  'Vehicle',
  'Other',
] as const;

/**
 * The category of the electronic item
 */
export const itemCategorySchema = z
  .enum(itemCategories)
  .describe('The category of the item');

/*
List of item manufacturer options
*/

export const itemManufacturers: Record<string, EbayCategory> = {
  Acer: { id: 1 },
  Amazon: { id: 2 },
  Apple: { id: 3 },
  ASUS: { id: 4 },
  'Bang & Olufsen': { id: 5 },
  Beats: { id: 6 },
  BenQ: { id: 7 },
  BMW: { id: 8 },
  Bose: { id: 9 },
  Bosch: { id: 10 },
  Chevrolet: { id: 11 },
  Corsair: { id: 12 },
  Dell: { id: 13 },
  Dyson: { id: 14 },
  Fitbit: { id: 15 },
  Ford: { id: 16 },
  Fossil: { id: 17 },
  Frigidaire: { id: 18 },
  Garmin: { id: 19 },
  'GE Appliances': { id: 20 },
  Google: { id: 21 },
  Hisense: { id: 22 },
  HP: { id: 23 },
  Huawei: { id: 24 },
  JBL: { id: 25 },
  Lenovo: { id: 26 },
  LG: { id: 27 },
  Logitech: { id: 28 },
  Maytag: { id: 29 },
  Microsoft: { id: 30 },
  Nintendo: { id: 31 },
  OnePlus: { id: 32 },
  Panasonic: { id: 33 },
  Philips: { id: 34 },
  Razer: { id: 35 },
  Samsung: { id: 36 },
  Sennheiser: { id: 37 },
  Sony: { id: 38 },
  TCL: { id: 39 },
  Tesla: { id: 40 },
  Toyota: { id: 41 },
  Vizio: { id: 42 },
  Volkswagen: { id: 43 },
  Whirlpool: { id: 44 },
  Other: { id: 45 },
};

const manufacturerKeys = Object.keys(itemManufacturers) as [
  string,
  ...string[],
];

// Define the schema
export const itemManufacturerSchema = z
  .enum(manufacturerKeys)
  .describe('The manufacturer of the item');

/**
 * The schema for identifying an electronic item
 */
export const itemIdentifierSchema = z.object({
  manufacturer: itemManufacturerSchema.describe('The manufacturer of the item'),
  model: z
    .string()
    .min(1, { message: 'Model is required' })
    .describe('The model of the electronic item'),
  serialNumber: z
    .string()
    .optional()
    .describe('The serial number of the electronic item'),
  releaseYear: z
    .number({ coerce: true })
    .optional()
    .describe('The release year of the electronic item'),
  category: itemCategorySchema,
});

export type ItemIdentifier = z.infer<typeof itemIdentifierSchema>;

/**
 * The schema for identifying an electronic item with optional fields for AI
 */
export const aiItemIdentifierSchema = itemIdentifierSchema
  .partial()
  .describe('The schema for identifying an electronic item');

export type AIItemIdentifier = z.infer<typeof aiItemIdentifierSchema>;
