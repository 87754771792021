import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';

import EmailChange from './EmailChange/EmailChange';
import PasswordChange from './PasswordChange/PasswordChange';

interface AccountInfoProps {
  email: string;
  emailVerified: boolean;
  managed?: boolean;
}

const AccountInfo = ({ email, emailVerified, managed }: AccountInfoProps) => {
  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: 1000,
        margin: '0 auto',
      }}
    >
      <Box sx={{ mb: 1 }}>
        <Typography level="title-md">Account info</Typography>
        <Typography level="body-sm">Change your account information</Typography>
      </Box>
      <Divider />
      {managed &&
        'Your account information is managed by your company. Please contact your company administrator for any changes.'}
      {!managed && (
        <Stack direction="row" spacing={4}>
          <EmailChange currentEmail={email} isVerified={emailVerified} />
          <PasswordChange />
        </Stack>
      )}
    </Card>
  );
};

export default AccountInfo;
