import { trpc, getTrpcClientOptions, getConfig } from '@shared';
import { QueryClient } from '@tanstack/query-core';
import { createTRPCQueryUtils } from '@trpc/react-query';

import type { DeploymentStage } from '@shared';

const stage = import.meta.env.MODE as DeploymentStage;
const config = getConfig(stage);

export const trpcClient = trpc.createClient(getTrpcClientOptions(config));

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Infinity, gcTime: 600000, retry: false },
  },
});

export const clientUtils = createTRPCQueryUtils({
  queryClient,
  client: trpcClient,
});
