import PrevQuotesHeaderCell from '../PrevQuotesHeaderCell/PrevQuotesHeaderCell';

export default function PrevQuotesHeader() {
  const columns = [
    { label: 'Status', width: '135px' },
    { label: 'Name', width: '30%' },
    { label: 'Description', width: '80%' },
    { label: 'Last Updated', width: '170px' },
  ];

  return (
    <thead>
      <tr>
        {columns.map((column, index) => (
          <PrevQuotesHeaderCell
            key={index}
            thProps={{ style: { width: column.width } }}
          >
            {column.label}
          </PrevQuotesHeaderCell>
        ))}
        <th style={{ width: '110px' }}>Est. Value</th>
        <th style={{ width: '100px' }}>Actions</th>
      </tr>
    </thead>
  );
}
