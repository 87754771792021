import CheckIcon from '@mui/icons-material/Check';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { Box, Button, Typography } from '@mui/joy';
import { trpc } from '@shared';
import { useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import PricingStepTable from './PricingStepTable';

import type { loader } from '../../../pages/quote/pricing';
import type { QuoteItem } from '@backend/types';

const calculatePriceRange = (
  ranges: QuoteItem[]
): { lowerRange: number; upperRange: number } => {
  let lowerRange = 0;
  let upperRange = 0;

  ranges.forEach(({ quantity, minUnitPrice, maxUnitPrice, fixedUnitPrice }) => {
    if (fixedUnitPrice != null) {
      lowerRange += fixedUnitPrice * quantity;
      upperRange += fixedUnitPrice * quantity;
    } else if (minUnitPrice != null && maxUnitPrice != null) {
      lowerRange += minUnitPrice * quantity;
      upperRange += maxUnitPrice * quantity;
    }
  });

  return { lowerRange, upperRange };
};

export default function PricingStep() {
  const { quoteDetail, finalQuoteItems } = useLoaderData() as Awaited<
    ReturnType<typeof loader>
  >;
  const getDetail = trpc.quotes.getQuoteDetailsById.useQuery(
    {
      id: quoteDetail.id,
    },
    { initialData: quoteDetail }
  );
  const detail = getDetail.data;

  const getFinalItems = trpc.quotes.getAllItemsInFinalQuote.useQuery(
    {
      id: quoteDetail.id,
    },
    { initialData: finalQuoteItems }
  );
  const items = getFinalItems.data;

  const finalize = trpc.quotes.finalizeQuote.useMutation();
  const utils = trpc.useUtils();

  const itemsPending = items?.filter((item) => item.status === 'pending');
  const isItemsPending = itemsPending.length > 0;

  const priceRange = useMemo(() => calculatePriceRange(items), [items]);
  const [finalizing, setFinalizing] = useState(false);

  const finalized = quoteDetail.status === 'completed';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ml: 2,
        mb: 1.5,
      }}
    >
      <Box
        data-testid="top boxes"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '5vw',
          paddingRight: 2,
        }}
      >
        <Box
          sx={(theme) => ({
            borderRadius: 10,
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: isItemsPending ? 'warning.200' : 'primary.50',
            [theme.getColorSchemeSelector('dark')]: {
              backgroundColor: isItemsPending ? 'warning.400' : 'primary.800',
            },
            justifyContent: 'center',
            padding: 2,
          })}
        >
          {isItemsPending ? (
            <PendingActionsIcon
              sx={(theme) => ({
                fontSize: 60,
                paddingRight: 2,
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
              })}
            />
          ) : (
            <CheckIcon
              sx={(theme) => ({
                fontSize: 60,
                paddingRight: 2,
                display: 'flex',
                alignItems: 'center',
                color: theme.palette.mode === 'dark' ? 'white' : 'black',
              })}
            />
          )}
          <Box>
            <Typography
              level="h3"
              sx={{
                fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
              }}
            >
              {finalized
                ? 'Quote Finalized'
                : isItemsPending
                  ? `${itemsPending.length} items left`
                  : 'All items estimated'}
            </Typography>
            <Typography
              level="h4"
              sx={{
                fontSize: { xs: '0.75rem', sm: '1.0rem', md: '1.25rem' },
              }}
            >
              {finalized
                ? 'All items estimated'
                : isItemsPending
                  ? 'Set prices for the remaining items'
                  : 'Quote can be finalized'}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={() => ({
            borderRadius: 10,
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            backgroundColor: 'neutral.300',
          })}
        >
          <Typography
            level="h2"
            textColor="black"
            sx={{
              fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
            }}
          >
            Estimated Worth
          </Typography>
          <Typography
            level="h3"
            textColor="black"
            sx={{
              fontSize: { xs: '0.75rem', sm: '1.0rem', md: '1.25rem' },
            }}
          >
            {priceRange.lowerRange !== priceRange.upperRange
              ? `$${priceRange.lowerRange} - $${priceRange.upperRange}`
              : `$${priceRange.lowerRange}`}
          </Typography>
        </Box>
      </Box>
      <Box data-testid="table-section" sx={{ paddingRight: 2 }}>
        <Box sx={{ mt: 3 }}>
          <Typography level="h4">
            {!finalized && 'Finalizing quote:'} {detail.quoteName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Typography level="h3" sx={{ flex: 1 }}>
            Quote Items
          </Typography>
          <Button
            disabled={isItemsPending}
            loading={finalizing}
            onClick={async () => {
              setFinalizing(true);
              await finalize.mutateAsync({
                id: quoteDetail.id,
                state: !finalized, // toggle state
                value: finalized
                  ? `$${priceRange.lowerRange} - $${priceRange.upperRange}`
                  : null,
              });
              setFinalizing(false);
              void utils.quotes.getQuoteDetailsById.invalidate({
                id: quoteDetail.id,
              });
              void utils.quotes.getQuoteDetails.invalidate();
            }}
          >
            {finalized ? 'Change to Draft' : 'Finalize Quote'}
          </Button>
        </Box>
        <PricingStepTable items={items} finalized={finalized} />
      </Box>
    </Box>
  );
}
