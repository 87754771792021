import CloudIcon from '@mui/icons-material/Cloud';
import { Box, Typography, Link } from '@mui/joy';
import { useDropzone } from 'react-dropzone';

import { useQuoteStore } from '../../../../stores/quote.store';

function ImportItemsStepUploadCSV() {
  const addFiles = useQuoteStore((state) => state.addFiles);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    onDrop: addFiles,
  });

  return (
    <Box
      {...getRootProps()}
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: `2px dashed ${theme.palette.primary[500]}`,
        padding: '20px',
        borderRadius: '3px',
        cursor: 'pointer',
        position: 'relative',
        mb: 1,
        bgcolor: theme.palette.background.backdrop,
        width: '100%',
        height: '32vh',
        pt: 1,
      })}
    >
      <input {...getInputProps()} />
      <CloudIcon
        sx={(theme) => ({
          width: '60%',
          height: '60%',
          fill: 'transparent',
          // @ts-expect-error This is a valid color
          stroke: theme.palette.primary[600],
        })}
        data-testid="cloud-icon"
      />
      <Typography level="h2">
        Drag & drop or{' '}
        <Link sx={(theme) => ({ color: theme.palette.primary[500] })}>
          Click to select files
        </Link>
      </Typography>
      <Typography sx={{ mt: '0.5vh' }}>Supported file types: CSV</Typography>
      <Link href="/greensight-schema.csv" download sx={{ mt: '0.5vh' }}>
        Download schema template
      </Link>
    </Box>
  );
}

export default ImportItemsStepUploadCSV;
