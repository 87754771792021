import { create } from 'zustand';

export const availableSteps = [
  'details',
  'import',
  'edits',
  'pricing',
] as const;
export type StepOption = (typeof availableSteps)[number];

interface StepperStore {
  activeStep: StepOption;

  setActiveStep: (step: StepOption) => void;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  changeStep: (direction: number) => void;
}

export const useStepperStore = create<StepperStore>((set, get) => ({
  activeStep: 'details',
  error: null,

  setActiveStep: (step) => {
    set({ activeStep: step });
  },
  goToNextStep: () => {
    get().changeStep(1);
  },
  goToPrevStep: () => {
    get().changeStep(-1);
  },
  changeStep: (direction: number) => {
    const newStepIndex = calculateNewStepIndex(get().activeStep, direction);
    if (newStepIndex !== -1) {
      set({ activeStep: availableSteps[newStepIndex] });
    }
  },
}));

function calculateNewStepIndex(
  currentStep: StepOption,
  direction: number
): number {
  const currentStepIndex = availableSteps.indexOf(currentStep);
  const newStepIndex = currentStepIndex + direction;
  return Math.max(0, Math.min(newStepIndex, availableSteps.length - 1));
}
