import * as Sentry from '@sentry/react';
import { getConfig, enableSentry } from '@shared';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from 'react-router-dom';

import App from './app/app';
import { loader as userInfoLoader } from './app/components/TopBar/TopBarUserMenu/TopBarUserMenu';
import { AuthProvider } from './app/context/auth-context';
import Dashboard from './app/pages/dashboard';
import NotFound from './app/pages/not-found';
import QuoteDetails, {
  loader as quoteDetailsLoader,
} from './app/pages/quote/details';
import QuoteEdits, {
  loader as quoteEditsLoader,
} from './app/pages/quote/edits';
import ImportItems from './app/pages/quote/import';
import MyQuotes, {
  loader as myQuotesLoader,
} from './app/pages/quote/my-quotes';
import NewQuote from './app/pages/quote/new-quote';
import QuotePricing, {
  loader as quotePricingLoader,
} from './app/pages/quote/pricing';
import Settings from './app/pages/settings';
import AccountSettings, {
  loader as accountSettingsLoader,
} from './app/pages/settings/account';
import { createRoute } from './app/util/router-utils';
import { TRPCWrapper } from './app/util/trpc-wrapper';

import type { DeploymentStage } from '@shared';

const stage = import.meta.env.MODE as DeploymentStage;
const config = getConfig(stage);

Sentry.init({
  dsn: 'https://ab05a355c63c544881d6a370d0e46a28@o4507057818566656.ingest.us.sentry.io/4507268038066176',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      colorScheme: 'system',
    }),
    Sentry.browserProfilingIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [`https://${config.clientEnv.domain}/`],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,
  environment: stage,
  enabled: enableSentry(stage),
});

const root = createRoot(document.getElementById('root')!);
const Main = () => {
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  const NotFoundWrapped = (
    <App>
      <NotFound />
    </App>
  );

  const router = sentryCreateBrowserRouter([
    {
      path: '/',
      element: (
        <App>
          <Outlet />
        </App>
      ),
      errorElement: NotFoundWrapped,
      loader: userInfoLoader,
      children: [
        createRoute({
          pageName: 'Dashboard',
          index: true,
          element: <Dashboard />,
        }),
        createRoute({
          pageName: 'Settings',
          path: 'settings/*',
          children: [
            {
              index: true,
              element: <Settings />,
            },
            createRoute({
              pageName: 'My Account',
              crumbName: 'Account',
              path: 'account',
              loader: accountSettingsLoader,
              element: <AccountSettings />,
            }),
          ],
        }),
        createRoute({
          pageName: 'My Quotes',
          crumbName: 'Quotes',
          path: 'quote/*',
          children: [
            {
              index: true,
              element: <MyQuotes />,
              loader: myQuotesLoader,
            },
            createRoute({
              pageName: 'Create Quote',
              crumbName: 'New Quote',
              path: 'new-quote',
              element: <NewQuote />,
            }),
            createRoute({
              pageName: 'Quote Details',
              path: ':quoteId/details',
              loader: quoteDetailsLoader,
              element: <QuoteDetails />, // Use the existing QuoteDetailsStep component
            }),
            createRoute({
              pageName: 'Import Items',
              path: ':quoteId/import', // Dynamic route for the import step
              element: <ImportItems />, // Component for the import items step
            }),
            createRoute({
              pageName: 'Make Edits',
              path: ':quoteId/edits', // Dynamic route for the import step
              loader: quoteEditsLoader,
              element: <QuoteEdits />, // Component for the import items step
            }),
            createRoute({
              pageName: 'Pricing',
              path: ':quoteId/pricing',
              loader: quotePricingLoader,
              element: <QuotePricing />,
            }),
          ],
        }),
      ],
    },
    createRoute({
      pageName: 'Not Found',
      path: '*',
      loader: userInfoLoader,
      element: NotFoundWrapped,
    }),
  ]);

  return (
    <StrictMode>
      <AuthProvider apiUrl={`https://${config.clientEnv.domain}`}>
        <TRPCWrapper>
          <RouterProvider router={router} />
        </TRPCWrapper>
      </AuthProvider>
    </StrictMode>
  );
};

root.render(<Main />);
