import { z } from 'zod';

/**
 * eBay category schema
 */
const categorySchema = z.object({
  categoryId: z.number(),
  categoryName: z.string(),
});

/**
 * eBay price schema
 */
const priceSchema = z.object({
  currencyId: z.string(),
  value: z.number(),
});

/**
 * eBay selling status schema
 */
const sellingStatusSchema = z.object({
  convertedCurrentPrice: priceSchema,
  sellingState: z.literal('Active'),
});

/**
 * eBay condition schema
 */
const conditionSchema = z.object({
  conditionDisplayName: z.string(),
});

/**
 * eBay item schema
 */
const itemSchema = z.object({
  title: z.string(),
  primaryCategory: categorySchema,
  sellingStatus: sellingStatusSchema,
  condition: conditionSchema.optional(),
});

export type Item = z.infer<typeof itemSchema>;

/**
 * eBay search result schema
 */
const searchResultSchema = z.object({
  count: z.number(),
  item: itemSchema.array().optional(),
});

/**
 * eBay pagination output schema
 */
const paginationOutputSchema = z.object({
  pageNumber: z.number(),
  entriesPerPage: z.number(),
  totalPages: z.number(),
  totalEntries: z.number(),
});

/**
 * eBay find items by keywords response schema
 */
export const findItemsByKeywordsResponseSchema = z.object({
  searchResult: searchResultSchema,
  paginationOutput: paginationOutputSchema,
});
