import { PasswordChange, passwordChangeSchema } from '@backend/types';
import { zodResolver } from '@hookform/resolvers/zod';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, LinearProgress } from '@mui/joy';
import Alert from '@mui/joy/Alert';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { trpc } from '@shared';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import zxcvbn from 'zxcvbn';

import FormInput from '../../../../FormInput/FormInput';

import type { SubmitHandler } from 'react-hook-form';

const PasswordChange = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordChange>({
    resolver: zodResolver(passwordChangeSchema),
  });

  const changePassword = trpc.session.changePassword.useMutation();

  const onSubmit: SubmitHandler<PasswordChange> = async (data) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await changePassword.mutateAsync(data);
      setSuccess(true);
    } catch (err) {
      setError((err as any).message);
    }

    setLoading(false);
  };

  const passwordValue = useWatch({ control, name: 'newPassword' });
  const passwordAnalysis = zxcvbn(passwordValue ?? '');
  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <FormInput
            inputProps={{
              type: showCurrentPassword ? 'text' : 'password',
              placeholder: 'Type here...',
              startDecorator: <KeyIcon />,
              endDecorator: (
                <IconButton
                  data-testid="current-visibility"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {showCurrentPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              ),
            }}
            field="currentPassword"
            fieldLabel="Current Password"
            errors={errors}
            register={register}
          />
          <Stack
            spacing={0.5}
            sx={{
              '--hue': Math.min(passwordAnalysis.score * 25, 120),
            }}
          >
            <FormInput
              inputProps={{
                type: showNewPassword ? 'text' : 'password',
                placeholder: 'Type here...',
                startDecorator: <KeyIcon />,
                endDecorator: (
                  <IconButton
                    data-testid="new-visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                ),
              }}
              field="newPassword"
              fieldLabel="New Password"
              errors={errors}
              register={register}
            />
            {passwordValue && (
              <>
                <LinearProgress
                  determinate
                  size="sm"
                  value={Math.min(passwordAnalysis.score * 25, 100)}
                  sx={{
                    bgcolor: 'background.level3',
                    color: 'hsl(var(--hue) 80% 40%)',
                  }}
                />
                <Typography
                  level="body-xs"
                  sx={{
                    alignSelf: 'flex-end',
                    color: 'hsl(var(--hue) 80% 30%)',
                  }}
                >
                  {passwordAnalysis.feedback.warning}
                </Typography>
              </>
            )}
          </Stack>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Stack direction="row" spacing={2}>
              {error != null && (
                <Alert size="sm" color="danger">
                  {error}
                </Alert>
              )}
              {success && (
                <Alert size="sm" color="success">
                  Password changed!
                </Alert>
              )}
              <Button variant="solid" type="submit" loading={loading}>
                Change Password
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Box>
  );
};

export default PasswordChange;
