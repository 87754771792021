import { create } from 'zustand';

interface PageStore {
  page: string;
  setPage: (page: string) => void;
}

export const usePageStore = create<PageStore>((set) => ({
  page: 'Dashboard',
  setPage: (page) => set({ page }),
}));
