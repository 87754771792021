import { ALPHA_ENV, BETA_ENV, LOCAL_ENV, PROD_ENV } from '@backend/config';

import type { APIEnv } from '@backend/config';

export const stages = ['alpha', 'beta', 'production'] as const;

export type Stage = (typeof stages)[number];

export type DeploymentStage = 'test' | 'dev' | 'fallback' | Stage;

export interface AppConfiguration {
  clientEnv: APIEnv;
  FRONTEND_DOMAIN: string;
  WRISTBAND_DOMAIN: string;
  unitTest?: boolean;
}

const prodConfig: AppConfiguration = {
  clientEnv: PROD_ENV,
  FRONTEND_DOMAIN: 'greensight.app',
  WRISTBAND_DOMAIN: 'prod-greensight.us.wristband.dev',
};

const betaConfig: AppConfiguration = {
  clientEnv: BETA_ENV,
  FRONTEND_DOMAIN: 'integ.greensight.app',
  WRISTBAND_DOMAIN: 'beta-greensight.us.wristband.dev',
};

const alphaConfig: AppConfiguration = {
  clientEnv: ALPHA_ENV,
  FRONTEND_DOMAIN: 'dev.greensight.app',
  WRISTBAND_DOMAIN: 'beta-greensight.us.wristband.dev',
};

export const localConfig: AppConfiguration = {
  ...alphaConfig,
  FRONTEND_DOMAIN: 'local.greensight.app:4200',
  clientEnv: LOCAL_ENV, // TODO: This uses localhost, but this won't work for mobile
};

const modeToConfig: Record<DeploymentStage, AppConfiguration> = {
  production: prodConfig,
  beta: betaConfig,
  alpha: alphaConfig,
  dev: localConfig,
  test: {
    ...localConfig,
    unitTest: true,
  },
  fallback: alphaConfig,
};

/**
 * Gets the configuration for the specified deployment stage.
 * @param deploymentMode - The deployment stage (e.g. 'production', 'beta', 'alpha', 'dev', 'fallback')
 * @returns The configuration for the specified deployment stage
 */
export const getConfig = (deploymentMode: DeploymentStage) =>
  modeToConfig[deploymentMode] ?? modeToConfig.fallback;

/**
 * Gets the profile picture URL for the specified tenant and user.
 * @param clientEnv - The client environment
 * @param tenantId - The tenant ID
 * @param userId - The user ID
 * @returns The profile picture URL
 */
export const getPfpUrl = (
  clientEnv: APIEnv,
  tenantId: string,
  userId: string
) => `https://${clientEnv.pfpBucketName}.greensight.app/${tenantId}/${userId}`;
