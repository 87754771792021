import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy';

import type {
  FormControlTypeMap,
  FormHelperTextTypeMap,
  InputTypeMap,
} from '@mui/joy';
import type {
  Path,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';

export interface FieldDef<T, O = string> {
  key: keyof T;
  type?: React.HTMLInputTypeAttribute;
  label: string;
  placeholder: string;
  helperText: string;
  options?: readonly O[];
  required?: boolean;
}

interface FormInputProps<T extends FieldValues> {
  children?: React.ReactNode;
  field: Path<T>;
  fieldLabel?: string;
  fieldHelperText?: string | React.ReactNode;
  inputProps?: InputTypeMap['props'];
  formHelperTextProps?: FormHelperTextTypeMap['props'];
  formControlProps?: FormControlTypeMap['props'];
  errors?: FieldErrors<T>;
  required?: boolean;
  register: UseFormRegister<T>;
}

const FormInput = <T extends FieldValues>({
  children,
  field,
  fieldLabel,
  fieldHelperText,
  inputProps,
  formHelperTextProps,
  formControlProps,
  errors,
  required,
  register,
}: FormInputProps<T>) => {
  const error = errors?.[field];

  return (
    <FormControl {...formControlProps} error={!!error}>
      {fieldLabel && (
        <FormLabel>
          {fieldLabel} {required && '*'}
        </FormLabel>
      )}
      {children ?? (
        <Input
          {...inputProps}
          {...register(field, {
            valueAsNumber: inputProps?.type === 'number',
          })}
        />
      )}
      <FormHelperText {...formHelperTextProps}>
        {error ? (
          <>
            <InfoOutlined />
            {error.message}
          </>
        ) : (
          fieldHelperText
        )}
      </FormHelperText>
    </FormControl>
  );
};

export default FormInput;
