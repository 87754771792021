import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import { useLoaderData, type LoaderFunctionArgs } from 'react-router-dom';

import QuoteEditsStep from '../../../components/Quote/EditsStep/QuoteEditsStep';
import NewQuoteStepper from '../../../components/Quote/Stepper/NewQuoteStepper';
import { clientUtils } from '../../../util/trpc-client';

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const quoteItems = await clientUtils.quotes.getItems.ensureData({
    id: params.quoteId!,
  });
  const quoteDetail = await clientUtils.quotes.getQuoteDetailsById.ensureData({
    id: params.quoteId!,
  });

  return {
    quoteItems,
    quoteDetail,
  };
};

const QuoteEdits = () => {
  const { quoteDetail } = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        width: '100%',
        maxWidth: 1500,
        margin: '0 auto',
      }}
    >
      <NewQuoteStepper finalized={quoteDetail.status === 'completed'} />
      <Card sx={{ width: '100%' }}>
        <QuoteEditsStep />
      </Card>
    </Box>
  );
};

export default QuoteEdits;
