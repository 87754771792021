import { ClickAwayListener } from '@mui/base';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Dropdown, Menu, MenuButton } from '@mui/joy';
import Badge from '@mui/joy/Badge';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../context/auth-context';

import NotificationsList from './NotificationsList/NotificationsList';

interface IconButtonProps {
  showBadge?: boolean;
}

export default function TopBarButtons({ showBadge }: IconButtonProps) {
  const { logout } = useAuth();

  const notifButtonRef = useRef<HTMLButtonElement>(null);
  const [openNotif, setOpenNotif] = useState(false);
  const handleOpenChange = useCallback(
    (_: React.SyntheticEvent | null, isOpen: boolean) => {
      setOpenNotif(isOpen);
    },
    []
  );

  return (
    <Stack spacing={2} direction="row">
      <IconButton
        variant="solid"
        sx={{
          bgcolor: '#FFBBBB',
          ':hover': {
            border: '2px solid #ff5252',
            bgcolor: '#FFBBBB',
          },
          ':active': {
            bgcolor: '#fca2a2',
          },
        }}
        data-testid="settings-button"
        component={Link}
        to="/settings"
      >
        <SettingsOutlinedIcon sx={{ color: 'red' }} />
      </IconButton>
      <IconButton
        variant="solid"
        sx={{
          bgcolor: '#E4D3FF',
          ':hover': {
            border: '2px solid #a066ff',
            bgcolor: '#E4D3FF',
          },
          ':active': {
            bgcolor: '#ceb0ff',
          },
        }}
        data-testid="logout-button"
        onClick={() => {
          void logout();
        }}
      >
        <LogoutIcon sx={{ color: 'purple' }} />
      </IconButton>
      <Dropdown open={openNotif} onOpenChange={handleOpenChange}>
        <Badge
          invisible={!showBadge}
          color="danger"
          size="sm"
          data-testid="badge"
        >
          <MenuButton
            ref={notifButtonRef}
            slots={{ root: IconButton }}
            slotProps={{
              root: {
                sx: {
                  bgcolor: '#FFE3BA',
                  ':hover': {
                    border: '2px solid #ff9f12',
                    bgcolor: '#FFE3BA',
                  },
                  ':active': {
                    bgcolor: '#ffd89e',
                  },
                },
                variant: 'solid',
              },
            }}
            data-testid="notifications-button"
          >
            <NotificationsOutlinedIcon sx={{ color: 'orange' }} />
          </MenuButton>
        </Badge>
        <ClickAwayListener
          onClickAway={(event) => {
            if (event.target !== notifButtonRef.current) {
              handleOpenChange(null, false);
            }
          }}
        >
          <Menu placement="bottom-end">
            <NotificationsList />
          </Menu>
        </ClickAwayListener>
      </Dropdown>
    </Stack>
  );
}
