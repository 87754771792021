import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
  Button,
  DialogTitle,
  Modal,
  DialogActions,
  DialogContent,
  ModalDialog,
  Divider,
  Alert,
} from '@mui/joy';
import { useState } from 'react';

interface DeleteDialogProps {
  itemToBeDeleted: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
}

const DeleteDialog = ({
  itemToBeDeleted,
  open,
  onClose,
  onDelete,
}: DeleteDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Delete {itemToBeDeleted}
        </DialogTitle>
        <Divider />
        <DialogContent>
          Are you sure you want to delete this {itemToBeDeleted}?
          {error && (
            <Alert color="danger" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="solid"
            color="danger"
            onClick={async () => {
              setLoading(true);
              try {
                await onDelete();
              } catch (error) {
                setError((error as any).message);
              } finally {
                setLoading(false);
              }
              onClose();
            }}
            loading={loading}
          >
            Delete
          </Button>
          <Button
            variant="plain"
            color="neutral"
            onClick={() => {
              onClose();
            }}
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default DeleteDialog;
