import type { RouteObject } from 'react-router-dom';

export interface Breadcrumb {
  pageName: string;
  crumbName?: string;
  crumbPath: string;
}

export function createRoute(
  routeObj: RouteObject & Omit<Breadcrumb, 'crumbPath'>
): RouteObject {
  let crumbPath = routeObj.path ?? '';

  if (crumbPath.endsWith('/*')) {
    // strip the /* from the end of the path
    crumbPath = crumbPath.slice(0, -2);
  }

  const crumb: Breadcrumb = {
    pageName: routeObj.pageName,
    crumbName: routeObj.crumbName,
    crumbPath,
  };
  return {
    ...routeObj,
    handle: { crumb },
  };
}
