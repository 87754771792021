import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import {
  Avatar,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Typography,
  Dropdown,
} from '@mui/joy';
import { trpc } from '@shared';
import { Link, useLoaderData } from 'react-router-dom';

import { useAuth } from '../../../context/auth-context';
import { clientUtils } from '../../../util/trpc-client';

export async function loader() {
  const userInfoData = await clientUtils.session.userInfo.ensureData();
  return {
    userInfoData,
  };
}

export default function TopBarUserMenu() {
  const { logout } = useAuth();

  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;

  const userInfo = trpc.session.userInfo.useQuery(undefined, {
    initialData: loaderData.userInfoData,
  });

  return (
    <Stack spacing={2} direction="row">
      <Avatar
        src={userInfo.data?.pictureUrl ?? undefined}
        style={{ borderRadius: 5 }}
        data-testid="user-avatar"
      />
      <Stack direction="column">
        <Typography level="title-sm" data-testid="user-name-text" noWrap>
          {userInfo.data.displayName ??
            `${userInfo.data.givenName} ${userInfo.data.familyName}`}
        </Typography>
        <Typography level="body-xs" data-testid="user-email-text" noWrap>
          {userInfo.data.email}
        </Typography>
      </Stack>
      <Dropdown>
        <MenuButton slots={{ root: IconButton }} data-testid="menu-button">
          <ArrowDropDown />
        </MenuButton>
        <Menu>
          <MenuItem
            data-testid="menu-account-button"
            component={Link}
            to="/settings/account"
          >
            Account Settings
          </MenuItem>
          <MenuItem
            onClick={() => {
              void logout();
            }}
            data-testid="menu-logout-button"
          >
            Logout
          </MenuItem>
        </Menu>
      </Dropdown>
    </Stack>
  );
}
