import MenuIcon from '@mui/icons-material/Menu';
import { Box, Typography, GlobalStyles, IconButton, Sheet } from '@mui/joy';

import { toggleSidebar } from '../../util/sidebar-utils';

import TopBarBreadcrumbs from './TopBarBreadCrumbs/TopBarBreadCrumbs';
import TopBarButtons from './TopBarButtons/TopBarButtons';
import TopBarUserMenu from './TopBarUserMenu/TopBarUserMenu';

interface TopBarProps {
  title: string;
}

export default function TopBar({ title }: TopBarProps) {
  return (
    <Sheet
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        width: {
          xs: '100%',
          md: 'calc(100% - var(--Sidebar-width))',
        },
        // offset the Sidebar width
        left: {
          xs: 0,
          md: 'var(--Sidebar-width)',
        },
        height: 'var(--Header-height)',
        p: 2,
        zIndex: 999,
        borderBottom: '1px solid',
        borderColor: 'background.level1',
        boxShadow: 'sm',
      }}
    >
      <GlobalStyles
        styles={() => ({
          ':root': {
            '--Header-height': '60px',
          },
        })}
      />
      <IconButton
        onClick={() => {
          toggleSidebar();
        }}
        sx={{ display: { xs: 'inline-flex', md: 'none' } }}
        variant="outlined"
        color="neutral"
        size="sm"
      >
        <MenuIcon />
      </IconButton>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            whiteSpace: 'nowrap',
            pl: 3,
          }}
        >
          <Typography level="h2" component="h1" data-testid="title">
            {title}
          </Typography>
          <TopBarBreadcrumbs />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 5,
          }}
        >
          <TopBarButtons showBadge={false} />
          <TopBarUserMenu />
        </Box>
      </Box>
    </Sheet>
  );
}
