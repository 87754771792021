import type { DeploymentStage } from './config';

export const enableSentry = (mode: DeploymentStage) => {
  switch (mode) {
    case 'alpha':
    case 'beta':
    case 'production':
      return true;

    default:
      return false;
  }
};
