import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRightRounded';
import { Card, ListItemButton } from '@mui/joy';
import Avatar from '@mui/joy/Avatar';
import List from '@mui/joy/List';
import ListItem, { listItemClasses } from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import { Link } from 'react-router-dom';

import type { ColorPaletteProp } from '@mui/joy';

interface SettingItemProps {
  title: string;
  description: string;
  route: string;
  icon: React.ReactNode;
  color: ColorPaletteProp;
}

const SettingItem = ({
  title,
  description,
  route,
  icon,
  color,
}: SettingItemProps) => (
  <ListItem>
    <ListItemButton
      component={Link}
      to={route}
      sx={{
        borderRadius: 'md',
      }}
    >
      <Avatar color={color}>{icon}</Avatar>
      <ListItemContent>
        <Typography level="title-md">{title}</Typography>
        <Typography level="body-sm">{description}</Typography>
      </ListItemContent>
      <KeyboardArrowRight fontSize="large" />
    </ListItemButton>
  </ListItem>
);

const Settings = () => (
  <Card
    sx={{
      width: '100%',
      maxWidth: 1000,
      margin: '0 auto',
    }}
  >
    <List
      variant="plain"
      sx={{
        borderRadius: 'md',
        [`& .${listItemClasses.root}`]: {
          paddingBlock: '0.5rem',
        },
      }}
    >
      <SettingItem
        title="My Account"
        description="Manage your account"
        route="account"
        icon={<AccountCircleIcon />}
        color="primary"
      />
    </List>
  </Card>
);

export default Settings;
