import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';

import ImportItemsStepUploadCSV from '../../../components/Quote/ImportItemsStep/ImportItemsStepUploadCSV/ImportItemsStepUploadCSV';
import ImportItemsStepUploading from '../../../components/Quote/ImportItemsStep/ImportItemsStepUploading/ImportItemsStepUploading';
import NewQuoteStepper from '../../../components/Quote/Stepper/NewQuoteStepper';

const ImportItems = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        width: '100%',
        maxWidth: 1500,
        margin: '0 auto',
      }}
    >
      {/* TODO: FIX me */}
      <NewQuoteStepper finalized={false} />
      <Card sx={{ width: '100%' }}>
        <ImportItemsStepUploadCSV />
        <ImportItemsStepUploading />
      </Card>
    </Box>
  );
};

export default ImportItems;
