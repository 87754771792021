import {
  createQuoteItemSchema,
  ebayElectronicCategories,
  itemManufacturers,
} from '@backend/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { trpc } from '@shared';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useQuoteStore } from '../../../../stores/quote.store';
import FormInput from '../../../FormInput/FormInput';

import WindowedAutocomplete from './WindowedAutocomplete/WindowedAutocomplete';

import type { QuoteItemDialogOperation } from '../../../../stores/quote.store';
import type { FieldDef } from '../../../FormInput/FormInput';
import type { CategoryItem, EbayCategory, QuoteItem } from '@backend/types';
import type { SubmitHandler } from 'react-hook-form';
import type { z } from 'zod';

const formSchema = createQuoteItemSchema.omit({ quoteId: true });
export type QuoteItemFormFields = z.infer<typeof formSchema>;

type QuoteEditsItemFormProps = {
  operation: QuoteItemDialogOperation;
  currentItem?: QuoteItem;
  onClose: () => void;
};

const toOptions = (
  categories: Record<string, EbayCategory>,
  depth = 0,
  parentId: number | null = null
): CategoryItem[] => {
  return Object.entries(categories).flatMap(([name, category]) => {
    const { id, subcategories = {} } = category;
    const children = toOptions(subcategories, depth + 1, id);

    const option: CategoryItem = {
      id,
      name,
      depth,
      parentId,
      matchTerms: [name, ...children.map((child) => child.name)],
    };

    return [option, ...children];
  });
};

const QuoteEditsItemForm = ({
  operation,
  currentItem,
  onClose,
}: QuoteEditsItemFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<QuoteItemFormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: currentItem,
  });

  const { quoteId } = useParams();

  const [setSuccess, setError] = useQuoteStore((state) => [
    state.setSuccess,
    state.setError,
  ]);

  const [loading, setLoading] = useState(false);

  const utils = trpc.useUtils();

  const createItem = trpc.quotes.createItem.useMutation();
  const updateItem = trpc.quotes.updateItem.useMutation();

  const onSubmit: SubmitHandler<QuoteItemFormFields> = async (data) => {
    setLoading(true);
    setError(null);

    try {
      if (!quoteId) {
        throw new Error('Quote ID not found');
      }
      // If the currentItem exists, update it. Otherwise, create a new item.
      if (currentItem) {
        await updateItem.mutateAsync({
          id: currentItem.id,
          data: { ...currentItem, ...data },
        });
        setSuccess('Item successfully updated');
      } else {
        await createItem.mutateAsync({
          ...data,
          quoteId,
        });
        setSuccess('Item successfully created');
      }
      setError(null);
      void utils.quotes.getItems.invalidate();
      onClose();
    } catch (error) {
      setSuccess('');
      setError((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  const config: Array<FieldDef<QuoteItemFormFields, CategoryItem>> = useMemo(
    () => [
      {
        key: 'quantity',
        label: 'Quantity',
        placeholder: 'i.e. 3',
        helperText: 'How many units do you have?',
        required: true,
        type: 'number',
      },
      {
        key: 'category',
        label: 'Product Category',
        placeholder: 'i.e. Apple Laptops',
        helperText: "What's the best classification?",
        options: toOptions(ebayElectronicCategories),
        required: true,
      },
      {
        key: 'manufacturer',
        label: 'Manufacturer',
        placeholder: 'i.e. Apple',
        helperText: 'Who made the item?',
        options: toOptions(itemManufacturers),
        required: true,
      },
      {
        key: 'model',
        label: 'Model',
        placeholder: 'i.e. MacBook Pro',
        helperText: 'What is the item model?',
        required: true,
      },
      {
        key: 'identifier',
        label: 'Additional Identifier',
        placeholder: 'i.e. MK193LL/A',
        helperText:
          'Optionally include any identifiers to narrow down the item',
      },
    ],
    []
  );

  return (
    <Box sx={{ overflowY: 'auto' }}>
      <Typography color="neutral" level="h4" sx={{ mb: 1 }}>
        {operation === 'update'
          ? 'Modify an Existing Item'
          : 'Create a New Item'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          {config.map((item) => (
            <FormInput
              key={item.key}
              errors={errors}
              formControlProps={{
                sx: {
                  flexBasis: '23%',
                  mb: 3,
                },
              }}
              field={item.key}
              register={register}
              fieldLabel={item.label}
              fieldHelperText={item.helperText}
              inputProps={{ placeholder: item.placeholder, type: item.type }}
              required={item.required}
            >
              {item.options && (
                <WindowedAutocomplete
                  options={item.options}
                  placeholder={item.placeholder}
                  field={item.key}
                  register={register}
                />
              )}
            </FormInput>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mr: 2,
          }}
        >
          <Button loading={loading} type="submit" sx={{ mr: 2 }}>
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default QuoteEditsItemForm;
