import { createQuoteDetailSchema } from '@backend/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Typography, Alert } from '@mui/joy';
import { trpc } from '@shared';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import FormInput from '../../FormInput/FormInput';

import type { FieldDef } from '../../FormInput/FormInput';
import type { CreateQuoteDetail, QuoteDetail } from '@backend/types';
import type { SubmitHandler } from 'react-hook-form';

interface QuoteDetailsStepProps {
  detail?: QuoteDetail;
}

export default function QuoteDetailsStep({ detail }: QuoteDetailsStepProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const utils = trpc.useUtils();

  const createQuoteDetail = trpc.quotes.createQuoteDetail.useMutation();
  const updateQuoteDetail = trpc.quotes.updateQuoteDetail.useMutation();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateQuoteDetail>({
    resolver: zodResolver(createQuoteDetailSchema),
    defaultValues: {
      quoteName: detail?.quoteName,
      quoteDescription: detail?.quoteDescription,
    },
  });

  useEffect(() => {
    reset(detail);
  }, [reset, detail]);

  const onSubmit: SubmitHandler<CreateQuoteDetail> = async (data) => {
    setLoading(true);
    setError(null);

    try {
      let id = detail?.id;
      if (id) {
        if (
          detail?.quoteName !== data.quoteName ||
          detail?.quoteDescription !== data.quoteDescription
        ) {
          await updateQuoteDetail.mutateAsync({ id, data });
          void utils.quotes.getQuoteDetailsById.invalidate({ id });
        }
      } else {
        const newQuote = await createQuoteDetail.mutateAsync(data);
        id = newQuote.id;
      }
      void utils.quotes.getQuoteDetails.invalidate();

      navigate(`/quote/${id}/import`);
    } catch (error) {
      setError((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  const config: Array<FieldDef<CreateQuoteDetail>> = [
    {
      key: 'quoteName',
      label: 'Name',
      placeholder: 'i.e. Q1 Intake',
      helperText: 'This is your quote name',
      required: true,
    },
    {
      key: 'quoteDescription',
      label: 'Description',
      placeholder: 'i.e. Quarterly intake for q1',
      helperText: 'Describe what this quote is',
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2, mb: 1.5 }}>
      <Typography
        color="primary"
        level="h2"
        variant="plain"
        fontWeight="bold"
        sx={{ mb: 1 }}
      >
        Quote Details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {config.map((item) => (
          <FormInput
            key={item.key}
            fieldLabel={item.label}
            fieldHelperText={item.helperText}
            inputProps={{ placeholder: item.placeholder, sx: { width: '50%' } }}
            field={item.key}
            errors={errors}
            register={register}
            formControlProps={{ sx: { mb: 3 } }}
            required={item.required}
          />
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button loading={loading} type="submit" sx={{ mr: 2 }}>
            Next
          </Button>
        </Box>
        {error && (
          <Alert
            color="danger"
            variant="plain"
            sx={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'flex-end',
              mb: -3,
            }}
          >
            {error}
          </Alert>
        )}
      </form>
    </Box>
  );
}
