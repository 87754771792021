import { useTheme } from '@mui/joy';
import {
  VictoryAxis,
  VictoryChart,
  VictoryHistogram,
  VictoryLabel,
} from 'victory';

import {
  calculateNumberOfBins,
  removeOutliers,
} from '../../util/histogram-utils';

export interface HistogramProps {
  data: number[];
}

function Histogram({ data }: HistogramProps) {
  const theme = useTheme(); // Using JoyUI theme hook to access theme

  // Apply theme colors and fonts from JoyUI to VictoryChart
  const axisStyles = {
    axis: { stroke: theme.palette.text.primary },
    axisLabel: {
      fontSize: 20,
      padding: 30,
      fill: theme.palette.text.primary,
    },
    ticks: { size: 5, stroke: theme.palette.text.secondary },
    tickLabels: {
      fill: theme.palette.text.primary,
      padding: 5,
      fontSize: 15,
    },
  };

  const formattedData = removeOutliers(data).map((value) => ({ x: value }));
  return (
    <VictoryChart>
      <VictoryLabel
        text={`Prices for ${data.length} current listings`}
        textAnchor="middle"
        x={225}
        y={30}
        style={{ fill: theme.palette.text.primary, fontSize: 20 }}
      />
      <VictoryAxis
        label="Price"
        style={axisStyles}
        tickFormat={(x: number) => `$${x}`}
      />
      <VictoryAxis
        dependentAxis
        label="Listings"
        style={axisStyles}
        tickFormat={(x: number) => `${Math.floor(x)}`}
      />
      <VictoryHistogram
        bins={calculateNumberOfBins(data)}
        style={{ data: { fill: 'green' } }}
        data={formattedData}
      />
    </VictoryChart>
  );
}

export default Histogram;
