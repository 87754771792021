import { trpc } from '@shared';
import { QueryClientProvider } from '@tanstack/react-query';

import { queryClient, trpcClient } from './trpc-client';

// eslint-disable-next-line import/prefer-default-export
export function TRPCWrapper({ children }: { children: React.ReactNode }) {
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
}
