import {
  personalInfoSchema,
  type PersonalInfo,
  type UserData,
} from '@backend/types';
import { zodResolver } from '@hookform/resolvers/zod';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Alert from '@mui/joy/Alert';
import AspectRatio from '@mui/joy/AspectRatio';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { trpc } from '@shared';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import FormInput from '../../../FormInput/FormInput';

import ProfilePictureUploadDialog from './ProfilePictureUploadDialog/ProfilePictureUploadDialog';

import type { SubmitHandler } from 'react-hook-form';

interface PersonalInfoProps {
  userInfo: UserData;
  managed?: boolean;
}

const PersonalInfo = ({ userInfo, managed }: PersonalInfoProps) => {
  const [openProfilePictureModal, setOpenProfilePictureModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalInfo>({
    resolver: zodResolver(personalInfoSchema.omit({ pictureUrl: true })),
    defaultValues: {
      givenName: userInfo.givenName ?? undefined,
      familyName: userInfo.familyName ?? undefined,
      displayName: userInfo.displayName ?? undefined,
    },
  });

  const updatePersonalInfo = trpc.session.updatePersonalInfo.useMutation();
  const utils = trpc.useUtils();

  const onSubmit: SubmitHandler<Partial<PersonalInfo>> = async (data) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    // Managed accounts can only update their display name
    if (managed) {
      data = {
        displayName: data.displayName,
      };
    }

    try {
      await updatePersonalInfo.mutateAsync(data);
      void utils.session.userInfo.invalidate();
      void utils.session.sessionData.invalidate();
      setSuccess(true);
    } catch (err) {
      setError((err as any).message);
    }

    setLoading(false);
  };

  const picture = userInfo.pictureUrl ?? undefined;

  return (
    <>
      <Card
        sx={{
          width: '100%',
          maxWidth: 1000,
          margin: '0 auto',
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">Personal info</Typography>
          <Typography level="body-sm">
            Customize your profile information
          </Typography>
        </Box>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" spacing={3} sx={{ display: 'flex', my: 1 }}>
            <Stack spacing={1}>
              <AspectRatio
                ratio="1"
                maxHeight={200}
                sx={{ flex: 1, minWidth: 120, borderRadius: '100%' }}
              >
                <Avatar src={picture} data-testid="user-avatar" />
              </AspectRatio>
              <IconButton
                aria-label="upload new picture"
                size="sm"
                variant="outlined"
                color="neutral"
                onClick={() => setOpenProfilePictureModal(true)}
                sx={{
                  bgcolor: 'background.body',
                  position: 'absolute',
                  zIndex: 2,
                  borderRadius: '50%',
                  left: 100,
                  top: 170,
                  boxShadow: 'sm',
                }}
              >
                <EditRoundedIcon />
              </IconButton>
            </Stack>
            <Stack spacing={2}>
              <Stack direction="row" spacing={3}>
                <FormInput
                  field="givenName"
                  fieldLabel="First Name"
                  inputProps={{ disabled: managed }}
                  errors={errors}
                  register={register}
                  required
                />
                <FormInput
                  field="familyName"
                  fieldLabel="Last Name"
                  inputProps={{ disabled: managed }}
                  errors={errors}
                  register={register}
                  required
                />
              </Stack>
              <FormInput
                field="displayName"
                fieldLabel="Display Name"
                errors={errors}
                register={register}
              />
            </Stack>
          </Stack>
          <CardOverflow
            sx={{
              borderTop: '1px solid',
              borderColor: 'divider',
            }}
          >
            <CardActions sx={{ pt: 2, justifyContent: 'space-between' }}>
              {managed && (
                <Typography level="body-sm">
                  Some of your account information is managed by your company.
                </Typography>
              )}
              <Stack direction="row" spacing={2}>
                {error != null && (
                  <Alert size="sm" color="danger">
                    {error}
                  </Alert>
                )}
                {success && (
                  <Alert size="sm" color="success">
                    Profile updated!
                  </Alert>
                )}
                <Button variant="solid" type="submit" loading={loading}>
                  Save
                </Button>
              </Stack>
            </CardActions>
          </CardOverflow>
        </form>
      </Card>
      <ProfilePictureUploadDialog
        open={openProfilePictureModal}
        onClose={() => setOpenProfilePictureModal(false)}
      />
    </>
  );
};

export default PersonalInfo;
