// Please do not modify this file it is generated by `generate-backend-types.ts`
/* eslint-disable */

export interface APIEnv {
  domain: string;
  photosBucketName: string;
  importsBucketName: string;
  pfpBucketName: string;
}
export const ALPHA_ENV: APIEnv = {
  domain: 'alpha.api.greensight.app',
  photosBucketName: 'dev-captures',
  importsBucketName: 'dev-imports',
  pfpBucketName: 'dev-profile-images',
};
export const BETA_ENV: APIEnv = {
  domain: 'beta.api.greensight.app',
  photosBucketName: 'beta-captures',
  importsBucketName: 'beta-imports',
  pfpBucketName: 'beta-profile-images',
};
export const PROD_ENV: APIEnv = {
  domain: 'api.greensight.app',
  photosBucketName: 'prod-captures',
  importsBucketName: 'prod-imports',
  pfpBucketName: 'prod-profile-images',
};
export const LOCAL_ENV: APIEnv = {
  domain: 'localhost:3001',
  photosBucketName: 'dev-captures',
  importsBucketName: 'dev-imports',
  pfpBucketName: 'dev-profile-images',
};

export const cloudflareAccountId = '7271a277a419da69b26e1e57a8620cfb';
