import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from '@mui/joy';

import { usePrevQuotesTableStore } from '../../../stores/prevQuotesTable.store';

import type { QuoteDetailSorting } from '../../../stores/prevQuotesTable.store';

interface PrevQuotesCellProps {
  children: React.ReactNode;
  thProps?: React.ThHTMLAttributes<HTMLTableCellElement>;
}

export default function PrevQuotesHeaderCell({
  children,
  thProps,
}: PrevQuotesCellProps) {
  const [sorting, setSorting] = usePrevQuotesTableStore((state) => [
    state.sorting,
    state.setSorting,
  ]);
  const childrenToHeader = {
    Name: 'quoteName',
    Description: 'quoteDescription',
    'Last Updated': 'updatedAt',
    'Created At': 'createdAt',
    'Est. Value': 'estValue',
    Status: 'status',
  };
  const header = childrenToHeader[
    children?.toString() as keyof typeof childrenToHeader
  ] as QuoteDetailSorting;

  return (
    <th {...thProps}>
      <Link
        underline="none"
        color="primary"
        component="button"
        onClick={() => {
          if (sorting.header === header) {
            setSorting({
              ...sorting,
              order: sorting.order === 'asc' ? 'desc' : 'asc',
            });
            return;
          }
          setSorting({ header: header, order: 'asc' });
        }}
        fontWeight="lg"
        endDecorator={
          sorting.header === header && (
            <ArrowDropDownIcon data-testid="prev-quotes-header-cell-dropdown-icon" />
          )
        }
        sx={{
          '& svg': {
            transition: '0.2s',
            transform:
              sorting.order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
          },
        }}
      >
        {children}
      </Link>
    </th>
  );
}
