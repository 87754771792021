import AddIcon from '@mui/icons-material/Add';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import { IconButton, ListItemDecorator } from '@mui/joy';
import Box from '@mui/joy/Box';
import GlobalStyles from '@mui/joy/GlobalStyles';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Toggler({
  defaultExpanded = false,
  topItemContent,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  topItemContent: React.ReactNode;
}) {
  const [open, setOpen] = useState(defaultExpanded);
  return (
    <ListItem
      nested
      endAction={
        <IconButton onClick={() => setOpen(!open)} size="sm" sx={{ ml: 1 }}>
          <KeyboardArrowDownIcon
            sx={{ transform: open ? 'rotate(180deg)' : 'none' }}
          />
        </IconButton>
      }
    >
      {topItemContent}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </ListItem>
  );
}

interface Page {
  name: string;
  route: string;
  icon: React.ReactNode;
  subpages?: Page[];
}

const pages: Page[] = [
  {
    name: 'Dashboard',
    route: '/',
    icon: <SpaceDashboardIcon />,
  },
  {
    name: 'Quote',
    route: '/quote',
    icon: <ContentPasteIcon />,
    subpages: [
      {
        name: 'My Quotes',
        route: '/quote',
        icon: <RequestQuoteIcon />,
      },
      {
        name: 'Create Quote',
        route: '/quote/new-quote',
        icon: <AddIcon />,
      },
    ],
  },
];

export default function Sidebar() {
  const { pathname } = useLocation();
  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: 'fixed',
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 1000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography
          level="h4"
          sx={{
            color: 'primary.200',
          }}
        >
          GreenSight
        </Typography>
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--ListItem-gap': '15px',
            '--List-nestedInsetStart': '10px',
          }}
        >
          {pages.map((page) =>
            page.subpages != null ? (
              <Toggler
                key={page.name}
                defaultExpanded={pathname.includes(page.route)}
                topItemContent={
                  <ListItemButton
                    selected={pathname === page.route}
                    component={Link}
                    to={page.route}
                  >
                    <ListItemDecorator>{page.icon}</ListItemDecorator>
                    <ListItemContent>
                      <b>{page.name}</b>
                    </ListItemContent>
                  </ListItemButton>
                }
              >
                <List
                  sx={{
                    gap: 0.5,
                    '--ListItem-paddingRight': 0,
                  }}
                >
                  {page.subpages.map((subpage, idx) => (
                    <ListItem
                      key={subpage.name}
                      sx={{
                        pt: idx === 0 ? 1 : undefined,
                      }}
                    >
                      <ListItemButton
                        selected={pathname === subpage.route}
                        component={Link}
                        to={subpage.route}
                      >
                        <ListItemDecorator>{subpage.icon}</ListItemDecorator>
                        <ListItemContent>{subpage.name}</ListItemContent>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Toggler>
            ) : (
              <ListItem key={page.name}>
                <ListItemButton
                  selected={pathname === page.route}
                  component={Link}
                  to={page.route}
                >
                  <ListItemDecorator>{page.icon}</ListItemDecorator>
                  <ListItemContent>
                    <b>{page.name}</b>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          <ListItem>
            <ListItemButton
              component="a"
              href="https://www.greensighttech.com/contact"
            >
              <SupportRoundedIcon />
              Support
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton component={Link} to="/settings">
              <SettingsRoundedIcon />
              Settings
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Sheet>
  );
}
