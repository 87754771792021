import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Link as MuiLink,
} from '@mui/joy';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import { DateTime } from 'luxon';
import { Link, useNavigate } from 'react-router-dom';

import { usePrevQuotesTableStore } from '../../../stores/prevQuotesTable.store';

import type { QuoteDetail } from '@backend/types';

export const formatDate = (dateString: string) => {
  const utcDate = DateTime.fromFormat(dateString, 'yyyy-MM-dd HH:mm:ss', {
    zone: 'utc',
  });
  const localDate = utcDate.setZone(DateTime.local().zoneName);

  if (localDate.hasSame(DateTime.local(), 'day')) {
    return `Today at ${localDate.toFormat('h:mm a')}`;
  } else if (localDate.hasSame(DateTime.local().minus({ days: 1 }), 'day')) {
    return `Yesterday at ${localDate.toFormat('h:mm a')}`;
  } else {
    return localDate.toFormat("MM/dd/yyyy 'at' h:mm a");
  }
};

export default function PrevQuotesRows() {
  const navigate = useNavigate();

  const [setOpenDeleteDialog, setDeleteRow, quotes] = usePrevQuotesTableStore(
    (state) => [state.setOpenDeleteDialog, state.setDeleteRow, state.quotes]
  );

  const columns = (row: QuoteDetail) => [
    {
      content: (
        <Chip
          variant="soft"
          size="sm"
          sx={{
            textTransform: 'capitalize',
          }}
          startDecorator={
            row.status === 'completed' ? <CheckRoundedIcon /> : <EditIcon />
          }
          color={row.status === 'completed' ? 'success' : 'neutral'}
        >
          {row.status}
        </Chip>
      ),
    },
    {
      content: (
        <Typography fontWeight="bold" noWrap>
          <MuiLink component={Link} to={`/quote/${row.id}/edits`}>
            {row.quoteName}
          </MuiLink>
        </Typography>
      ),
    },
    {
      content: <Typography noWrap>{row.quoteDescription}</Typography>,
    },
    {
      content: (
        <Typography level="body-xs" noWrap>
          {formatDate(row.updatedAt)}
        </Typography>
      ),
    },
    {
      content: <Typography noWrap>{row.estValue ?? '-'}</Typography>,
    },
    {
      content: (
        <Dropdown>
          <MenuButton
            slots={{ root: IconButton }}
            slotProps={{
              root: { size: 'sm', 'data-testid': 'quote-row-menu' },
            }}
          >
            <MoreHorizIcon />
          </MenuButton>
          <Menu>
            <MenuItem
              onClick={() => {
                navigate(
                  `/quote/${row.id}/${row.status === 'completed' ? 'pricing' : 'edits'}`
                );
              }}
            >
              <EditIcon />
              Edit
            </MenuItem>
            <MenuItem
              color="danger"
              onClick={() => {
                setDeleteRow(row);
                setOpenDeleteDialog(true);
              }}
            >
              <DeleteIcon />
              Delete
            </MenuItem>
          </Menu>
        </Dropdown>
      ),
    },
  ];

  return (
    <tbody>
      {quotes.map((row) => (
        <tr key={row.id}>
          {columns(row).map((column, index) => (
            <td key={index}>{column.content}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}
