export const isSameFile = (file1: File, file2: File): boolean => {
  return (
    file1.name === file2.name &&
    file1.size === file2.size &&
    file1.lastModified === file2.lastModified
  );
};

export const getBlob = async (fileUri: string) => {
  const resp = await fetch(fileUri);
  const imageBody = await resp.blob();
  return imageBody;
};

export const uploadFile = async (
  uploadUrl: string,
  body: Blob,
  contentType: string,
  onProgress?: (progress: number) => void,
  registerCancelFunction?: (cancelUpload: () => void) => void
) => {
  return new Promise<void>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadUrl);

    xhr.setRequestHeader('Content-Type', contentType);

    xhr.upload.onprogress = (event) => {
      onProgress && onProgress((event.loaded / event.total) * 100);
    };

    registerCancelFunction && registerCancelFunction(() => xhr.abort());

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve();
      } else {
        reject(new Error('Upload failed: ' + xhr.statusText));
      }
    };

    xhr.onerror = () => reject(new Error('Upload failed: Network Error'));
    xhr.send(body);
  });
};

export const uploadImage = async (uploadUrl: string, data: string) => {
  const body = await getBlob(data);

  const response = await uploadFile(uploadUrl, body, 'image/jpeg');
  return response;
};

export const uploadCSV = async (
  uploadUrl: string,
  data: File,
  onProgress?: (progress: number) => void,
  registerCancelFunction?: (cancelUpload: () => void) => void
) => {
  const response = await uploadFile(
    uploadUrl,
    data,
    'text/csv',
    onProgress,
    registerCancelFunction
  );
  return response;
};
