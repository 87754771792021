import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { Link } from '@mui/joy';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Stepper from '@mui/joy/Stepper';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStepperStore } from '../../../stores/stepper.store';

import type { StepOption } from '../../../stores/stepper.store';

interface NewQuoteStepperProps {
  finalized: boolean;
}

const NewQuoteStepper = ({ finalized }: NewQuoteStepperProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const stepsConfig = [
    {
      key: 'details',
      testId: 'details',
      label: 'Quote Details',
      icon: <InfoOutlinedIcon />,
      route: (id: string) => (id ? `/quote/${id}/details` : '/quote/new-quote'),
    },
    {
      key: 'import',
      testId: 'import',
      label: 'Import Items',
      icon: <AddOutlinedIcon />,
      route: (id: string) => `/quote/${id}/import`,
    },
    {
      key: 'edits',
      testId: 'edits',
      label: 'Make Edits',
      icon: <ModeEditOutlinedIcon />,
      route: (id: string) => `/quote/${id}/edits`,
    },
    {
      key: 'pricing',
      testId: 'pricing',
      label: 'Pricing',
      icon: <AttachMoneyOutlinedIcon />,
      route: (id: string) => `/quote/${id}/pricing`,
    },
  ];

  const [setActiveStep, activeStep] = useStepperStore((state) => [
    state.setActiveStep,
    state.activeStep,
  ]);

  // useEffect to set the active step based on the current URL path
  useEffect(() => {
    const path = location.pathname;
    if (path.includes('/new-quote') || path.includes('/details')) {
      setActiveStep('details');
    } else if (path.includes('/import')) {
      setActiveStep('import');
    } else if (path.includes('/edits')) {
      setActiveStep('edits');
    } else if (path.includes('/pricing')) {
      setActiveStep('pricing');
    }
  }, [location, setActiveStep]);

  const currentStep = stepsConfig.findIndex((step) => step.key === activeStep);

  return (
    <Stepper
      size="lg"
      sx={{
        width: '100%',
        '--StepIndicator-size': '3rem',
        '--Step-connectorInset': '5px',
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 4,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'primary.300',
            color: 'primary.300',
          },
          '&::after': {
            bgcolor: 'primary.300',
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: 'currentColor',
          },
        },
        [`& .${stepClasses.disabled} *`]: {
          color: 'neutral.outlinedDisabledColor',
        },
      }}
    >
      {stepsConfig.map((step, index) => {
        const isCompleted = currentStep > index;
        const isActive = currentStep === index;

        const canNavigate = currentStep >= index && !finalized;

        return (
          <Step
            key={step.key}
            data-key={step.key}
            data-testid={step.testId}
            data-active={isActive}
            data-completed={isCompleted}
            completed={isCompleted}
            active={isActive}
            onClick={() => {
              if (!canNavigate) return;
              const id = location.pathname.split('/')[2]; // Extract the quote ID from the current URL
              navigate(step.route(id)); // Navigate to the corresponding route
              setActiveStep(step.key as StepOption);
            }}
            indicator={
              <StepIndicator
                variant={isActive ? 'solid' : 'outlined'}
                sx={(theme) => ({
                  backgroundColor: isActive ? 'primary.500' : 'white',
                  [theme.getColorSchemeSelector('dark')]: {
                    backgroundColor: isActive ? 'primary.500' : 'black',
                  },
                  color: isActive
                    ? 'white'
                    : canNavigate
                      ? 'primary.200'
                      : 'neutral.400',
                  borderColor: isActive
                    ? 'currentColor'
                    : canNavigate
                      ? 'primary.200'
                      : 'neutral.400',
                })}
              >
                {step.icon}
              </StepIndicator>
            }
          >
            <Link
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'lg',
                fontSize: '0.75rem',
                letterSpacing: '0.5px',
                color: canNavigate
                  ? isActive
                    ? 'primary.600'
                    : 'neutral.400'
                  : 'neutral.600',
                cursor: canNavigate ? 'pointer' : 'default',
                '&:hover, &:focus': {
                  textDecoration: canNavigate ? 'default' : 'none',
                },
              }}
              aria-disabled={canNavigate ? 'true' : 'false'}
            >
              {step.label}
            </Link>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default NewQuoteStepper;
