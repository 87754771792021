import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import ReplayIcon from '@mui/icons-material/Replay';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  Typography,
  useTheme,
} from '@mui/joy';
import { trpc, uploadCSV } from '@shared';
import { TRPCError } from '@trpc/server';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useQuoteStore } from '../../../../stores/quote.store';

import type { PendingFile } from '../../../../stores/quote.store';

interface ImportItemsStepFileCardProps {
  pendingFile: PendingFile;
}

export default function ImportItemsStepFileCard({
  pendingFile,
}: ImportItemsStepFileCardProps) {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // const utils = trpc.useUtils();
  const putImport = trpc.imports.putImport.useMutation();
  const processImport = trpc.imports.processImport.useMutation();
  const deleteImport = trpc.imports.deleteImport.useMutation();

  const [deleteFile, updateFileUploadedStatus] = useQuoteStore((state) => [
    state.deleteFile,
    state.updateFileUploadedStatus,
  ]);

  // fileId will be '' if the file is not uploaded, or the id of the uploaded file
  const cancelUploadFn = useRef<() => void>();

  const theme = useTheme();

  const uploadFile = useCallback(async () => {
    setProgress(0);
    setError(null);

    if (pendingFile.uploaded && error === null) {
      setProgress(100);
      return;
    }

    try {
      const { id: uploadId, url } = await putImport.mutateAsync();
      pendingFile.uploadId = uploadId;

      await uploadCSV(url, pendingFile.file, setProgress, (fn) => {
        cancelUploadFn.current = fn;
      });

      await processImport.mutateAsync({ id: pendingFile.uploadId });

      updateFileUploadedStatus(pendingFile, true);

      // TODO: Replace this invalidation with the correct route for quote items
      // void utils.devices.getDevices.invalidate();
    } catch (err) {
      if (err instanceof TRPCError && err.code === 'PARSE_ERROR') {
        setError(err.message);
      } else {
        setError('An upload error occurred, please try again later');
      }
      updateFileUploadedStatus(pendingFile, false);
    }
  }, [pendingFile]);

  useEffect(() => {
    uploadFile();
  }, [pendingFile]);

  const handleCancel = () => {
    try {
      if (cancelUploadFn.current !== undefined && error === null) {
        cancelUploadFn.current();
      }
      deleteFile(pendingFile);
    } catch (err) {
      setError('An error occurred, please try again later');
      return;
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteImport.mutateAsync({ id: pendingFile.uploadId });
      deleteFile(pendingFile);
    } catch (err) {
      setError('An error occurred, please try again later');
      return;
    }
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        p: 1,
        border: '2px solid black',
        borderRadius: '10px',
        borderColor: error
          ? theme.palette.danger[600]
          : theme.palette.neutral[700],
        bgcolor: error
          ? theme.palette.danger[400]
          : theme.palette.background.body[100],
      })}
      data-testid="import-items-step-file-card"
    >
      <DescriptionIcon
        sx={{ height: 28, width: 28, mt: 1.5, mr: 2, ml: 1 }}
        htmlColor={theme.palette.neutral[error ? 600 : 400]}
      />
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0,
          width: '100%',
          p: 0,
          border: 0,
          bgcolor: 'transparent',
        }}
      >
        <Typography
          sx={(theme) => ({ color: theme.palette.neutral[error ? 600 : 300] })}
          overflow="hidden"
          height={22}
          data-testid="import-items-step-file-card-file-name"
        >
          {pendingFile.file.name}
        </Typography>
        <Typography
          sx={(theme) => ({ color: theme.palette.neutral[error ? 700 : 500] })}
          level="body-sm"
          data-testid="import-items-step-file-card-file-size"
        >
          {formatFileSize(pendingFile.file.size)}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          {error === null ? (
            <>
              <LinearProgress
                determinate
                value={progress}
                sx={{ width: '100%', borderRadius: '10px', mr: 1 }}
                data-testid="import-items-step-file-card-progress"
              />
              <Typography
                level="body-sm"
                data-testid="import-items-step-file-card-percentage"
              >
                {progress + '%'}
              </Typography>
            </>
          ) : (
            <>
              <WarningAmberOutlinedIcon
                sx={{ mr: 1, fontSize: 20 }}
                htmlColor={theme.palette.danger[600]}
              />
              <Typography
                textColor="danger.500"
                data-testid="import-items-step-file-card-error"
                fontSize={14}
                overflow="scroll"
                height={20}
              >
                {error}
              </Typography>
            </>
          )}
        </Box>
      </Card>
      {error && (
        <IconButton
          onClick={uploadFile}
          sx={{
            height: 'min-content',
            mt: 1.2,
            ml: 1,
            p: 0,
            bgcolor: theme.palette.danger[400],
            ':hover': {
              bgcolor: theme.palette.danger[300],
              border: `1px solid ${theme.palette.danger[500]}`,
            },
            ':active': {
              bgcolor: theme.palette.danger[400],
            },
          }}
          loading={progress !== 100}
        >
          <ReplayIcon
            data-testid="import-items-step-file-card-replay-icon"
            htmlColor={theme.palette.neutral[600]}
            sx={{ height: 28, width: 28 }}
          />
        </IconButton>
      )}
      <IconButton
        onClick={progress !== 100 ? handleCancel : handleDelete}
        sx={{
          height: 'min-content',
          mt: 1.2,
          p: 0,
          bgcolor: error
            ? theme.palette.danger[400]
            : theme.palette.background.body[900],
          ':hover': {
            bgcolor: error
              ? theme.palette.danger[300]
              : theme.palette.background.level2,
            border: `1px solid ${error ? theme.palette.danger[500] : theme.palette.neutral[700]}`,
          },
          ':active': {
            bgcolor: error
              ? theme.palette.danger[400]
              : theme.palette.background.level1,
          },
        }}
        loading={loading}
      >
        {progress !== 100 ? (
          <CloseIcon
            data-testid="import-items-step-file-card-close-icon"
            htmlColor={theme.palette.neutral[error ? 600 : 400]}
            sx={{ height: 28, width: 28 }}
          />
        ) : (
          <DeleteIcon
            data-testid="import-items-step-file-card-delete-icon"
            htmlColor={theme.palette.neutral[error ? 600 : 400]}
            sx={{ height: 28, width: 28 }}
          />
        )}
      </IconButton>
    </Box>
  );
}

const formatFileSize = (size: number): string => {
  if (size < 1024) return `${size} B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} kB`;
  if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(2)} mB`;
  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} gB`;
};
