import { Popper } from '@mui/base/Popper';
import {
  Autocomplete,
  AutocompleteListbox,
  AutocompleteOption,
  createFilterOptions,
  Typography,
} from '@mui/joy';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { createContext, forwardRef, useContext } from 'react';
import { FixedSizeList } from 'react-window';

import type { CategoryItem } from '@backend/types';
import type { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import type { ListChildComponentProps } from 'react-window';

type RowData = [
  Omit<React.HTMLAttributes<HTMLLIElement>, 'color'>,
  CategoryItem,
  {
    text: string;
    highlight: boolean;
  }[],
];

function renderRow(props: ListChildComponentProps<Array<RowData>>) {
  const { data, index, style } = props;
  const dataSet = data[index];

  return (
    <AutocompleteOption {...dataSet[0]} style={style}>
      <Typography level="inherit" sx={{ paddingLeft: 2 * dataSet[1].depth }}>
        {dataSet[2].map((part, idx) => (
          <Typography
            key={idx}
            {...(part.highlight && {
              color: 'primary',
              fontWeight: 'lg',
            })}
          >
            {part.text}
          </Typography>
        ))}
      </Typography>
    </AutocompleteOption>
  );
}

const OuterElementContext = createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return (
    <AutocompleteListbox
      {...props}
      {...outerProps}
      component="div"
      ref={ref}
      sx={{
        '& ul': {
          padding: 0,
          margin: 0,
          flexShrink: 0,
        },
      }}
    />
  );
});

// Adapter for react-window
const ListboxComponent = forwardRef<
  HTMLDivElement,
  {
    anchorEl: any;
    open: boolean;
    modifiers: any[];
  } & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, anchorEl, open, modifiers, ...other } = props;
  const itemData: Array<any> = [];
  (
    children as [Array<{ children: Array<React.ReactElement> | undefined }>]
  )[0].forEach((item) => {
    if (item) {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  });

  const itemCount = itemData.length;
  const itemSize = 40;

  return (
    <Popper
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      modifiers={modifiers}
      style={{ zIndex: 1300 }}
    >
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          height={itemSize * 8}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Popper>
  );
});

interface WindowedAutocompleteProps<T extends FieldValues> {
  options: readonly CategoryItem[];
  placeholder: string;
  field: Path<T>;
  register: UseFormRegister<T>;
}

const WindowedAutocomplete = <T extends FieldValues>({
  options,
  placeholder,
  field,
  register,
}: WindowedAutocompleteProps<T>) => {
  return (
    <Autocomplete
      disableListWrap
      placeholder={placeholder}
      slots={{
        listbox: ListboxComponent,
      }}
      filterOptions={createFilterOptions({
        stringify: (option) => option.matchTerms.join('//'),
      })}
      getOptionLabel={(option) => option.name}
      options={options}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.name, inputValue);
        const parts = parse(option.name, matches);
        const rowData: RowData = [props, option, parts];

        return rowData as React.ReactNode;
      }}
      {...register(field)}
    />
  );
};

export default WindowedAutocomplete;
