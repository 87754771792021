import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';

import QuoteDetailsStep from '../../../components/Quote/DetailsStep/QuoteDetailsStep';
import NewQuoteStepper from '../../../components/Quote/Stepper/NewQuoteStepper';

const NewQuote = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        width: '100%',
        maxWidth: 1500,
        margin: '0 auto',
      }}
    >
      <NewQuoteStepper finalized={false} />
      <Card sx={{ width: '100%' }}>
        <QuoteDetailsStep />
      </Card>
    </Box>
  );
};

export default NewQuote;
