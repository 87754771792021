import { zodResolver } from '@hookform/resolvers/zod';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Box,
  Typography,
  Modal,
  ModalDialog,
  ModalClose,
  CircularProgress,
  Alert,
  Divider,
  Button,
} from '@mui/joy';
import { trpc, uploadFile } from '@shared';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import FormInput from '../../../../FormInput/FormInput';

import type { SubmitHandler } from 'react-hook-form';

const formSchema = z.object({
  pictureUrl: z.string().url(),
});

type Form = z.infer<typeof formSchema>;

interface ProfilePictureUploadDialogProps {
  open: boolean;
  onClose: () => void;
}

const ProfilePictureUploadDialog = ({
  open,
  onClose,
}: ProfilePictureUploadDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState(false);

  const utils = trpc.useUtils();

  const updateProfile = trpc.session.updatePersonalInfo.useMutation();
  const pfpUrl = trpc.session.getSignedPfpUploadUrl.useMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    resolver: zodResolver(formSchema),
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/gif': ['.gif'],
    },
    multiple: false,
    onDrop: async (acceptedFile) => {
      setLoading(true);
      setError(null);
      setSuccess(false);
      try {
        const { uploadUrl, hostedUrl } = await pfpUrl.mutateAsync();
        await uploadFile(uploadUrl, acceptedFile[0], acceptedFile[0].type);

        await onSubmit({
          pictureUrl: hostedUrl,
        });
      } catch (err) {
        setLoading(false);
        setError((err as any).message);
      }
    },
  });

  const handleClose = () => {
    setLoading(false);
    setError(null);
    setSuccess(false);
    onClose();
  };

  const onSubmit: SubmitHandler<Form> = async (data) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      await updateProfile.mutateAsync({ pictureUrl: data.pictureUrl });
      setLoading(false);
      setSuccess(true);
      void utils.session.userInfo.invalidate();
      void utils.session.sessionData.invalidate();
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (err) {
      setLoading(false);
      setError((err as any).message);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalDialog
        aria-labelledby="profile-picture-dialog-title"
        aria-describedby="profile-picture-dialog-description"
      >
        <ModalClose
          data-testid="profile-picture-dialog-close"
          onClick={handleClose}
        />
        <Typography id="profile-picture-dialog-title" component="h2">
          Upload Profile Picture
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box
            {...getRootProps()}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              border: '2px dashed',
              padding: '20px',
              borderRadius: '8px',
              cursor: 'pointer',
              position: 'relative',
            }}
          >
            <>
              <input
                {...getInputProps()}
                disabled={success}
                data-testid="profile-picture-dialog-input"
              />
              <UploadIcon
                sx={{ fontSize: 40 }}
                data-testid="profile-picture-dialog-upload-icon"
              />
              <Typography sx={{ marginTop: '16px' }}>
                Drag and drop an image here, or click to select an image
              </Typography>
              <Typography sx={{ marginTop: '8px' }} color="neutral">
                Supported formats: PNG, JPEG, GIF
              </Typography>
            </>
          </Box>
        )}
        <Divider />
        <Typography level="body-sm">
          Or enter a URL to a profile picture
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            field="pictureUrl"
            fieldLabel="Profile Picture URL"
            register={register}
            errors={errors}
            inputProps={{
              sx: { '--Input-decoratorChildHeight': '34px' },
              endDecorator: (
                <Button
                  variant="solid"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={success}
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  Submit
                </Button>
              ),
            }}
          />
        </form>
        {error != null && (
          <Alert sx={{ marginTop: '16px' }} color="danger">
            {error}
          </Alert>
        )}
        {success && (
          <Alert sx={{ marginTop: '16px' }} color="success">
            Profile picture changed successfully! Refresh the page.
          </Alert>
        )}
      </ModalDialog>
    </Modal>
  );
};

export default ProfilePictureUploadDialog;
