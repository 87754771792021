import { Box } from '@mui/joy';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';

import Sidebar from './components/Sidebar/Sidebar';
import TopBar from './components/TopBar/TopBar';
import { usePageStore } from './stores/page.store';

interface AppProps {
  children: React.ReactNode;
}

export default function App({ children }: AppProps) {
  const page = usePageStore((state) => state.page);

  const theme = extendTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: {
            50: '#99E2B4',
            100: '#88D4AB',
            200: '#78C6A3',
            300: '#67B99A',
            400: '#56AB91',
            500: '#469D89',
            600: '#358F80',
            700: '#248277',
            800: '#14746F',
            900: '#036666',
          },
        },
      },
      dark: {
        palette: {
          primary: {
            50: '#99E2B4',
            100: '#88D4AB',
            200: '#78C6A3',
            300: '#67B99A',
            400: '#56AB91',
            500: '#469D89',
            600: '#358F80',
            700: '#248277',
            800: '#14746F',
            900: '#036666',
          },
        },
      },
    },
  });

  return (
    <CssVarsProvider
      defaultMode="system"
      theme={theme}
      disableTransitionOnChange
    >
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
        <Sidebar />
        <TopBar title={page} />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: 'calc(12px + var(--Header-height))',
            ml: { xs: 0, md: 'var(--Sidebar-width)' },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          {children}
        </Box>
      </Box>
    </CssVarsProvider>
  );
}
