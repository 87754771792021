export const ebayConditions: Record<string, number> = {
  New: 1000,
  'Open box': 1500,
  'Certified refurbished': 2000,
  'Seller refurbished': 2500,
  'Like New': 2750,
  Used: 3000,
  'For parts or not working': 7000,
};

export interface EbayCategory {
  id: number;
  subcategories?: Record<string, EbayCategory>;
}

export interface CategoryItem {
  id: number;
  name: string;
  depth: number;
  parentId: number | null;
  matchTerms: string[];
}

/**
 * eBay electronics categories as a dictionary
 */
export const ebayElectronicCategories: Record<string, EbayCategory> = {
  'Cameras & Photo': {
    id: 625,
    subcategories: {
      'Vintage Movie & Photography': {
        id: 3326,
        subcategories: {
          'Other Vintage Photography': { id: 711 },
          'Vintage Parts & Accessories': { id: 4702 },
          'Vintage Projectors & Screens': { id: 11722 },
          'Vintage Lenses': { id: 101642 },
          'Vintage Cameras': {
            id: 101643,
            subcategories: {
              'Stereo Cameras': { id: 4692 },
              'Other Vintage Cameras': { id: 4701 },
              'Box Cameras': { id: 11716 },
              'Folding Cameras': { id: 11717 },
              'Instant Cameras': { id: 11718 },
              'Movie Cameras': { id: 11719 },
              'SLR Cameras': { id: 11720 },
              'TLR Cameras': { id: 11721 },
              '35mm Cameras': { id: 98923 },
              'Rangefinder Cameras': { id: 98924 },
              'Subminiature Cameras': { id: 101644 },
              'Disc Cameras': { id: 109092 },
            },
          },
          'Vintage Film': { id: 167943 },
          'Vintage Manuals & Guides': { id: 170067 },
        },
      },
      'Camera Manuals & Guides': { id: 4684 },
      Camcorders: { id: 11724 },
      'Camera, Drone & Photo Accessories': {
        id: 15200,
        subcategories: {
          'Memory Cards': { id: 18871 },
          'Battery Grips': { id: 29967 },
          'Accessory Bundles': { id: 29994 },
          'Camera & Camcorder Lights': { id: 43440 },
          'Memory Card Readers & Adapters': { id: 43448 },
          'Memory Card Cases': { id: 50503 },
          'Underwater Cases & Housings': { id: 50506 },
          'LCD Hoods': { id: 50508 },
          'Straps & Hand Grips': { id: 64343 },
          'Remotes & Shutter Releases': { id: 64345 },
          'Camcorder Tapes & Discs': { id: 67339 },
          Microphones: { id: 83857 },
          'Cases, Bags & Covers': { id: 107894 },
          'Cables & Adapters': { id: 155707 },
          Batteries: { id: 162045 },
          'Chargers & Cradles': { id: 162046 },
          'Cleaning Equipment & Kits': { id: 162053 },
          'Other Camera & Photo Accs': { id: 162480 },
          'Screen Protectors': { id: 167928 },
          'Viewfinders & Eyecups': { id: 167930 },
          'Photo Albums & Storage': {
            id: 182075,
            subcategories: {
              'Other Photo Albums & Storage': { id: 29953 },
              'Photo Albums & Boxes': { id: 73459 },
              'Photo Pages, Sheets & Sleeves': { id: 182076 },
            },
          },
          'Camera Drone Accessories': { id: 182969 },
        },
      },
      'Video Production & Editing': {
        id: 21162,
        subcategories: {
          'Other Production & Editing': { id: 3319 },
          'Video Recorders & Players': { id: 21168 },
          'Distribution Switchers & Routers': { id: 21169 },
          'Video Monitors': { id: 21517 },
          'Lighting for Video Production': { id: 33513 },
          'Video Production Accessories': { id: 51541 },
          'Audio for Video': { id: 100501 },
          'Video Servers & Streaming Dev.': { id: 184620 },
          'Signal Generators': { id: 184622 },
          'Production Switchers': { id: 184623 },
          'Converters, Scalers & Processors': { id: 184648 },
          Extenders: { id: 185014 },
          'Production Controllers & Panels': { id: 185015 },
          'Waveform Monitors': { id: 185016 },
        },
      },
      'Other Cameras & Photo': { id: 27432 },
      'Binoculars & Telescopes': {
        id: 28179,
        subcategories: {
          'Telescope Parts & Accessories': {
            id: 74922,
            subcategories: {
              'Telescope Eyepieces & Lenses': { id: 29954 },
              'Telescope Cases & Bags': { id: 32943 },
              'Telescope Filters': { id: 74923 },
              'Telescope Mirrors & Prisms': { id: 74925 },
              'Other Telescope Parts': { id: 74926 },
              'Telescope Adapters': { id: 151236 },
              'Telescope Mounts': { id: 167938 },
            },
          },
          Telescopes: { id: 74927 },
          'Binoculars & Monoculars': { id: 83880 },
          'Other Binoculars & Telescopes': { id: 162044 },
          'Binocular Cases & Accessories': { id: 162485 },
        },
      },
      'Lighting & Studio': {
        id: 30078,
        subcategories: {
          'Continuous Lighting': {
            id: 19590,
            subcategories: {
              'Other Continuous Lighting': { id: 3860 },
              'Lighting Kits': { id: 19591 },
              'Lighting Units': { id: 69341 },
              'Light Bulbs & Tubes': { id: 172516 },
            },
          },
          'Stock Photo Collections': { id: 21198 },
          'Props & Stage Equipment': { id: 30085 },
          'Other Lighting & Studio': { id: 30088 },
          'Background Material': { id: 79003 },
          'Light Controls & Modifiers': {
            id: 79007,
            subcategories: {
              'Studio Reflectors': { id: 30082 },
              'Softboxes & Diffusers': { id: 79008 },
              'Studio Umbrellas': { id: 79009 },
              'Other Light Controls & Mods': { id: 79010 },
            },
          },
          'Light Stands & Booms': { id: 88665 },
          'Light Stand & Boom Accessories': { id: 88667 },
          'Light Meters': { id: 88668 },
          'Flash Lighting': {
            id: 124848,
            subcategories: {
              'Slaves & Trigger Systems': { id: 30086 },
              'Other Flash Lighting': { id: 30087 },
              'Flash Lighting Kits': { id: 87635 },
              'Monolight Flashes': { id: 87637 },
              'Flash Heads & Power Supplies': { id: 124850 },
              'Flash Bulbs & Tubes': { id: 155817 },
            },
          },
          'Shooting Tables & Light Tents': { id: 151226 },
        },
      },
      'Tripods & Supports': {
        id: 30090,
        subcategories: {
          'Tripods & Monopods': { id: 30093 },
          'Tripod Heads': { id: 30095 },
          'Other Tripods & Supports': { id: 30097 },
          Stabilizers: { id: 172511 },
        },
      },
      'Digital Cameras': { id: 31388 },
      'Mixed Lots': { id: 45089 },
      'Flashes & Flash Accessories': {
        id: 64353,
        subcategories: {
          Flashes: { id: 48515 },
          'Other Flashes & Flash Accs': { id: 64354 },
          'Flash Adapters': { id: 69573 },
          'Flash Brackets': { id: 89161 },
          'Flash Diffusers': { id: 121464 },
          'Sync Cords': { id: 155823 },
        },
      },
      'Film Photography': {
        id: 69323,
        subcategories: {
          Film: { id: 4201 },
          'Movie Cameras': { id: 4689 },
          'Movie Camera Accessories': { id: 4690 },
          'Movie Editing Equipment': { id: 4790 },
          'Film Cameras': { id: 15230 },
          'Slide & Movie Projection': {
            id: 15250,
            subcategories: {
              'Projector Bulbs & Lamps': { id: 15251 },
              'Movie Projectors': { id: 15252 },
              'Slide Projectors': { id: 15256 },
              'Other Slide & Movie Projection': { id: 15257 },
              'Projection Screens & Material': { id: 30089 },
              'Projection Lenses': { id: 83874 },
              'Slide Trays & Carousels': { id: 83876 },
              'Slide Viewers': { id: 83877 },
            },
          },
          'Rangefinder Units & Accs': { id: 29965 },
          'Motor Drives & Winders': { id: 29970 },
          'Film Backs & Holders': { id: 29973 },
          'Lens Boards': { id: 29980 },
          'Other Film Photography': { id: 43479 },
          'Digital Camera Backs': { id: 170066 },
          'Darkroom & Developing': {
            id: 182077,
            subcategories: {
              'Other Darkroom & Developing': { id: 629 },
              'Photographic Paper': { id: 15226 },
              'Film Development Chemicals': { id: 29983 },
              'Enlargement Equipment': { id: 29985 },
              'Enlarging Lenses': { id: 29986 },
              'Safelights & Bulbs': { id: 29990 },
              'Darkroom Timers': { id: 29991 },
              'Processing & Finishing Equip': { id: 29993 },
              'Easels & Negative Carriers': { id: 162048 },
            },
          },
        },
      },
      'Lenses & Filters': {
        id: 78997,
        subcategories: {
          Lenses: { id: 3323 },
          Filters: { id: 15216 },
          'Filter Rings & Holders': { id: 30040 },
          'Lens Adapters, Mounts & Tubes': { id: 30059 },
          'Lens Hoods': { id: 78999 },
          'Other Lenses & Filters': { id: 79000 },
          'Lens Caps': { id: 79001 },
          'Auxiliary/Conversion Lenses': { id: 80390 },
        },
      },
      'Digital Photo Frames': { id: 150044 },
      'Camera Drones': { id: 179697 },
      'Replacement Parts & Tools': {
        id: 182074,
        subcategories: {
          'Film Camera Parts': { id: 30038 },
          'Digital Camera Parts': { id: 64352 },
          'Camcorder Parts': { id: 172513 },
          'Camera Tools & Repair Kits': { id: 172514 },
          'Camera Drone Parts': { id: 260144 },
        },
      },
    },
  },
  'Vehicle Electronics & GPS': {
    id: 3270,
    subcategories: {
      'Other Vehicle Electronics': { id: 1498 },
      'Radar & Laser Detectors': { id: 14935 },
      'Car Audio & Video Installation': {
        id: 32806,
        subcategories: {
          'Power & Speaker Wire': { id: 32810 },
          'Connectors & Terminals': { id: 50549 },
          'Distribution Blocks': { id: 50550 },
          'Fuses & Fuse Holders': { id: 50551 },
          'Other Car A/V Installation': { id: 50552 },
          'Speaker/Sub. Enclosures': { id: 50564 },
          'Wire Harnesses': { id: 64578 },
          'Speaker/Sub. Grills & Accs': { id: 67773 },
          'Amplifier Kits': { id: 71527 },
          Capacitors: { id: 75386 },
          'Dashboard Installation Kits': { id: 85806 },
          'Interconnect Cables': { id: 94844 },
        },
      },
      'Car Video': {
        id: 48604,
        subcategories: {
          'Other Car Video': { id: 48605 },
          'Car Monitors w/o Player': { id: 48606 },
          'Video In-Dash Units w/GPS': { id: 79834 },
          'Video In-Dash Units w/o GPS': { id: 79835 },
          'Rear View Monitors/Cams & Kits': { id: 149976 },
          'Car Monitors w/Built-in Player': { id: 175712 },
        },
      },
      'Car Alarms & Security': {
        id: 48610,
        subcategories: {
          'Other Car Alarms & Security': { id: 60203 },
          'Replacement Remotes': { id: 71530 },
          'Relays & Sensors': { id: 75389 },
          'Car Alarms & Security Systems': { id: 75390 },
          'Car Keys & Transponders': { id: 94830 },
        },
      },
      'Car Electronics Accessories': {
        id: 60207,
        subcategories: {
          'Audio/Video Remotes': { id: 12040 },
          'Radio Antennas': { id: 50553 },
          'Power Inverters': { id: 58020 },
          Adapters: { id: 60208 },
          'Replacement Faceplates': { id: 71535 },
          'Car Lighting': { id: 71536 },
          'Remote Start & Entry Systems': { id: 175713 },
          'Bluetooth, Handsfree Car Kits': { id: 175714 },
          'Other Car Electronics Accs': { id: 175715 },
        },
      },
      '12-Volt Portable Appliances': { id: 73335 },
      'GPS Accessories & Tracking': {
        id: 139836,
        subcategories: {
          'Other GPS Accs & Tracking': { id: 58049 },
          'GPS Antennas': { id: 73348 },
          'GPS Holders & Mounts': { id: 73353 },
          'Tracking Devices': { id: 73362 },
          'GPS Software & Maps': { id: 139837 },
          'GPS Cases & Skins': { id: 168103 },
          'GPS Screen Protectors': { id: 168104 },
          'GPS Chargers & Batteries': { id: 175726 },
        },
      },
      'GPS Units': { id: 156955 },
      'Marine Audio': { id: 168105 },
      'Car Audio': {
        id: 175716,
        subcategories: {
          'Car Speakers & Speaker Systems': { id: 14936 },
          'Car Amplifiers': { id: 18795 },
          'Car Subwoofers': { id: 18805 },
          'Car CD Changers': { id: 39746 },
          'Car Audio In-Dash Units': { id: 39754 },
          'Signal Processors': { id: 79839 },
          'Other Car Audio': { id: 175717 },
        },
      },
    },
  },
  'Camping Tableware': { id: 181383 },
  'Surveillance & Smart Home Electronics': {
    id: 185067,
    subcategories: {
      'Home Surveillance': {
        id: 48633,
        subcategories: {
          'Security Cameras': { id: 48638 },
          'Dummy Cameras': { id: 75395 },
          'Sensors & Motion Detectors': { id: 115940 },
          'CCTV Systems': { id: 159909 },
          'Home Surveillance Parts & Accessories': {
            id: 185048,
            subcategories: {
              'Surveillance Monitors & Displays': { id: 48635 },
              'Security Camera Cables & Adapters': { id: 48637 },
              'Surveillance Video Processors & Switchers': { id: 75397 },
              'Surveillance DVRs & NVRs': { id: 88754 },
              'Other Surveillance Parts & Accessories': { id: 175725 },
              'Security Camera Brackets & Mounts': { id: 185049 },
              'Security Camera Cases & Housings': { id: 185050 },
              'Security Camera Microphones': { id: 185051 },
              'Surveillance Illuminators': { id: 185052 },
              'Security Camera Controllers & Joysticks': { id: 185116 },
              'Security Camera Lenses': { id: 185117 },
              'Security Camera Power Supplies': { id: 185118 },
            },
          },
          'IP & Smart Security Camera Systems': { id: 185053 },
        },
      },
      'Smart Speakers, Hubs & Accessories': {
        id: 185034,
        subcategories: {
          'Smart Speakers': { id: 184435 },
          'Smart Speaker Accessories': { id: 185043 },
          'Smart Home Hubs': { id: 185068 },
          'Smart Home Hub Accessories': { id: 185119 },
          'Smart Speaker Parts': { id: 260301 },
          'Smart Home Hub Parts': { id: 260302 },
        },
      },
      'Smart Plugs': { id: 185061 },
      'Other Smart Home Electronics': { id: 185065 },
    },
  },
  'Major Appliances': {
    id: 20710,
    subcategories: {
      'Other Major Appliances': { id: 20715 },
      'Washers & Dryers': {
        id: 42231,
        subcategories: {
          Dryers: { id: 71254 },
          'Washing Machines': { id: 71256 },
          'Washer Dryer Combinations & Sets': { id: 71257 },
        },
      },
      'Ranges & Cooking Appliances': {
        id: 43563,
        subcategories: {
          Cooktops: { id: 71246 },
          'Ranges & Stoves': { id: 71250 },
          'Range Hoods': { id: 71253 },
          'Wall Ovens': { id: 71318 },
        },
      },
      'Refrigerators & Freezers': {
        id: 71258,
        subcategories: {
          Refrigerators: { id: 20713 },
          'Upright & Chest Freezers': { id: 71260 },
          'Mini Fridges': { id: 71262 },
          Kegerators: { id: 155678 },
          'Wine Fridges & Cellars': { id: 177750 },
        },
      },
      Dishwashers: { id: 116023 },
      'Major Appliances Parts & Accessories': {
        id: 260308,
        subcategories: {
          'Cooking Appliance Parts': { id: 43566 },
          'Washer & Dryer Parts': { id: 99697 },
          'Dishwasher Parts': { id: 116026 },
          'Refrigerator & Freezer Parts': { id: 184666 },
          'Cooking Appliance Accessories': { id: 260136 },
        },
      },
    },
  },
  'Video Games & Consoles': {
    id: 1249,
    subcategories: {
      'Video Game Merchandise': { id: 38583 },
      'Video Game Accessories': {
        id: 54968,
        subcategories: {
          'Other Video Game Accessories': { id: 49230 },
          'Controllers & Attachments': { id: 117042 },
          'Motion Sensors & Cameras': { id: 117044 },
          'Memory Cards & Expansion Packs': { id: 117045 },
          'Faceplates, Decals & Stickers': { id: 171668 },
          Batteries: { id: 171813 },
          'Cables & Adapters': { id: 171814 },
          'Hard Drives': { id: 171820 },
          Headsets: { id: 171821 },
          'Accessory Bundles': { id: 171826 },
          'Bags, Skins & Travel Cases': { id: 171831 },
          'Screen Protectors': { id: 171837 },
          Styluses: { id: 171839 },
          'Chargers & Charging Docks': { id: 171858 },
          'Cooling Devices': { id: 171898 },
          'Keyboards & Keypads': { id: 172510 },
          'Mounts & Stands': { id: 182179 },
          'Toys to Life': { id: 182180 },
        },
      },
      'Video Game Consoles': { id: 139971 },
      'Video Games': { id: 139973 },
      'Strategy Guides & Cheats': { id: 156595 },
      'Prepaid Gaming Cards': { id: 156597 },
      'Replacement Parts & Tools': { id: 171833 },
      'Manuals, Inserts & Box Art': { id: 182174 },
      'Original Game Cases & Boxes': { id: 182175 },
      'Mixed Lots': { id: 260000 },
    },
  },
  'PC & Console VR Headsets': { id: 183068 },
  'Cell Phones & Accessories': {
    id: 15032,
    subcategories: {
      'Cell Phones & Smartphones': { id: 9355 },
      'Cell Phone Accessories': {
        id: 9394,
        subcategories: {
          'Cases, Covers & Skins': { id: 20349 },
          Batteries: { id: 20357 },
          'Faceplates, Decals & Stickers': { id: 20373 },
          'Mounts & Holders': { id: 35190 },
          'Other Cell Phone Accessories': { id: 42425 },
          'Manuals & Guides': { id: 43307 },
          'Memory Card Readers & Adapters': { id: 48571 },
          Styluses: { id: 48677 },
          'Screen Protectors': { id: 58540 },
          'Accessory Bundles': { id: 58543 },
          'Signal Boosters': { id: 68030 },
          Headsets: { id: 80077 },
          'Car Speakerphones': { id: 88468 },
          'Memory Cards': { id: 96991 },
          'Straps & Charms': { id: 122962 },
          'Chargers & Cradles': { id: 123417 },
          'Cables & Adapters': { id: 123422 },
          'Audio Docks & Speakers': { id: 132297 },
          Transmitters: { id: 146504 },
          Armbands: { id: 166030 },
          'Port Dust Covers': { id: 179406 },
        },
      },
      PDAs: { id: 38331 },
      'Cell Phone & Smartphone Parts': { id: 43304 },
      'Mixed Lots': { id: 45065 },
      'Display Phones': { id: 136699 },
      'Phone Cards & SIM Cards': {
        id: 146492,
        subcategories: {
          'SIM Cards': { id: 29778 },
          'Phone & Data Cards': { id: 43308 },
          'SIM Card Accessories': { id: 146493 },
        },
      },
      'PDA Accessories': { id: 175744 },
      'Smart Watches': { id: 178893 },
      'Smart Watch Accessories': {
        id: 182064,
        subcategories: {
          'Chargers & Docking Stations': { id: 182065 },
          'Screen Protectors': { id: 182066 },
          'Smart Watch Cases': { id: 182067 },
          'Watch Bands': { id: 182068 },
          'Other Smart Watch Accessories': { id: 182069 },
        },
      },
      'Vintage Cell Phones': { id: 182073 },
    },
  },
  'Computers/Tablets & Networking': {
    id: 58058,
    subcategories: {
      'Other Computers & Networking': { id: 162 },
      'Drives, Storage & Blank Media': {
        id: 165,
        subcategories: {
          'Other Drives, Storage & Media': { id: 167 },
          'Floppy, Zip & Jaz Drives': { id: 169 },
          'CD, DVD & Blu-ray Duplicators': { id: 31509 },
          'Tape & Data Cartridge Drives': { id: 39976 },
          'USB Flash Drives': { id: 51071 },
          'Blank Media & Accessories': {
            id: 80133,
            subcategories: {
              'Other Blank Media & Accs': { id: 11161 },
              'Tapes & Data Cartridges': { id: 73329 },
              'CD, DVD & Blu-ray Discs': { id: 80135 },
              'Floppy, Zip & Jaz Disks': { id: 80136 },
              'Disc Repair & Disc Cleaning': { id: 175664 },
              'Drive Head & Lens Cleaning': { id: 175665 },
              'Media Cases, Sleeves & Wallets': { id: 175666 },
            },
          },
          'CD, DVD & Blu-ray Drives': { id: 131542 },
          'Drive Bay Caddies': { id: 158816 },
          'Drive Enclosures & Docks': { id: 158817 },
          'Hard Drive Duplicators': { id: 175671 },
          'Hard Drives (HDD, SSD & NAS)': {
            id: 182085,
            subcategories: {
              'Internal Hard Disk Drives': { id: 56083 },
              'Network Attached Storage': { id: 106273 },
              'External Hard Disk Drives': { id: 131553 },
              'Solid State Drives': { id: 175669 },
              'Other Hard Drives': { id: 175670 },
            },
          },
        },
      },
      'Manuals & Resources': { id: 3516 },
      'Keyboards, Mice & Pointers': {
        id: 3676,
        subcategories: {
          'Other Keyboards & Mice': { id: 170 },
          'Graphics Tablets/Boards & Pens': { id: 3680 },
          'Mice, Trackballs & Touchpads': { id: 23160 },
          'Keyboards & Keypads': { id: 33963 },
          'Keyboard & Mouse Bundles': { id: 47779 },
          'Remote Controls & Pointers': { id: 51086 },
        },
      },
      'Home Networking & Connectivity': {
        id: 11176,
        subcategories: {
          'Wired Routers': { id: 11183 },
          'Wireless Routers': { id: 44995 },
          'Other Home Networking': { id: 44996 },
          'USB Bluetooth Adapters/Dongles': { id: 44999 },
          'USB Wi-Fi Adapters/Dongles': { id: 45002 },
          Modems: { id: 58297 },
          'Boosters, Extenders & Antennas': { id: 61816 },
          'VoIP Home Phones': { id: 61840 },
          'Powerline Networking': { id: 67262 },
          'VoIP Phone Adapters': { id: 99269 },
          'Modem-Router Combos': { id: 101270 },
          'Wireless Access Points': { id: 175709 },
          'Mobile Broadband Devices': { id: 175710 },
        },
      },
      'Vintage Computing': {
        id: 11189,
        subcategories: {
          'Other Vintage Computing': { id: 4193 },
          'Vintage Manuals & Merchandise': { id: 14906 },
          'Vintage Computers & Mainframes': { id: 162075 },
          'Vintage Parts & Accessories': { id: 175690 },
        },
      },
      Software: {
        id: 18793,
        subcategories: {
          'Other Computer Software': { id: 182 },
          'Web & Desktop Publishing': { id: 185 },
          'Office & Business': { id: 3768 },
          'Education, Language, Reference': { id: 3783 },
          'Operating Systems': { id: 11226 },
          'Image, Video & Audio': { id: 41859 },
          'Hobbies & Leisure': { id: 80015 },
          'Servers, Development & DBMS': { id: 80356 },
          'Personal Finance, Tax & Legal': { id: 158906 },
          'Drivers & Utilities': { id: 158911 },
          'Antivirus & Security': { id: 175689 },
        },
      },
      'Laptop & Desktop Accessories': {
        id: 31530,
        subcategories: {
          'Computer Speakers': { id: 3702 },
          'Laptop Docking Stations': { id: 3709 },
          Webcams: { id: 4616 },
          'Laptop Batteries': { id: 14295 },
          'Mouse Pads & Wrist Rests': { id: 23895 },
          'Laptop Power Adapters/Chargers': { id: 31510 },
          'Laptop Cases & Bags': { id: 31519 },
          'Anti-Theft Locks & Kits': { id: 31529 },
          'Other Laptop & Desktop Accs': { id: 31534 },
          'Laptop Add-On Cards': {
            id: 42190,
            subcategories: {
              'Laptop Network Cards': { id: 42196 },
              'Laptop Port Expansion Cards': { id: 42323 },
              'Laptop Modem Cards': { id: 74953 },
              'Other Laptop Add-On Cards': { id: 96913 },
            },
          },
          'Memory Card Readers & Adapters': { id: 51082 },
          'USB Lights & Gadgets': { id: 67870 },
          'Sound Cards (External)': { id: 75518 },
          Headsets: { id: 80183 },
          'Laptop Cooling Pads': { id: 96915 },
          Microphones: { id: 116309 },
          'Stands, Holders & Car Mounts': { id: 116346 },
          'Keyboard Protectors': { id: 158837 },
          'Screen Protectors': { id: 158846 },
          'Privacy Filters': { id: 170597 },
          'Case Mods, Stickers & Decals': { id: 175677 },
          'Cleaning Equipment & Kits': { id: 175678 },
          'TV Tuner/Video Capture Devices': { id: 175679 },
          'Hard Drive Pouches': { id: 175680 },
        },
      },
      'Power Protection, Distribution': {
        id: 86722,
        subcategories: {
          'Other Power Protection': { id: 20315 },
          'UPS Batteries & Components': { id: 48479 },
          'Power Distribution Units': { id: 86723 },
          'Surge Protectors, Power Strips': { id: 96907 },
          'Uninterruptible Power Supplies': { id: 99265 },
          'Power Inverters': { id: 175687 },
        },
      },
      'Monitors, Projectors & Accs': {
        id: 162497,
        subcategories: {
          Projectors: { id: 25321 },
          'Monitor Mounts & Stands': { id: 51052 },
          Monitors: { id: 80053 },
          'Projector Replacement Parts': { id: 99231 },
          'Monitor Power Supplies': { id: 158845 },
          'Monitor Replacement Parts': { id: 168068 },
          'Other Computer Monitors & Accs': { id: 175688 },
          'Projector Accessories': { id: 260137 },
        },
      },
      'Tablets & eBook Readers': { id: 171485 },
      'Desktops & All-In-Ones': {
        id: 171957,
        subcategories: {
          'PC Desktops & All-In-Ones': { id: 179 },
          'Apple Desktops & All-In-Ones': { id: 111418 },
        },
      },
      'Printers, Scanners & Supplies': {
        id: 171961,
        subcategories: {
          Printers: { id: 1245 },
          'Printer Ink, Toner & Paper': {
            id: 11195,
            subcategories: {
              'Ink Ribbons': { id: 11202 },
              'Ink Cartridges': { id: 16191 },
              'Toner Cartridges': { id: 16204 },
              'Printer Photo Paper': { id: 73452 },
              'Printer Paper': { id: 86728 },
              'Ink Refills & Kits': { id: 99319 },
              'Empty Ink Cartridges': { id: 156545 },
              'Toner Refills & Kits': { id: 175682 },
              'Other Ink, Toner & Paper': { id: 175705 },
            },
          },
          Scanners: { id: 11205 },
          'Printer & Scanner Parts & Accs': {
            id: 182086,
            subcategories: {
              'Feeders & Trays': { id: 11197 },
              'Printer Memory': { id: 16211 },
              'Laser Drums': { id: 51288 },
              'Print Heads': { id: 51328 },
              'Power Supplies': { id: 101356 },
              'Other Printer & Scanner Accs': { id: 170599 },
              'Scanner Parts': { id: 170601 },
              'Cleaning & Repair Kits': { id: 175685 },
              Fusers: { id: 175686 },
              Duplexers: { id: 182087 },
            },
          },
        },
      },
      'Laptops & Netbooks': {
        id: 175672,
        subcategories: {
          'PC Laptops & Netbooks': { id: 177 },
          'Apple Laptops': { id: 111422 },
        },
      },
      'Computer Components & Parts': {
        id: 175673,
        subcategories: {
          'CPUs/Processors': { id: 164 },
          Motherboards: { id: 1244 },
          'Video Capture & TV Tuner Cards': { id: 3761 },
          'Other Components & Parts': { id: 16145 },
          'Graphics/Video Cards': { id: 27386 },
          'Fans, Heat Sinks & Cooling': {
            id: 42000,
            subcategories: {
              'Other Computer Fans & Cooling': { id: 3673 },
              'Thermal Compounds & Supplies': { id: 46322 },
              'Video Card/GPU Cooling': { id: 80151 },
              'CPU Fans & Heat Sinks': { id: 131486 },
              'Computer Case Fans': { id: 131487 },
              'Hard Drive Cooling': { id: 131494 },
              'Fan Grills & Filters': { id: 131495 },
              'Memory & Chipset Cooling': { id: 131502 },
              'Water Cooling': { id: 131503 },
              'Fan, Heat Sink Brackets & Accs': { id: 170078 },
            },
          },
          'Power Supplies': { id: 42017 },
          'Sound Cards (Internal)': { id: 44980 },
          'Motherboard & CPU Combos': { id: 131511 },
          'Power Supply Testers': { id: 168059 },
          'Memory (RAM)': { id: 170083 },
          'Computer Cases & Accessories': {
            id: 175674,
            subcategories: {
              'Computer Cases': { id: 42014 },
              'Case Parts & Accessories': { id: 51064 },
            },
          },
          'Interface/Add-On Cards': {
            id: 182088,
            subcategories: {
              'Network Cards': { id: 20318 },
              'Modem Cards': { id: 44940 },
              'Disk Controllers/RAID Cards': { id: 90715 },
              'Port Expansion Cards': { id: 90718 },
              'Other Interface/Add-On Cards': { id: 90719 },
            },
          },
          'Laptop Replacement Parts': {
            id: 182089,
            subcategories: {
              'Laptop Replacement Keyboards': { id: 31568 },
              'Laptop Screens & LCD Panels': { id: 31569 },
              'Other Laptop Replacement Parts': { id: 168061 },
              'Laptop Housings & Touchpads': { id: 175676 },
            },
          },
          'Motherboard Components & Accs': {
            id: 182090,
            subcategories: {
              'Motherboard Diagnostic Cards': { id: 168060 },
              'Motherboard Backplates': { id: 170080 },
              'Other Motherboard Accessories': { id: 170082 },
              'Motherboard Components': { id: 175668 },
            },
          },
        },
      },
      'Enterprise Networking, Servers': {
        id: 175698,
        subcategories: {
          'Other Enterprise Networking': { id: 11175 },
          'Load Balancers': { id: 51167 },
          'Firewall & VPN Devices': { id: 51168 },
          'Racks, Chassis & Patch Panels': {
            id: 51197,
            subcategories: {
              'Patch Panels': { id: 44992 },
              'Rackmount Cabinets & Frames': { id: 51199 },
              'Rackmount Cases & Chassis': { id: 64061 },
              'Other Ent. Networking Racks': { id: 80216 },
            },
          },
          'Server Components': {
            id: 51240,
            subcategories: {
              'Server Memory (RAM)': { id: 11210 },
              'Server CPUs/Processors': { id: 56088 },
              'Server Power Supplies': { id: 56090 },
              'Disk Controllers/RAID Cards': { id: 56091 },
              'Other Ent. Server Components': { id: 56092 },
              'Server Boards': { id: 71509 },
              'Server Fans & Cooling Systems': { id: 168074 },
            },
          },
          'VoIP Business Phones/IP PBX': { id: 61839 },
          'CSUs/DSUs': { id: 80225 },
          'Directional Antennas': { id: 86720 },
          'Enterprise Routers': { id: 175699 },
          'Servers, Clients & Terminals': {
            id: 175700,
            subcategories: {
              'Computer Servers': { id: 11211 },
              'Print Servers': { id: 64052 },
              'Terminal Servers': { id: 175701 },
              'Thin Clients': { id: 175702 },
              'Other Ent. Clients & Terminals': { id: 175703 },
              'Server Cables & Adapters': { id: 184657 },
              'Server Tray Sleds/Caddies': { id: 184658 },
              'Server Mount Hardware': { id: 184659 },
            },
          },
          'Switches & Hubs': {
            id: 182091,
            subcategories: {
              'Network Hubs': { id: 11180 },
              'Network Switches': { id: 51268 },
              'Switch Modules': { id: 58319 },
              'Switch Power Supplies': { id: 58320 },
              'Other Ent. Switches & Hubs': { id: 58321 },
            },
          },
          'Enterprise Router Components': {
            id: 182092,
            subcategories: {
              'Router Modules/Cards/Adapters': { id: 67286 },
              'Router Power Supplies': { id: 67287 },
              'Router Memory': { id: 67292 },
              'Other Ent. Router Components': { id: 175704 },
            },
          },
          'Network Storage Disk Arrays': {
            id: 182093,
            subcategories: {
              'Other Network Disk Arrays': { id: 11209 },
              'SAN Disk Arrays': { id: 58317 },
              'Disk Array Components': { id: 58322 },
              'NAS Disk Arrays': { id: 64069 },
            },
          },
        },
      },
      'Tablet & eBook Reader Accs': {
        id: 176970,
        subcategories: {
          'Accessory Bundles': { id: 176971 },
          'A/V Cables & Adapters': { id: 176972 },
          'Cases, Covers, Keyboard Folios': { id: 176973 },
          'Chargers & Sync Cables': { id: 176974 },
          Keyboards: { id: 176975 },
          'Memory Card & USB Adapters': { id: 176976 },
          'Mounts, Stands & Holders': { id: 176977 },
          'Reading Lights': { id: 176978 },
          'Screen Protectors': { id: 176979 },
          'Stickers & Decals': { id: 176980 },
          Styluses: { id: 176981 },
          'Other Tablet & eBook Accs': { id: 176982 },
        },
      },
      'Tablet & eBook Reader Parts': { id: 180235 },
      'Computer Cables & Connectors': {
        id: 182094,
        subcategories: {
          'Other Computer Cables': { id: 3668 },
          'Networking Cables & Adapters': {
            id: 20311,
            subcategories: {
              'Ethernet Cables (RJ-45/8P8C)': { id: 64035 },
              'Optical Fiber Cables': { id: 64043 },
              'Splitters, Couplers & Adapters': { id: 67276 },
              'Plugs, Jacks & Wall Plates': { id: 67279 },
              'DSL/Phone Cables (RJ-11)': { id: 67857 },
              'Other Networking Cables': { id: 101282 },
              'Coaxial Network Cables': { id: 175681 },
            },
          },
          'Audio Cables & Adapters': { id: 31492 },
          'FireWire Cables & Adapters': { id: 31493 },
          'USB Cables, Hubs & Adapters': { id: 44932 },
          'Cabling Tools': { id: 51169 },
          'Computer Cable Testers': { id: 58300 },
          'Cable Ties & Organizers': { id: 67858 },
          'Drive Cables & Adapters': { id: 74941 },
          'Parallel, Serial & PS/2': { id: 116848 },
          'Monitor/AV Cables & Adapters': { id: 158840 },
          'KVM Cables': { id: 182095 },
          'KVM Switches': { id: 182096 },
          'Power Cables & Connectors': { id: 182097 },
        },
      },
      '3D Printers & Supplies': {
        id: 183062,
        subcategories: {
          '3D Printers': { id: 183063 },
          '3D Scanners': { id: 183064 },
          '3D Printer Consumables': { id: 183065 },
          '3D Printer Parts': { id: 183066 },
          '3D Printer Accessories': { id: 260315 },
          '3D Scanner Accessories': { id: 260316 },
          '3D Scanner Parts': { id: 260317 },
        },
      },
      'Mixed Lots': { id: 259696 },
    },
  },
  'Consumer Electronics': {
    id: 293,
    subcategories: {
      'Radio Communication': {
        id: 1500,
        subcategories: {
          'Other Radio Communication': { id: 296 },
          'Commercial Radios': { id: 4669 },
          'Ham, Amateur Radio': {
            id: 4670,
            subcategories: {
              'Ham Radio Receivers': { id: 4673 },
              'Ham Radio Transmitters': { id: 4675 },
              'Ham Radio Transceivers': { id: 40065 },
              'Other Ham Radio Equipment': { id: 96966 },
              'Ham Radio Amplifiers': { id: 163853 },
            },
          },
          'Shortwave Radios': { id: 15051 },
          'Walkie Talkies, Two-Way Radios': { id: 20381 },
          'CB Radios': { id: 40054 },
          Scanners: { id: 40979 },
          'Marine & Aircraft Radios': { id: 149987 },
          Antennas: {
            id: 183502,
            subcategories: {
              'Ham, Amateur Radio Antennas': { id: 4672 },
              'CB Radio Antennas': { id: 48692 },
              'Marine/Air Band Radio Antennas': { id: 175731 },
              'Walkie Talkie/Two-Way Antennas': { id: 175732 },
              'Other Radio Antennas': { id: 175733 },
            },
          },
          'Parts & Accessories': {
            id: 183503,
            subcategories: {
              'Manuals & Magazines': { id: 15050 },
              'Other Radio Communication Accs': { id: 40059 },
              Mounts: { id: 48694 },
              Meters: { id: 48701 },
              'Batteries & Chargers': { id: 48703 },
              'Coax. Cables & Connectors': { id: 48704 },
              'Headsets & Earpieces': { id: 48705 },
              'Code Keyers & Keys': { id: 48706 },
              Microphones: { id: 48707 },
              'Power Supplies': { id: 48708 },
              Speakers: { id: 48709 },
              Tubes: { id: 48710 },
              Tuners: { id: 48711 },
              'Baluns & Chokes': { id: 175735 },
              Filters: { id: 175736 },
              Oscillators: { id: 175737 },
              'Radio & Scanner Crystals': { id: 175738 },
            },
          },
        },
      },
      'Home Telephones & Accessories': {
        id: 3286,
        subcategories: {
          Pagers: { id: 3314 },
          'Home Telephone Parts': { id: 9425 },
          'Caller ID Devices': { id: 15048 },
          'Telephone Batteries': { id: 20034 },
          'Cordless Telephones & Handsets': { id: 33929 },
          'Corded Telephones': { id: 41373 },
          'Cords, Cables & Adapters': { id: 58353 },
          'Telephone Headsets': { id: 84079 },
          'Answering Machines': { id: 139968 },
          'Jacks & Plugs': { id: 184642 },
        },
      },
      'Portable Audio & Headphones': {
        id: 15052,
        subcategories: {
          'Personal Cassette Players': { id: 15053 },
          'Personal CD Players': { id: 15054 },
          'Personal MiniDisc Recorders': { id: 15056 },
          'Portable Stereos & Boomboxes': { id: 48626 },
          'Portable Audio Accessories': {
            id: 56169,
            subcategories: {
              'Other Portable Audio Accs': { id: 48680 },
              'Cases, Covers & Skins': { id: 56170 },
              'Cables & Adapters': { id: 56172 },
              'Accessory Bundles': { id: 73834 },
              'Cassette Adapters': { id: 73835 },
              Transmitters: { id: 73836 },
              Remotes: { id: 79877 },
              'Device-Specific Batteries': { id: 118261 },
              'Mini Plug-in Microphones': { id: 122654 },
              'Chargers & Cradles': { id: 124270 },
              'Mounts & Holders': { id: 131093 },
              Armbands: { id: 168093 },
              'Screen Protectors': { id: 168096 },
            },
          },
          'iPods & MP3 Players': { id: 73839 },
          'Portable AM/FM Radios': { id: 96954 },
          'Portable Satellite Radios': { id: 109256 },
          'Audio Docks & Mini Speakers': { id: 111694 },
          Headphones: { id: 112529 },
          'Replacement Parts & Tools': { id: 163769 },
          'Headphone Parts': { id: 184618 },
          'Headphone Accessories': { id: 260318 },
        },
      },
      'TV, Video & Home Audio': {
        id: 32852,
        subcategories: {
          'TV, Video & Audio Accessories': {
            id: 14961,
            subcategories: {
              'Manuals & Resources': { id: 48644 },
              'Remote Controls': { id: 61312 },
              'Other TV, Video & Audio Accs': { id: 61395 },
              'Media Cases & Storage': { id: 64588 },
              'Audio/Video Transmitters': { id: 149958 },
              'Splitters & Combiners': { id: 149972 },
              'Video Glasses': { id: 163826 },
              'Blank Audio/Video Media': {
                id: 184637,
                subcategories: {
                  'Audio Tapes': { id: 14962 },
                  MiniDiscs: { id: 149959 },
                  'VHS Video Tapes': { id: 149960 },
                  'CD, DVD & Blu-ray Discs': { id: 175718 },
                },
              },
              'TV Tools': {
                id: 184638,
                subcategories: {
                  Crimpers: { id: 184639 },
                  Strippers: { id: 184640 },
                  'Other TV Tools': { id: 184641 },
                },
              },
              'TV & Video Accessories': {
                id: 184975,
                subcategories: {
                  'Video Cables & Interconnects': { id: 32834 },
                  'Signal Amplifiers & Filters': { id: 39803 },
                  'Projection Screens & Material': { id: 48655 },
                  'TV Stands & Mounts': { id: 48656 },
                  'Satellite Signal Multiswitches': { id: 67880 },
                  'Signal Finders': { id: 67882 },
                  'Projector Lamps & Components': { id: 71583 },
                  'Projector Mounts & Stands': { id: 71584 },
                  'Satellite LNB Downconverters': { id: 149971 },
                  'Antenna & Dish Mounting Gear': { id: 149973 },
                  '3D TV Glasses & Accessories': { id: 175719 },
                  'Satellite Dishes': { id: 175720 },
                  Antennas: { id: 175835 },
                },
              },
              'Home Audio Accessories': {
                id: 184976,
                subcategories: {
                  'Audio Cables & Interconnects': { id: 14964 },
                  'Speaker Mounts & Stands': { id: 61336 },
                  'Audio Cable Plugs & Jacks': { id: 175721 },
                },
              },
              'Audio/Video Media Cleaning': {
                id: 258042,
                subcategories: {
                  'A/V Cleaning Kits': { id: 184610 },
                  'A/V Cleaning Cloths & Brushes': { id: 258044 },
                  'A/V Cleaning Solutions': { id: 258045 },
                },
              },
              'Audio/Video Media Repair': {
                id: 258046,
                subcategories: {
                  'A/V Buffing & Sanding Pads': { id: 184612 },
                  'A/V Repair Kits': { id: 184613 },
                  'A/V Repair Machines & Parts': { id: 184614 },
                },
              },
            },
          },
          'TV, Video & Audio Parts': {
            id: 71582,
            subcategories: {
              'Cassette Player Parts & Comp.': { id: 31197 },
              'Record Player, Turntable Parts': { id: 64619 },
              'Other TV, Video & Audio Parts': { id: 71585 },
              'Amplifier Parts & Components': { id: 122649 },
              'Rear-Projection TV Lamps': { id: 149975 },
              'TV Boards, Parts & Components': { id: 163768 },
              'Speaker Parts & Components': {
                id: 184647,
                subcategories: {
                  Tweeters: { id: 12903 },
                  'Speaker Boxes': { id: 21995 },
                  'Other Speaker Parts & Comp.': { id: 21997 },
                  Manuals: { id: 96273 },
                  'Speaker Building Components': { id: 141249 },
                  Woofers: { id: 141262 },
                  'Repair Kits & Consumables': { id: 169024 },
                  'Electronic Parts & Components': { id: 169025 },
                  'Speaker Frames': { id: 185025 },
                  'Speaker Magnets': { id: 185026 },
                  'Voice Coils': { id: 185027 },
                },
              },
            },
          },
          'Other TV, Video & Home Audio': { id: 163829 },
          'Media Streamers': { id: 168058 },
          'TV & Video': {
            id: 184972,
            subcategories: {
              TVs: { id: 11071 },
              'DVRs, Hard Drive Recorders': { id: 11725 },
              'Home Theater Projectors': { id: 22610 },
              'Cable TV Boxes': { id: 39804 },
              'Satellite TV Receivers': { id: 96969 },
              'DVD & Blu-ray Players': { id: 175711 },
            },
          },
          'Home Audio': {
            id: 184973,
            subcategories: {
              'Compact & Shelf Stereos': { id: 4787 },
              'Home Audio Components': {
                id: 14969,
                subcategories: {
                  'MiniDisc Decks': { id: 4786 },
                  'CD Players & Recorders': { id: 14971 },
                  'Radio Tuners': { id: 22635 },
                  'Cassette Tape Decks': { id: 48645 },
                  'Record Players/Home Turntables': { id: 48647 },
                  'Other Home Stereo Components': { id: 81741 },
                  'Stereo Component Combos': { id: 175708 },
                },
              },
              'Home Speakers & Subwoofers': { id: 14990 },
              'Home Theater Systems': { id: 72406 },
              'Receivers & Amplifiers': {
                id: 184974,
                subcategories: {
                  Equalizers: { id: 3271 },
                  'Amplifiers & Preamps': { id: 14970 },
                  Receivers: { id: 14981 },
                },
              },
            },
          },
        },
      },
      'Multipurpose Batteries & Power': {
        id: 48446,
        subcategories: {
          'Battery Chargers': { id: 48618 },
          'Rechargeable Batteries': { id: 48619 },
          'Single Use Batteries': { id: 50602 },
          'Battery Testers': { id: 79845 },
          'Other Multipurpose Batteries': { id: 79846 },
          'Multipurpose AC to DC Adapters': { id: 88758 },
          'Power Cables & Connectors': { id: 116852 },
          'Battery Converters': { id: 172011 },
          'Heavy-Duty Voltage Converters': { id: 175729 },
        },
      },
      'Other Consumer Electronics': { id: 175837 },
      'Smart Glasses': { id: 178894 },
      'Virtual Reality': {
        id: 183067,
        subcategories: {
          'Smartphone VR Headsets': { id: 183069 },
          'Cases, Covers & Skins': { id: 183070 },
          'Controllers & Motion Sensors': { id: 183071 },
          Parts: { id: 183072 },
          'Other Virtual Reality Accs': { id: 183073 },
          'Standalone VR Headsets': { id: 184645 },
        },
      },
      'Vintage Electronics': {
        id: 183077,
        subcategories: {
          'Vintage Calculators': { id: 58042 },
          'Vintage Audio & Video': {
            id: 175740,
            subcategories: {
              'Laserdisc Players': { id: 3317 },
              VCRs: { id: 15088 },
              'Vintage Record Players': { id: 48649 },
              'Vintage Radios': { id: 50595 },
              'Vintage Stereo Receivers': { id: 50596 },
              'Vintage Speakers': { id: 50597 },
              'Vintage Preamps & Tube Preamps': { id: 67807 },
              'Vintage Cassette Decks': { id: 71574 },
              'Vintage Amplifiers & Tube Amps': { id: 73368 },
              'Vintage Televisions': { id: 73374 },
              'Vintage Tuners & Tube Tuners': { id: 73382 },
              'Reel-to-Reel Tape Recorders': { id: 116868 },
              'Vintage Parts & Accessories': {
                id: 175741,
                subcategories: {
                  'Vintage Manuals': { id: 39996 },
                  'Vintage Tubes & Tube Sockets': { id: 64627 },
                  'Vintage Transformers': { id: 67815 },
                  'Vintage Capacitors': { id: 71573 },
                  'Vintage Speaker & Horn Drivers': { id: 73372 },
                  'Vintage Blank Media': { id: 163851 },
                  'Other Vintage A/V Parts & Accs': { id: 168088 },
                },
              },
              'Other Vintage Audio & Video': { id: 175742 },
              'Vintage Headphones': { id: 183074 },
              '8-Track Players': { id: 183078 },
            },
          },
          'Vintage Home Telephones': { id: 182098 },
          'Other Vintage Electronics': { id: 183079 },
        },
      },
      'Mixed Lots': { id: 259701 },
    },
  },
};
