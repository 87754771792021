import { ebayCategoryNameToId } from '@backend/types';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import InsightsIcon from '@mui/icons-material/Insights';
import WarningIcon from '@mui/icons-material/Warning';
import { Chip, Link, Table } from '@mui/joy';
import { useState } from 'react';

import PriceDialog from '../../PriceDialog/PriceDialog';

import type { QuoteItem } from '@backend/types';

interface PricingStepTableProps {
  items: QuoteItem[];
  finalized: boolean;
}

const PricingStepTable = ({ items, finalized }: PricingStepTableProps) => {
  const [item, setItem] = useState<QuoteItem | null>(null);

  const onDialogOpen = (item: QuoteItem) => {
    setItem(item);
  };

  const onDialogClose = () => {
    setItem(null);
  };

  return (
    <>
      <Table
        data-testid="pricing-table"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
          tableLayout: 'auto',
        }}
      >
        <thead>
          <tr>
            <th style={{ width: '100px' }}>Status</th>
            <th style={{ width: '50px' }}>Quantity</th>
            <th style={{ width: '15%' }}>Category</th>
            <th style={{ width: '30%' }}>Product</th>
            <th style={{ width: '150px' }}>Price/unit</th>
            <th style={{ width: '150px' }}>Total Worth</th>
            <th style={{ width: '150px' }}>Actions</th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: 'primary.500' }}>
          {items.map((row) => (
            <tr key={row.id}>
              <td>
                <Chip
                  sx={{
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textTransform: 'capitalize',
                  }}
                  variant="soft"
                  size="sm"
                  startDecorator={
                    row.status === 'estimated' ? (
                      <CheckRoundedIcon />
                    ) : (
                      <WarningIcon />
                    )
                  }
                  color={row.status === 'estimated' ? 'success' : 'warning'}
                >
                  {row.status}
                </Chip>
              </td>
              <td>{row.quantity}</td>
              <td>{row.category}</td>
              <td>
                {row.manufacturer} {row.model} {row.identifier}
              </td>
              <td>
                {row.status === 'estimated'
                  ? row.fixedUnitPrice
                    ? `$${row.fixedUnitPrice}`
                    : `$${row.minUnitPrice} - $${row.maxUnitPrice}`
                  : '-'}
              </td>
              <td>
                {row.status === 'estimated'
                  ? row.fixedUnitPrice
                    ? `$${row.fixedUnitPrice * row.quantity}`
                    : `$${row.minUnitPrice! * row.quantity} - $${row.maxUnitPrice! * row.quantity}`
                  : '-'}
              </td>
              <td>
                <Link
                  component="button"
                  onClick={() => onDialogOpen(row)}
                  disabled={finalized}
                  startDecorator={<InsightsIcon />}
                >
                  Set Price
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {item && (
        <PriceDialog
          item={item}
          searchParams={{
            searchTerm: `${item.manufacturer} ${item.model} ${item.identifier ?? ''}`,
            actuallySold: false,
            category: item?.category
              ? ebayCategoryNameToId[item?.category]
              : undefined,
          }}
          open={!!item}
          onClose={onDialogClose}
        />
      )}
    </>
  );
};

export default PricingStepTable;
