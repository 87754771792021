import { Card, Divider, List, ListItem, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';

const Dashboard = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        width: '100%',
        maxWidth: 1000,
        margin: '0 auto',
      }}
    >
      <Card sx={{ width: '100%' }}>
        <Typography level="h3">Welcome to GreenSight</Typography>
        <Typography>
          Thank you for participating in the launch of our Quote feature. To
          help us refine the system, we ask that you take the time to explore
          and test the following functionalities:
        </Typography>
        <Divider />
        <Typography level="h4">Key Features to Test</Typography>
        <List marker="decimal">
          <ListItem nested>
            <ListItem>Quote Pricing & Appraisal Tool</ListItem>
            <List marker="circle">
              <ListItem nested>
                <ListItem>
                  Test the Quote feature by importing items or adding them
                  manually. Then, try out our pricing tool. This is the primary
                  feature we&apos;d like your feedback on.
                </ListItem>
              </ListItem>
            </List>
          </ListItem>
          <ListItem nested>
            <ListItem>Account Management</ListItem>
            <List marker="circle">
              <ListItem nested>
                <ListItem>
                  Visit the Account page in the Settings to manage your account
                  details.
                </ListItem>
              </ListItem>
            </List>
          </ListItem>
          <ListItem nested>
            <ListItem>Multi-User Functionality</ListItem>
            <List marker="circle">
              <ListItem nested>
                <ListItem>
                  Ensure multiple users can access and work within the system
                  simultaneously.
                </ListItem>
              </ListItem>
            </List>
          </ListItem>
        </List>
        <Typography level="h4">Feedback & Issues</Typography>
        <Typography>
          If you encounter any issues or have feedback, please use the “Report a
          Bug” button located in the lower right corner of the app. We will
          immediately be notified of your report and will contact you if we need
          further information.
        </Typography>
        <Typography level="h4">Additional Accounts</Typography>
        <Typography>
          Should you require additional accounts for your team, please contact
          us, and we will provision them promptly.
        </Typography>
        <Divider />
        <Typography>
          Thank you for your continued support in making GreenSight the leading
          solution for electronics resale!
        </Typography>
      </Card>
    </Box>
  );
};

export default Dashboard;
