import { Box, Typography, Button, Grid, Alert } from '@mui/joy';
import { alpha } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuoteStore } from '../../../../stores/quote.store';
import ImportItemsStepFileCard from '../ImportItemsStepFileCard/ImportItemsStepFileCard';

function ImportItemsStepUploading() {
  const files = useQuoteStore((state) => state.filenameToPendingFile);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fileGridComponents = useMemo(() => {
    return [...files].map(([filename, file]) => (
      <Grid sm={12} md={6} key={filename} sx={{ minWidth: 100 }}>
        <ImportItemsStepFileCard pendingFile={file} />
      </Grid>
    ));
  }, [files]);

  const skipUpload = ![...files].some(([_, file]) => file.uploaded);

  const { quoteId } = useParams();

  const handleBack = () => {
    navigate(`/quote/${quoteId}/details`);
  };

  const handleNext = async () => {
    if (skipUpload) {
      navigate(`/quote/${quoteId}/edits`);
    } else {
      setLoading(true);
      try {
        // TODO: Start processing uploaded files
        navigate(`/quote/${quoteId}/edits`);
      } catch (error) {
        setError((error as any).message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          height: '30vh',
          mb: 4,
          overflowY: 'scroll',
          overflowX: 'hidden',
          p: 2,
          bgcolor: error
            ? alpha(theme.palette.danger[400], 0.2)
            : theme.palette.background.level1,
          border: `1px solid ${error ? theme.palette.danger[700] : theme.palette.neutral[700]}`,
          borderRadius: 8,
        })}
      >
        {files.size === 0 && (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Typography level="body-sm">
              {error
                ? 'Please upload at least one file before proceding.'
                : 'Your files will show up here when uploaded.'}
            </Typography>
          </Box>
        )}
        <Grid container columnSpacing={5} rowSpacing={3}>
          {fileGridComponents}
        </Grid>
      </Box>

      {error && (
        <Alert color="danger" variant="plain" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={handleBack} disabled={loading}>
          Back
        </Button>
        <Button
          onClick={handleNext}
          color={skipUpload ? 'neutral' : undefined}
          loading={loading}
        >
          {skipUpload ? 'Add items manually' : 'Verify uploaded items'}
        </Button>
      </Box>
    </>
  );
}

export default ImportItemsStepUploading;
