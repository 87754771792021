export function removeOutliers(numbers: number[]) {
  numbers.sort((a, b) => a - b);
  const q1 = numbers[Math.floor(numbers.length / 4)];
  const q3 = numbers[Math.ceil(numbers.length * (3 / 4)) - 1];
  const iqr = q3 - q1;
  const lowerBound = q1 - iqr * 1.5;
  const upperBound = q3 + iqr * 1.5;

  return numbers.filter((num) => num >= lowerBound && num <= upperBound);
}

export function calculateNumberOfBins(data: number[]): number {
  const numElements = data.length;
  return Math.ceil(Math.sqrt(numElements));
}
