import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Box, ModalDialog, Typography, Alert } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import { trpc } from '@shared';
import { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';

import { useQuoteStore } from '../../../stores/quote.store';

import QuoteActionButton from './QuoteActionButton/QuoteActionButton';
import QuoteEditsItemForm from './QuoteEditsItemForm/QuoteEditsItemForm';
import QuoteTable from './QuoteTable/QuoteTable';

import type { loader } from '../../../pages/quote/edits';

export default function QuoteEditsStep() {
  const navigate = useNavigate();
  const [
    selected,
    setSelected,
    success,
    setSuccess,
    error,
    setError,
    itemDialog,
    setItemDialogState,
  ] = useQuoteStore((state) => [
    state.selected,
    state.setSelected,
    state.success,
    state.setSuccess,
    state.error,
    state.setError,
    state.itemDialog,
    state.setItemDialogState,
  ]);

  const { quoteItems, quoteDetail } = useLoaderData() as Awaited<
    ReturnType<typeof loader>
  >;

  const getDetail = trpc.quotes.getQuoteDetailsById.useQuery(
    {
      id: quoteDetail.id,
    },
    { initialData: quoteDetail }
  );

  const detail = getDetail.data;

  const getItems = trpc.quotes.getItems.useQuery(
    {
      id: detail.id,
    },
    { initialData: quoteItems }
  );

  const items = getItems.data;

  const [loading, setLoading] = useState(false);
  const utils = trpc.useUtils();
  const deleteItems = trpc.quotes.deleteItems.useMutation({
    onSettled: () => {
      setLoading(false);
    },
    onMutate: () => {
      setLoading(true);
      setError(null);
    },
    onSuccess: async () => {
      setSuccess('Item(s) successfully deleted');
      setError(null);
      setSelected([]);
      void utils.quotes.getItems.invalidate({ id: detail.id });
    },
    onError: (err) => {
      setSuccess('');
      setError(err.message);
    },
  });

  const createItem = trpc.quotes.createItem.useMutation({
    onSettled: () => {
      setLoading(false);
    },
    onMutate: () => {
      setLoading(true);
      setError(null);
    },
    onSuccess: async () => {
      setSuccess('Item(s) successfully duplicated');
      setError(null);
      setSelected([]);
      void utils.quotes.getItems.invalidate({ id: detail.id });
    },
    onError: (err) => {
      setSuccess('');
      setError(err.message);
    },
  });

  const clearItemsInFinalQuote =
    trpc.quotes.clearAllItemsInFinalQuote.useMutation();

  const updateItemInFinalQuote =
    trpc.quotes.updateItemsInFinalQuote.useMutation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ml: 2,
        mb: 1.5,
      }}
    >
      <Typography level="h4">{detail.quoteName}</Typography>
      <Typography level="body-sm">{detail.quoteDescription ?? ''}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <QuoteActionButton
          logo={FileUploadIcon}
          header="Import Items"
          subtext="Import more items"
          onClick={() => navigate(`/quote/${detail.id}/import`)}
          loading={loading}
        />
        <QuoteActionButton
          logo={AddIcon}
          header="Add new"
          subtext="Add an individual item"
          onClick={() => {
            setItemDialogState(true, 'create');
          }}
          loading={loading}
        />
        <QuoteActionButton
          logo={ContentCopyIcon}
          header="Duplicate"
          subtext="Duplicate any selected items"
          disabled={selected.length === 0}
          onClick={() => {
            items?.forEach((item) => {
              if (selected.includes(item.id)) {
                createItem.mutate(item);
              }
            });
          }}
          loading={loading}
        />
        <QuoteActionButton
          logo={DeleteIcon}
          header="Delete"
          subtext="Delete any selected items"
          onClick={() => {
            deleteItems.mutate(selected);
          }}
          disabled={selected.length === 0}
          loading={loading}
        />
        <QuoteActionButton
          logo={RequestQuoteIcon}
          header={selected.length > 0 ? 'Quote few' : 'Quote all'}
          subtext={
            selected.length > 0
              ? 'Find a quote for selected items'
              : 'Find a quote for all of the items'
          }
          loading={loading}
          disabled={items.length === 0}
          onClick={async () => {
            setLoading(true);
            setError(null);
            try {
              await clearItemsInFinalQuote.mutateAsync({
                id: detail.id,
              });
              await updateItemInFinalQuote.mutateAsync({
                id: detail.id,
                itemIds: selected,
                inFinalQuote: true,
              });
              navigate(`/quote/${detail.id}/pricing`);
              void utils.quotes.getItems.invalidate({ id: detail.id });
              void utils.quotes.getAllItemsInFinalQuote.invalidate({
                id: detail.id,
              });
            } catch (err) {
              setError((err as any).message);
            } finally {
              setLoading(false);
            }
          }}
          bold
        />
      </Box>
      <QuoteTable items={items} />
      {success && (
        <Alert
          color="success"
          variant="plain"
          sx={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
            mb: -3,
            mr: 1,
          }}
        >
          {success}
        </Alert>
      )}
      {error && (
        <Alert
          color="danger"
          variant="plain"
          sx={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'flex-end',
            mb: -3,
            mr: 1,
          }}
        >
          {error}
        </Alert>
      )}
      <Modal
        sx={{
          overflow: 'scroll',
        }}
        open={itemDialog.open}
        onClose={() => {
          setItemDialogState(false);
        }}
      >
        <ModalDialog>
          <QuoteEditsItemForm
            operation={itemDialog.operation}
            currentItem={itemDialog.item}
            onClose={() => {
              setItemDialogState(false);
            }}
          />
        </ModalDialog>
      </Modal>
    </Box>
  );
}
